var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{staticClass:"stepper-wrapper",style:(("margin-left: " + _vm.margin))},[_c('v-row',{staticClass:"justify-center",staticStyle:{"margin-top":"20px"}},[_c('div',{staticStyle:{"min-width":"20%","max-width":"20% !important"}},[_c('v-select',{attrs:{"label":"Odaberite prodajno mjesto","outlined":"","dense":"","hide-details":"","items":_vm.locations,"item-text":"name","item-value":"value","return-object":""},on:{"change":function($event){return _vm.filterWarehouses()}},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}})],1),_c('div',{staticStyle:{"min-width":"20%","max-width":"20%","padding-left":"3px"}},[_c('v-select',{attrs:{"label":"Odaberite skladište","outlined":"","dense":"","hide-details":"","items":_vm.warehouses,"item-text":"name","item-value":"id"},model:{value:(_vm.selectedWarehouse),callback:function ($$v) {_vm.selectedWarehouse=$$v},expression:"selectedWarehouse"}})],1),(_vm.selectedLocation && _vm.selectedWarehouse.value !== '')?_c('div',{staticStyle:{"min-width":"10%","padding-left":"3px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-btn',_vm._g(_vm._b({staticClass:"white--text okButton",attrs:{"disabled":_vm.activeInventory ? true : false,"height":"40"},on:{"click":_vm.newInventura}},'v-btn',attrs,false),on),[_vm._v("Započni inventuru")])],1)]}}],null,false,2046824520)},[_c('span',[_vm._v(_vm._s(_vm.activeInventory ? "Završi aktivnu inventuru" : "Započni inventuru"))])])],1):_vm._e()]),_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.inventories),function(inventory,i){return _c('v-card',{key:i,staticClass:"pb-5",staticStyle:{"height":"48px !important"},attrs:{"hover":""}},[_c('v-row',{class:[
                'ma-0',
                inventory.status === 'OK'
                  ? 'status-ok-exp'
                  : ("" + (inventory.status === 'PENDING'
                        ? 'status-processing-exp'
                        : ("" + (inventory.status === 'PARTIAL'
                              ? 'status-processing-partial-exp'
                              : 'status-failed-exp')))) ],staticStyle:{"height":"48px !important"}},[_c('div',{staticStyle:{"text-align":"right","padding":"0px 0px 0px 3px"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Početak")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("fullDateTime")(inventory.created))+" ")])]),_c('div',{staticStyle:{"text-align":"center","width":"20%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Pokrenuo")]),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(inventory.started_user)+" ")])]),_c('div',{staticStyle:{"text-align":"center","width":"20%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Status")]),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.showStatus(inventory.status))+" ")])]),_c('div',{staticStyle:{"text-align":"center","width":"20%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Odobrio")]),(
                    inventory.finished_user && inventory.finished_user !== ''
                  )?_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(inventory.finished_user)+" ")]):_vm._e()]),_c('div',{staticStyle:{"text-align":"center","width":"20%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Kraj")]),(inventory.finished && inventory.finished > 0)?_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("fullDateTime")(inventory.finished))+" ")]):_vm._e()]),_c('div',{staticClass:"flex-grow-1",staticStyle:{"text-align":"right"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('div',{staticClass:"caption grey--text text-left"}),_c('div',{staticClass:"text-left"})]),_c('div',{staticClass:"d-flex justify-end align-center pl-2",staticStyle:{"z-index":"1"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((inventory.options),function(item,index){return _c('v-list-item',{key:index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){("" + (item.action(inventory)))},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();("" + (item.action(inventory)))}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)])])])],1)}),1)],1)],1)],1),_c('inventory-items-list',{ref:"inventoryItemsList"}),_c('change-inventory-status',{ref:"changeInventoryStatus",on:{"finishedChange":_vm.onFinishedChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }