<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="300"
    style="z-index: 6"
  >
    <v-form ref="warehouseForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">{{ $tc("$vuetify.item", 1) }}</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Artikl sa skladišta"
                  v-model="item.name"
                  outlined
                  disabled
                  :rules="[rules.req]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                ><quantity-input
                  :label="$t('$vuetify.pointOfSale.stock')"
                  outlined
                  autofocus
                  v-model="item.stock"
                  :rules="[rules.req]"
                  id="stock"
                ></quantity-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import state from '@/state'
import { clone } from '@/plugins/utils'
import QuantityInput from '../../../components/QuantityInput.vue'
export default {
  components: { QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  data: () => ({
    visible: false,
    submitting: false,
    loading: false,
    lagerItems: [],
    item: {},
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq(),
      money: rules.money()
    }
  }),
  methods: {
    open (item, multiplier) {
      this.visible = true
      this.submitting = false
      item.stock = item.stock.toString().replace('.', ',')
      this.item = clone(item)
      this.multiplier = clone(multiplier)
    },
    close () {
      this.$emit('close')
      this.visible = false
    },
    submit () {
      if (!this.$refs.warehouseForm.validate()) return
      this.submitting = true
      try {
        const payload = {
          action: {
            operation: 'update',
            entity: 'warehouse',
            resource_id: this.item.id,
            params: {
              stock: parseFloat(this.item.stock.toString().replace(',', '.')) * this.multiplier,
              location_id: state.getPointOfSale().id,
              status: 'OK'
            }
          }
        }

        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.submitting = false
              this.visible = false
              this.$emit('refresh')
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.submitting = false
            }
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
<style>
.items {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}
</style>
