<template>
  <v-card justify="center" style="overflow-y: hidden" dense>
    <v-card-actions dense>
      <v-container dense>
        <v-card class="mt-0 pt-2 mb-2 pb-2">
          <v-card-actions class="mt-0 pt-2 mb-0 pb-0">
            <v-row dense class="pb-1">
              <v-col cols="6" dense class="mt-0 pt-0 mb-0 pb-0"
                ><v-select
                  hide-details
                  label="Odaberite prodajno mjesto"
                  v-model="selectedLocation"
                  :items="pointsOfSale"
                  item-text="name"
                  item-value="value"
                  return-object
                  @change="setWarehouseChoices"
                  bottom
                  outlined
                  dense
                  class="ml-2 pb-0"
                >
                </v-select
              ></v-col>
              <v-col class="mt-0 pt-0 mb-0 pb-0"
                ><v-select
                  hide-details
                  label="Odaberite skladište"
                  class="mr-2 pb-0"
                  v-model="selectedWarehouse"
                  :items="warehouses"
                  item-text="name"
                  item-value="value"
                  bottom
                  outlined
                  return-object
                  dense
                  @change="warehouseSelected"
                >
                </v-select
              ></v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card class="mt-0 pt-0 mb-2 pb-0" style="justify: center">
          <v-card-actions style="justify: center">
            <v-row>
              <v-col cols="12" style="justify: center">
                <v-row>
                  <v-col cols="2">
                    <v-menu v-model="reportFromMenu" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          hide-details
                          :value="formatDateFrom"
                          label="Od datuma"
                          dense
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateFrom"
                        no-title
                        scrollable
                        @input="reportFromMenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-menu v-model="reportToMenu" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          hide-details
                          :value="formatDateTo"
                          label="Do datuma"
                          dense
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dateTo"
                        no-title
                        scrollable
                        @input="reportToMenu = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2"
                    ><v-text-field
                      outlined
                      hide-details
                      label="Broj dokumenta"
                      dense
                      v-model="deliveryNumberSearch"
                    ></v-text-field>
                  </v-col>
                  <div
            style="
              min-width: 40% !important;
              padding-top: 12px;
              padding-right: 10px;
              overflow: auto;
            "
          >
                    <v-btn
                      dense
                      class="mb-3 ml-2 enabledbutton mr-2"
                      height="2.5rem"
                      :disabled="disableButton"
                      @click="getDocuments()"
                      >Dohvati</v-btn
                    >
                    <v-btn
                      v-if="!modal"
                      dense
                      class="mb-3 enabledbutton mr-2"
                      height="2.5rem"
                      @click="showDoc"
                      >Nova</v-btn
                    >
                    <v-btn class="mb-3 mr-2" style="min-width: 44px !important;"  v-if="selectedRows.length > 0" height="2.5rem" @click="changeWidth()">
                      <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn class="mb-3 mr-2" style="min-width: 44px !important;"  v-if="selectedRows.length > 0" height="2.5rem" @click="generateExcel()"
                      ><v-icon>mdi-microsoft-excel</v-icon></v-btn
                    >
                    <v-btn class="mb-3 mr-2" style="min-width: 44px !important;"  v-if="selectedRows.length > 0 && primke.some(primka => selectedRows.includes(primka.id) && primka.type === 'delivery_notes')" height="2.5rem" @click="editDeliveryNote()">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>

                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card class="mb-2" style="justify: center">
          <v-card-actions style="justify: center">
            <v-row>
              <v-col cols="6">
                <v-data-table
                  height="100%"
                  hide-default-footer
                  :headers="headers"
                  fixed-header
                  disable-pagination
                  :items-per-page="-1"
                  dense
                  :items="primke"
                  item-key="id"
                  v-model="selectedRows"
                  class="elevation-1"
                  style="height: 256px; overflow-y: auto; cursor: pointer"
                >
                  <template v-slot:item="{ item }">
                    <tr
                      :class="
                        selectedRows.indexOf(item.id) > -1
                          ? 'selectedrow-456'
                          : ''
                      "
                      @click="rowClicked(item)"
                    >
                      <td>{{ formatDate(item.order_date) }}</td>
                      <td>{{ item.delivery_number }}</td>
                      <td>{{ item.customer_name }}</td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.ukupno) }}
                      </td>
                      <td style="text-align: right">
                        {{ showDocumentType(item.realType, item.type) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-simple-table dense id="podaci" class="elevation-1">
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td class="font-weight-bold">Skladište</td>
                            <td>{{ warehouseName }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Dobavljač</td>
                            <td>{{ dobavljac }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Način plaćanja</td>
                            <td>{{ fobDescription }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Datum</td>
                            <td>{{ formatDate(datumKreiranja) }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Broj dokumenta</td>
                            <td>{{ deliveryNumber }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Iznos</td>
                            <td>
                              {{
                                ukupnoBezPdv ? formatCurrency(ukupnoBezPdv) : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">Predporez</td>
                            <td>
                              {{
                                inputTaxTotal
                                  ? formatCurrency(inputTaxTotal)
                                  : ""
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-black">Povratna naknada</td>
                            <td class="font-weight-black">
                              {{ calculatePn(false) }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-weight-black">Ukupno</td>
                            <td class="font-weight-black">
                              {{
                                ukupnoBrutto
                                  ? formatCurrency(ukupnoBrutto)
                                  : formatCurrency(
                                      ukupnoBezPdv +
                                        inputTaxTotal +
                                        calculatePn(true)
                                    )
                              }}
                            </td>
                          </tr>
                          <tr v-if="skladisteLabel && skladiste">
                            <td class="font-weight-black">
                              {{ skladisteLabel }}
                            </td>
                            <td class="font-weight-black">
                              {{ skladiste }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-card style="justify: center">
          <v-card-actions style="jusitfy: center">
            <v-row>
              <v-col cols="12">
                <v-data-table
                  fixed-header
                  :headers="stavkeHeaders"
                  :items="stavke"
                  hide-default-footer
                  dense
                  id="stavket"
                  height="100%"
                  item-key="id"
                  class="elevation-1"
                  style="height: 35vh; overflow-y: auto"
                  :items-per-page="-1"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.name }}</td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.quantity, 1) }}
                      </td>
                      <td>{{ item.measureUnit }}</td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.purchasePrice) }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.supplierRebate) }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.totalPriceWithRabat) }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.totalPriceNoPdv) }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.pdv) }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.inputTax) }}
                      </td>
                      <td style="text-align: right">
                        {{
                          formatCurrency(item.inputTax + item.totalPriceNoPdv)
                        }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.refundPrice) }}
                      </td>
                      <td style="text-align: right">
                        {{ formatCurrency(item.refundPriceTotal) }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-card-actions>
    <div class="d-flex justify-center" style="display: none !important">
      <div
        id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
          display: none;
        "
      >
        <div
          style="font-family: font-family: Raleway, sans-serif; padding-bottom: 10px"
        >
          <table
            justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title"
          >
            <thead>
              <tr style="text-align: center">
                <th colspan="2">{{ this.documentType.name.toUpperCase() }}</th>
              </tr>
            </thead>
          </table>
        </div>

        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important; font-family: 'Roboto-Regular'"
            border="1"
            class="mb-10"
            cellspacing="0"
            id="firma"
          >
            <thead>
              <tr :class="['header', 'naslov']" style="text-align: center">
                <th colspan="2">Osnovni podaci</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="font-weight-bold">Skladište</td>
                <td>{{ warehouseName }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Dobavljač</td>
                <td>{{ dobavljac }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Način plaćanja</td>
                <td>{{ fobDescription }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Datum</td>
                <td>{{ formatDate(datumKreiranja) }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Broj dokumenta</td>
                <td>{{ deliveryNumber }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Iznos</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? formatCurrency(ukupnoBezPdv)
                      : ukupnoBezPdv / 100
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">Predporez</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? formatCurrency(inputTaxTotal)
                      : inputTaxTotal / 100
                  }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-black">Povratna naknada</td>
                <td class="font-weight-black">
                  {{ calculatePn(false) }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-black">Ukupno</td>
                <td class="font-weight-black">
                  {{
                    viewType === "pdf"
                      ? ukupnoBrutto
                        ? formatCurrency(ukupnoBrutto)
                        : formatCurrency(
                            ukupnoBezPdv + inputTaxTotal + calculatePn(true)
                          )
                      : ukupnoBrutto
                      ? ukupnoBrutto / 100
                      : (ukupnoBezPdv + inputTaxTotal + calculatePn(true)) / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style="font-family: font-family: Raleway, sans-serif; padding-top: 10px"
        >
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            id="tableTraffic"
            v-if="showTableData && stavke.length > 0"
          >
            <thead>
              <tr :class="['header', 'naslov']" style="text-align: center">
                <th colspan="11">Artikli</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="['header', 'naslov']"
                style="font-weight: bold; text-align: left"
              >
                <th>Artikl</th>
                <th>Kol.</th>
                <th>Jed. mjere</th>
                <th>Nabav. cij.</th>
                <th>Rabat (%)</th>
                <th>Cijena</th>
                <th>Ukupno</th>
                <th>PDV (%)</th>
                <th>Predporez</th>
                <th>Brutto</th>
                <th>Pn</th>
              </tr>
              <tr v-for="(item) in stavke" :key="item.id">
                <td>{{ item.name }}</td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.quantity, 1)
                      : item.quantity
                  }}
                </td>
                <td>{{ item.measureUnit }}</td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.purchasePrice)
                      : item.purchasePrice / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.supplierRebate)
                      : item.supplierRebate / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.totalPriceWithRabat)
                      : item.totalPriceWithRabat / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.totalPriceNoPdv)
                      : item.totalPriceNoPdv / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.pdv)
                      : item.pdv / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.inputTax)
                      : item.inputTax / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.inputTax + item.totalPriceNoPdv)
                      : (item.inputTax + item.totalPriceNoPdv) / 100
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? formatCurrency(item.refundPrice)
                      : item.refundPrice / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <modal
      v-if="!modal"
      height="auto"
      width="90%"
      scrollable
      draggable
      resizable
      style="z-index: 200"
      name="documents"
      class="createDelivery"
    >
      <!-- <PrimkaObjekt
        name="primka"
        :modal="true"
        @closeModal="hideDoc()"
      ></PrimkaObjekt> -->

      <component
        style="border-radius: 25px !important;"
        :is="primkaobjekt"
        :modal="true"
        @closeModal="hideDoc()"
      ></component>
    </modal>

    <modal
      v-if="!modal"
      height="auto"
      width="90%"
      scrollable
      draggable
      resizable
      style="z-index: 200; border-radius: 25px !important;"
      name="edit"
      class="editDelivery"
    >
      <!-- <PrimkaObjekt
        name="primka"
        :modal="true"
        @closeModal="hideDoc()"
      ></PrimkaObjekt> -->

      <component
        style="border-radius: 25px !important;"
        :is="primkaEditObjekt"
        :modal="true"
        @closeModal="hideDocEdit()"
        :primka="primkaZaEdit"
      ></component>
    </modal>
  </v-card>
</template>
<script>
import state from '@/state'
import moment from 'moment'
import { auth, df } from '@/plugins/firebase'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import { clone } from '@/plugins/utils'
// import DeliveryNoteEdit from './DeliveryNoteEdit.vue'

export default {
  components: { PrimkaObjekt: () => import('@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsv2Modal.vue'), PrimkaEditObjekt: () => import('@/modules/point-of-sale/components/warehouse/warehouse-module/DeliveryNoteEdit.vue') },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  props: {
    modal: Boolean
  },
  data: () => ({
    primkaobjekt: 'PrimkaObjekt',
    primkaEditObjekt: 'PrimkaEditObjekt',
    warehouses: [],
    currency: 'EUR',
    deliveryNumber: '',
    warehouseName: '',
    selectedWarehouse: '',
    ukupnoBezPdv: undefined,
    ukupnoBrutto: undefined,
    fobDescription: '',
    inputTaxTotal: undefined,
    datumKreiranja: '',
    dobavljac: '',
    selectedRows: [],
    deliveryNumberSearch: '',
    reportFromMenu: undefined,
    reportToMenu: undefined,
    pointsOfSale: [],
    companies: [],
    listeners: [],
    selectedLocation: undefined,
    selectedDeliveryNote: undefined,
    disableButton: true,
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
      .toISOString()
      .substr(0, 10),
    dateTo: new Date().toISOString().substr(0, 10),
    stavkeHeaders: [{ text: 'Artikl', value: 'name' },
      { text: 'Količina', value: 'quantity', align: 'end' },
      { text: 'Jedinica mjere', value: 'measureUnit' },
      { text: 'Nabavna cijena', value: 'purchasePrice', align: 'end' },
      { text: 'Rabat (%)', value: 'supplierRebate', align: 'end' },
      { text: 'Cijena', value: 'totalPriceWithRabat', align: 'end' },
      { text: 'Ukupno', value: 'totalPriceNoPdv', align: 'end' },
      { text: 'PDV (%)', value: 'pdv', align: 'end' },
      { text: 'Predporez', value: 'inputTax', align: 'end' },
      { text: 'Brutto', value: 'measureUnit', align: 'end' },
      { text: 'Pn', value: 'refundPrice', align: 'end' },
      { text: 'Pn brutto', value: 'refundPriceTotal', align: 'end' }],
    headers: [
      {
        text: 'Datum',
        align: 'left',
        sortable: false,
        value: 'created'
      },
      { text: 'Oznaka temelja', value: 'delivery_number' },
      { text: 'Dobavljač', value: 'customer_name' },
      { text: 'Iznos', value: 'ukupnoBezPdv' },
      { text: 'Tip dokumenta', value: 'type' }
    ],
    stavke: [],
    primke: [],
    documentTypes: [
      { name: 'Primka', type: 'delivery_notes' },
      { name: 'Otpremnica', type: 'shipment_notes' },
      { name: 'Međuskladišnica', type: 'inventory_transfers' }
    ],
    documentType: { name: 'Primka', type: 'delivery_notes' },
    viewType: 'pdf',
    showTableData: true,
    skladiste: undefined,
    skladisteLabel: undefined,
    fiveDecimalStartTimestamp: 1697443176,
    primkaZaEdit: undefined
  }),
  async mounted () {
    // await this.getAllWarehouses()
    /*
    var docRef = df.doc(`user_companies/${auth.currentUser.uid}`)
    this.unsubscribe = docRef.onSnapshot((doc) => {
      const data = doc.data()
      if (data?.companies) {
        this.companies = Object.keys(data.companies)
          .map((key) => {
            return data.companies[key]
          })
          .filter((company) => company?.resource_status === 'ACTIVE')
          .sort((company1, company2) => {
            if (company1.id < company2.id) return -1
            if (company1.id > company2.id) return 1
          })
      }
    })
*/
    var docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
    docRef.onSnapshot((doc) => {
      if (doc && doc.data() && doc.data().locations && Object.keys(doc.data().locations).length > 0) {
        const data = doc.data()
        if (data?.locations) {
          this.pointsOfSale = Object.keys(data.locations)
            .map(key => {
              if (state.getPointOfSale().id && data.locations[key].id === state.getPointOfSale().id) {
                this.selectedLocation = data.locations[key]
                this.setWarehouseChoices()
              }
              return data.locations[key]
            })
            .filter(location => location?.resource_status === 'ACTIVE')
            .sort((location1, location2) => {
              if (location1.id < location2.id) return -1
              if (location1.id > location2.id) return 1
            })
        }
      }
    })
  },
  computed: {

    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    }
  },
  methods: {
    showDoc () { this.$modal.show('documents') },
    hideDoc () {
      this.$modal.hide('documents')
    },
    hideDocEdit () {
      this.$modal.hide('edit')
      this.unclickRow()
      this.getDocuments()
    },
    showWarehouseLabel (realType, type) {
      if (type === 'inventory_transfer') {
        switch (realType) {
          case 'delivery_note':
            return 'M - primka'
          case 'shipment_note':
            return 'M - otpremnica'
        }
      }
    },
    showDocumentType (realType, type) {
      if (type === 'inventory_transfer') {
        switch (realType) {
          case 'delivery_note':
            return 'M - primka'
          case 'shipment_note':
            return 'M - otpremnica'
        }
      }

      if (realType === 'delivery_note') {
        return 'Primka'
      }
    },
    calculatePn (returnAsNumber = false) {
      var totalPn = 0
      if (this.selectedDeliveryNote) {
        Object.keys(this.selectedDeliveryNote.extended_items).forEach(key => {
          var it = this.selectedDeliveryNote.extended_items[key]
          if (it.warehouse_data.retail_price && it.warehouse_data.retail_price > 0) {
            totalPn += it.warehouse_data.retail_price * (it.item.stock / 1000)
          }
        })

        if (this.selectedDeliveryNote.total_retail_price) {
          totalPn = this.selectedDeliveryNote.total_retail_price
        }

        if (!returnAsNumber) {
          return this.formatCurrency(totalPn)
        } else {
          return totalPn
        }
      }

      if (!returnAsNumber) {
        return ''
      } else {
        return 0
      }
    },
    warehouseSelected () {
      this.disableButton = false
    },
    setWarehouseChoices () {
      if (this.selectedLocation && this.selectedLocation.id) {
        this.getAllWarehouses(this.selectedLocation.id)
      }
    },
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    },
    formatDate: function (date) {
      if (date === '') { return '' }
      date = new Date(date * 1000)

      return moment(date).format('DD.MM.YYYY')
    },
    async getDocuments () {
      this.primke = []
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000
      var query =
      df.collection(`warehouses/${this.selectedWarehouse.value}/delivery_notes`)
        .where('order_date', '>=', dateFrom)
        .where('order_date', '<=', dateTo + 86399)
        .orderBy('order_date', 'desc')
      // .limit(15)

      if (this.deliveryNumberSearch !== '') {
        query = query.where('delivery_number', '==', this.deliveryNumberSearch)
      }

      query.get()
        .then((documentSnapshots) => {
          this.loading = false
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            // Dohvati otpremnice za meduskladisnicu
            var shipmentquery =
            df.collection(`warehouses/${this.selectedWarehouse.value}/shipment_notes`)
              .where('order_date', '>=', dateFrom)
              .where('order_date', '<=', dateTo + 86399)
              .where('type', '==', 'inventory_transfer')
              .orderBy('order_date', 'desc')
            // .limit(15)

            // if (this.deliveryNumberSearch !== '') {
            //   query = query.where('delivery_number', '==', this.deliveryNumberSearch)
            // }

            shipmentquery.get()
              .then((shipmentDocumentSnapshots) => {
                shipmentDocumentSnapshots.docs.forEach((item) => {
                  var otpremnica = item.data()

                  // Sluzi za dio da li je skladiste koje prima ili s kojeg se skidalo u slucaju medjuskladisnice
                  otpremnica.realType = 'shipment_note'
                  otpremnica.skladiste = otpremnica.ship_name
                  otpremnica.skladisteLabel = 'Odredišno skladište'

                  let ukupno = 0
                  for (const prop in otpremnica.extended_items) {
                    const rabat = otpremnica.extended_items[prop].warehouse_data.supplier_rebate / 10000
                    const totalPriceWithRabat = otpremnica.extended_items[prop].warehouse_data.received_amount_purchase_price - otpremnica.extended_items[prop].warehouse_data.received_amount_purchase_price * (rabat)
                    const totalPriceNoPdv = totalPriceWithRabat * otpremnica.extended_items[prop].warehouse_data.received_amount_quantity / 1000
                    ukupno += totalPriceNoPdv
                  }
                  otpremnica.ukupno = ukupno.toFixed(2)
                  this.primke.push(otpremnica)
                })
              })

            documentSnapshots.docs.forEach((item) => {
              var primka = item.data()

              if (primka.type === '') {
                primka.type = 'delivery_note'
              }

              // Sluzi za dio da li je skladiste koje prima ili s kojeg se skidalo u slucaju medjuskladisnice
              primka.realType = 'delivery_note'
              if (primka.type === 'inventory_transfer') {
                primka.skladiste = primka.ship_name
                primka.skladisteLabel = 'Izvorišno skladište'
              }

              let ukupno = 0
              for (const prop in primka.extended_items) {
                const rabat = primka.extended_items[prop].warehouse_data.supplier_rebate / 10000
                // NAKON OVOG DATUMA UVEDENA OVA CIJENA NA 5 DECIMALA SAMO ZA OBICNE PRIMKE
                var receivedAmountPurchasePrice = primka.extended_items[prop].warehouse_data.received_amount_purchase_price
                if ((primka.type === 'delivery_note' || primka.type === 'delivery_notes') && primka.created > this.fiveDecimalStartTimestamp) {
                  receivedAmountPurchasePrice /= 1000
                }
                const totalPriceWithRabat = (receivedAmountPurchasePrice) - (receivedAmountPurchasePrice) * (rabat)
                const totalPriceNoPdv = totalPriceWithRabat * primka.extended_items[prop].warehouse_data.received_amount_quantity / 1000
                ukupno += totalPriceNoPdv
              }

              primka.ukupno = ukupno.toFixed(2)
              if (primka.total_base_price) {
                primka.ukupno = primka.total_base_price
              }

              this.primke.push(primka)
            })
          }
        })
    },
    async getAllWarehouses (locationId) {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('location_id', '==', locationId)

      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          if (warehouse.selling_warehouse === true) {
            this.selectedWarehouse = { name: warehouse.name, value: warehouse.id, warehouse: warehouse }
            this.warehouseSelected()
          }
          this.warehouses.push({ name: warehouse.name, value: warehouse.id, warehouse: warehouse })
        })
      })
    },
    unclickRow () {
      this.stavke = []
    },
    rowClicked (row) {
      this.toggleSelection(row.id)
      this.stavke = []
      this.deliveryNumber = row.delivery_number
      this.warehouseName = row.warehouse_name
      this.ukupnoBezPdv = row.total_base_price ? clone(row.total_base_price) : 0
      this.inputTaxTotal = row.total_tax ? clone(row.total_tax) : 0
      this.ukupnoBrutto = row.total_brutto ? clone(row.total_brutto) : 0

      this.datumKreiranja = row.created
      this.dobavljac = row.customer_name
      this.fobDescription = row.fob_description
      this.selectedDeliveryNote = row
      this.skladiste = row.skladiste
      this.skladisteLabel = row.skladisteLabel
      var ukupnoBezPdvIsEmpty = false

      if (this.ukupnoBezPdv === 0 || this.ukupnoBezPdv === undefined) {
        ukupnoBezPdvIsEmpty = true
      }
      for (const prop in row.extended_items) {
        const rabat = row.extended_items[prop].warehouse_data.supplier_rebate / 10000

        var receivedAmountPurchasePrice = clone(row.extended_items[prop].warehouse_data.received_amount_purchase_price)
        // NAKON OVOG DATUMA UVEDENA OVA CIJENA NA 5 DECIMALA SAMO ZA OBICNE PRIMKE
        if ((row.type === 'delivery_note' || row.type === 'delivery_notes') && row.created > this.fiveDecimalStartTimestamp) {
          receivedAmountPurchasePrice /= 1000
        }

        const totalPriceWithRabat = receivedAmountPurchasePrice - (receivedAmountPurchasePrice * (rabat))
        const totalPriceNoPdv = totalPriceWithRabat * row.extended_items[prop].warehouse_data.received_amount_quantity / 1000

        const retailPriceTotal = row.extended_items[prop].warehouse_data.retail_price * row.extended_items[prop].warehouse_data.received_amount_quantity / 1000

        if (ukupnoBezPdvIsEmpty) {
          this.ukupnoBezPdv += totalPriceNoPdv
        }
        const inputTax = totalPriceNoPdv * (row.extended_items[prop].warehouse_data.vat / 10000)

        if (this.inputTaxTotal === 0 || this.inputTaxTotal === undefined) {
          this.inputTaxTotal += inputTax
        }
        this.stavke.push({
          id: row.extended_items[prop].id,
          name: row.extended_items[prop].item.name,
          quantity: row.extended_items[prop].warehouse_data.received_amount_quantity / 1000,
          measureUnit: row.extended_items[prop].warehouse_data.received_amount_measure_unit,
          purchasePrice: receivedAmountPurchasePrice,
          supplierRebate: row.extended_items[prop].warehouse_data.supplier_rebate,
          totalPriceWithRabat: totalPriceWithRabat,
          totalPriceNoPdv: totalPriceNoPdv,
          inputTax: inputTax,
          pdv: row.extended_items[prop].warehouse_data.vat,
          refundPrice: row.extended_items[prop].warehouse_data.retail_price,
          refundPriceTotal: retailPriceTotal
        })
      }
    },
    toggleSelection (keyID) {
      if (this.selectedRows.includes(keyID)) {
        this.selectedRows = this.selectedRows.filter(
          selectedKeyID => selectedKeyID !== keyID
        )
      } else {
        this.selectedRows.splice(0)
        this.selectedRows.push(keyID)
      }
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()
      doc = await setTable(doc, '#title', 10)
      var finalY = doc.lastAutoTable.finalY || 10

      doc = await setTable(doc, '#firma', finalY + 10)
      finalY = doc.lastAutoTable.finalY || 10

      doc = await setTable(doc, '#tableTraffic', finalY + 10)
      finalY = doc.lastAutoTable.finalY || 10

      doc = await setPageNumber(doc)

      doc.save('Skladišni dokument.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['title', 'firma', 'tableTraffic']
        var fileName = 'Skladišni dokument.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    editDeliveryNote () {
      this.primkaZaEdit = this.primke.find(primka => this.selectedRows.includes(primka.id) && primka.type === 'delivery_notes')
      this.$modal.show('edit')
    }
  }
}
</script>
<style scoped>
.enabledbutton {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}
.selectedrow-456 {
  background-color: #2c97c5 !important;
  border-color: #2c97c5 !important;
  color: white;
  font-weight: 700;
}

.createDelivery >>> .vm--modal {
  border-radius: 25px !important;
}

.editDelivery >>> .vm--modal {
  border-radius: 25px !important;
}
</style>
