<template>
  <div>
    <div style="width: 100%" class="fill-height">
      <v-row>
        <v-col class="stepper-wrapper" :style="`margin-left: ${margin}`">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step color="blue" step="1" :complete="step > 1">
                Odaberite dokument za izradu i skladište
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step color="blue" step="2" :complete="step > 2">
                Dokument
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step color="blue" step="3" :complete="step > 3">
                Artikli
              </v-stepper-step>
              <v-divider
                v-if="
                  selectedDocument === 'ddf7ea58-2988-4d4a-9240-7f462d3bccae' ||
                  selectedDocument === 'ddf7ea58-2988-4d4a-9240-7f462d3bcca9' ||
                  selectedDocument === 'a8b09a41-cd24-4210-bc70-0e733f478db7'
                "
              ></v-divider>
              <v-stepper-step
                v-if="
                  selectedDocument === 'ddf7ea58-2988-4d4a-9240-7f462d3bccae' ||
                  selectedDocument === 'ddf7ea58-2988-4d4a-9240-7f462d3bcca9' ||
                  selectedDocument === 'a8b09a41-cd24-4210-bc70-0e733f478db7'
                "
                color="blue"
                step="4"
              >
                Ukupno
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-row style="margin-top: 20px" class="justify-center">
                  <v-col cols="8" sm="6" lg="4" :class="['px-0']">
                    <v-select
                      label="Odaberite prodajno mjesto"
                      v-model="selectedLocation"
                      :items="locations"
                      item-text="name"
                      item-value="value"
                      return-object
                      @change="filterWh()"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="8" sm="6" lg="4" :class="['px-0']">
                    <v-select
                      label="Odaberite skladište"
                      v-model="selectedWarehouse"
                      :items="warehouses"
                      item-text="name"
                      item-value="value"
                      return-object
                      @change="setDocuments"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row style="margin-top: 20px" class="justify-center">
                  <v-col cols="8" sm="6" lg="4" :class="['px-0']">
                    <v-select
                      label="Odaberite dokument za izradu"
                      v-model="selectedDocument"
                      :items="documents"
                      item-text="name"
                      item-value="value"
                      @change="generateDocumentGui"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row style="margin-top: 20px" class="justify-center">
                  <v-col
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      :disabled="
                        selectedDocument.length === 0 ||
                        selectedWarehouse.length === 0
                      "
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="changeStep(step + 1)"
                      >Nastavi</v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-row>
                  <template v-for="(field, idx) in documentFields">
                    <v-col
                      cols="6"
                      class="pt-6"
                      :key="idx"
                      v-if="
                        field.type === 'textInput' ||
                        field.type === 'dropdown' ||
                        field.type === 'date'
                      "
                      v-show="!field.hidden"
                    >
                      <v-card outlined>
                        <v-card-actions>
                          <v-text-field
                            class="mt-1 ml-4 mr-4"
                            v-if="field.type === 'textInput'"
                            :label="field.field_name"
                            :value="field.field_id"
                            v-model="vueFields[field.field_id]"
                            v-show="!field.hidden"
                          >
                          </v-text-field>
                          <v-select
                            class="mt-1 ml-4 mr-4"
                            :label="field.label"
                            v-if="field.type === 'dropdown'"
                            v-model="field.field_name"
                            :items="field.values"
                            item-text="name"
                            item-value="value"
                            @change="callChange(field.change, field.field_name)"
                          >
                          </v-select>
                          <v-menu
                            v-if="field.type === 'date'"
                            v-model="vueFields[field.field_name]"
                            offset-y
                            :close-on-content-click="true"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                style="margin-top: 22px; margin-bottom: 4px"
                                :value="vueFields[field.field_id]"
                                :label="field.field_name"
                                dense
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="vueFields[field.field_id]"
                              no-title
                              scrollable
                              locale="hr-HR"
                              @change="changeDate(field.field_id, field.id)"
                              @input="vueFields[field.field_name] = false"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
                <v-row style="margin-top: 20px" class="justify-center">
                  <v-col
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="changeStep(step - 1)"
                      >Vrati se</v-btn
                    >
                  </v-col>
                  <v-col
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="changeStep(step + 1)"
                      >Nastavi</v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-row class="pa-5">
                  <v-row align="center">
                    <v-col cols="12">
                      <v-btn
                        v-if="headers.length > 1"
                        class="okButton white--text"
                        height="45"
                        @click="openAddToItemTable"
                        @keydown.enter="openAddToItemTable"
                        @keydown="preventTextInput($event)"
                        >Dodaj artikl
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-row>
                <v-alert
                  v-model="alertShow"
                  max-width="365"
                  :type="alertType"
                  :color="alertType"
                  dismissible
                  >Artikl već postoji na listi, molimo editirajte postojeći
                  artikl u tablici</v-alert
                >
                <v-dialog
                  v-model="dialog"
                  @keydown.esc.stop="close"
                  scrollable
                  max-width="600"
                  min-width="600"
                >
                  <v-form ref="dialogForm" @submit.prevent="submit">
                    <v-card>
                      <v-card-title
                        class="d-flex"
                        style="vertical-align: middle"
                      >
                        <v-row class="pa-3 mb-2">
                          <h2 class="text--secondary">
                            <div>Dodaj artikl</div>
                          </h2>
                          <v-spacer></v-spacer>
                          <v-icon large @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row class="pb-0 mb-0">
                            <v-col cols="12" class="pb-0 mb-0">
                              <v-select
                                v-if="showSelect"
                                label="Odaberi artikl"
                                bottom
                                outlined
                                ref="mySelect"
                                dense
                                :dropdown-should-open="() => true"
                                v-model="selectedItem"
                                :items="warehouseItems"
                                return-object
                                class="pt-2"
                                item-text="item.name"
                                :menu-props="{ bottom: true }"
                                hide-selected
                                id="itemSelection"
                                autofocus
                                @change="setSelectedItemValues"
                              >
                                <template v-slot:prepend-item>
                                  <v-text-field
                                    autofocus
                                    class="pr-5 pl-5"
                                    label="Pretraži artikle"
                                    @click:prepend-inner="getItems"
                                    prepend-inner-icon="mdi-magnify"
                                    v-model="searchModel"
                                    @keyup.enter="getItems"
                                    :loading="loading"
                                    id="searchItem"
                                    hide-details
                                  >
                                  </v-text-field>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                                <template v-slot:[`item.item`]="{ item }">
                                  {{ item.name }}
                                </template>
                                <template v-slot:append-item>
                                  <v-divider class="mt-2"></v-divider>
                                  <div
                                    class="pa-0 align-center justify-center d-flex"
                                  >
                                    <v-btn
                                      style="width: 30%"
                                      class="mt-2 okButton white--text"
                                      height="30"
                                      @click="openAddWhItem()"
                                      >Kreiraj artikl</v-btn
                                    >
                                  </div>
                                </template>
                              </v-select>
                              <div v-if="!showSelect">
                                <h2>{{ selectedItem.item.name }}</h2>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="selectedItem !== undefined"
                            class="pl-3 pr-3"
                          >
                            <v-text-field
                              label="Trenutno količina na skladištu"
                              outlined
                              dense
                              hide-details
                              readonly
                              class="pd-2 md-2"
                              max-height="20"
                              v-if="selectedItem !== undefined"
                              :value="currentStock"
                            >
                            </v-text-field>
                          </v-row>
                          <v-row class="pt-2">
                            <v-col class="pt-0">
                              <v-select
                                label="Mjerna jedinica"
                                v-model="measureUnit"
                                :items="measureUnits"
                                item-text="name"
                                item-value="value"
                                return-object
                              >
                              </v-select>
                              <template v-for="(field, id) in vueAddFields">
                                <v-text-field
                                  :key="id"
                                  @change="
                                    calculatePrices(field.field.field_id)
                                  "
                                  v-if="id % 2 == 1"
                                  :label="field.text"
                                  v-model="vueFields[field.field.field_id]"
                                  >{{ field.name }}
                                </v-text-field>
                              </template>
                            </v-col>
                            <v-col class="pt-0">
                              <template v-for="(field, id) in vueAddFields">
                                <v-text-field
                                  @change="
                                    calculatePrices(field.field.field_id)
                                  "
                                  :key="id"
                                  v-if="id % 2 == 0"
                                  :label="field.text"
                                  v-model="vueFields[field.field.field_id]"
                                  >{{ field.name }}
                                </v-text-field>
                                <v-checkbox
                                  :key="id"
                                  v-if="
                                    field.field.field_id === 'refundPriceInput'
                                  "
                                  label="Povratna naknada uključena u cijenu"
                                  v-model="refundPriceInPrice"
                                >
                                </v-checkbox>
                              </template>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center">
                        <v-btn
                          class="okButton white--text"
                          height="45"
                          @click="addToTable(selectedItem)"
                          @keydown="preventTextInput($event)"
                        >
                          Dodaj
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </v-dialog>
                <v-data-table
                  v-if="headers.length > 1"
                  :headers="headers"
                  :items="tableRows"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:body="{ items, headers }">
                    <tbody>
                      <tr v-for="(item, idx) in items" :key="idx">
                        <td v-for="(header, key) in headers" :key="key">
                          <v-edit-dialog
                            :return-value.sync="item[header.value]"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item[header.value] }}
                            <template v-slot:input>
                              <v-text-field
                                v-if="headers.length - 1 !== key"
                                v-model="item[header.value]"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                          <v-icon
                            v-if="headers.length - 1 === key"
                            small
                            @click="deleteFromTable(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
                <v-row style="margin-top: 20px" class="justify-center">
                  <v-col
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="changeStep(step - 1)"
                      >Vrati se</v-btn
                    >
                  </v-col>
                  <v-col
                    v-if="
                      selectedDocument !==
                        'ddf7ea58-2988-4d4a-9240-7f462d3bccae' &&
                      selectedDocument !==
                        'ddf7ea58-2988-4d4a-9240-7f462d3bcca9' &&
                      selectedDocument !==
                        'a8b09a41-cd24-4210-bc70-0e733f478db7'
                    "
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      v-if="headers.length > 1"
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="callMethod"
                      >Spremi</v-btn
                    >
                  </v-col>
                  <v-col
                    v-else
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      v-if="headers.length > 1"
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="changeStep(step + 1)"
                      >Nastavi</v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-row class="mt-2">
                  <v-data-table
                    fixed-header
                    :headers="stavkeHeaders"
                    :items="stavke"
                    hide-default-footer
                    dense
                    id="stavket"
                    height="100%"
                    item-key="id"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.name }}</td>
                        <td style="text-align: right">
                          {{ formatCurrency(item.quantity) }}
                        </td>
                        <td>{{ item.measureUnit }}</td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.purchasePrice"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.purchasePrice }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.purchasePrice"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.supplierRebate"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.supplierRebate }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.supplierRebate"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.totalPriceWithRabat"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.totalPriceWithRabat }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.totalPriceWithRabat"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.totalPriceNoPdv"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.totalPriceNoPdv }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.totalPriceNoPdv"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.vat"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.vat }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.vat"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.inputTax"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.inputTax }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.inputTax"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.totalPriceWithoutRabat"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.totalPriceWithoutRabat }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.totalPriceWithoutRabat"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.brutto"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.brutto }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.brutto"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="item.refundPrice"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ item.refundPrice }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="item.refundPrice"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </td>
                      </tr>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer" style="text-align: right">
                          Povratna naknada
                        </th>

                        <th class="footer" style="text-align: right">
                          {{ formatCurrency(this.totalRefund) }}
                        </th>
                        <th class="footer" style="text-align: right"></th>
                      </tr>
                      <tr>
                        <th class="footer">Ukupno</th>
                        <th class="footer" style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="totalQuantity"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ totalQuantity }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="totalQuantity"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </th>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right"></th>
                        <th class="footer" style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="totalBasePrice"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ totalBasePrice }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="totalBasePrice"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </th>
                        <th class="footer"></th>
                        <th class="footer" style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="totalTax"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ totalTax }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="totalTax"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </th>
                        <th class="footer" style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="totalValueWithoutRebate"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ totalValueWithoutRebate }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="totalValueWithoutRebate"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </th>
                        <th class="footer" style="text-align: right">
                          <v-edit-dialog
                            :return-value.sync="totalBrutto"
                            @save="save"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                          >
                            {{ totalBrutto }}
                            <template v-slot:input>
                              <v-text-field
                                v-model="totalBrutto"
                                label="Edit"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                        </th>
                      </tr>
                    </template>
                  </v-data-table>
                </v-row>
                <v-row style="margin-top: 20px" class="justify-center">
                  <v-col
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="changeStep(step - 1)"
                      >Vrati se</v-btn
                    >
                  </v-col>
                  <v-col
                    cols="1"
                    sm="1"
                    lg="1"
                    align-content="center"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      v-if="headers.length > 1"
                      class="mb-1 okButton white--text"
                      height="45"
                      @click="callMethod"
                      >Spremi</v-btn
                    >
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
      <add-wh-item ref="addWhItem" @success="itemCreated"></add-wh-item>
      <add-wh-pricelist-item
        ref="addWhPricelistItem"
        @success="itemAddedToPricelist"
      ></add-wh-pricelist-item>
    </div>
    <v-overlay
      v-if="itemLoading"
      z-index="1000"
      style="height: 100%; width: 100%"
    >
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <edit-wh-item ref="editWhItem" @success="refreshSearch()"></edit-wh-item>
  </div>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { v4 as uuidv4 } from 'uuid'
import { clone } from '@/plugins/utils'
import triGram from '@/mixins/trigram'
import { collection, query, getDocs, where, limit } from 'firebase/firestore'
import AddWhItem from '@/modules/company/components/AddWhItem'
import AddWhPricelistItem from '@/modules/point-of-sale/components/AddWhPricelistItem'
import editWhItem from '@/modules/company/components/EditWhItem'

export default {
  name: 'WarehouseDocuments',
  components: { AddWhItem, AddWhPricelistItem, editWhItem },
  inject: ['showLoader', 'hideLoader', 'confirmClose'],
  mixins: [flow, applicationSettings, barcodeChecker, triGram],
  data: () => ({
    step: 1,
    margin: '56px',
    stavkeHeaders: [{ text: 'Artikl', value: 'name' },
      { text: 'Količina', value: 'quantity', align: 'end' },
      { text: 'Jedinica mjere', value: 'measureUnit' },
      { text: 'Nabavna cijena', value: 'purchasePrice', align: 'end' },
      { text: 'Rabat (%)', value: 'supplierRebate', align: 'end' },
      { text: 'Cijena', value: 'totalPriceWithoutRabat', align: 'end' },
      { text: 'Ukupno', value: 'totalPriceNoPdv', align: 'end' },
      { text: 'PDV(%)', value: 'vat', align: 'end' },
      { text: 'Predporez', value: 'inputTax', align: 'end' },
      { text: 'Vrijednost', value: 'priceWithoutRabat', align: 'end' },
      { text: 'Brutto', value: 'brutto', align: 'end' },
      { text: 'Pn', value: 'refundPrice', align: 'end' }
    ],
    dialog: false,
    dialogDelete: false,
    loading: false,
    selectedItem: undefined,
    warehouseItems: [],
    lagerItems: [],
    whModuleItems: [],
    itemSelection: false,
    items: [],
    datePicker: '',
    fieldData: [],
    currency: 'EUR',
    documents: [],
    allDocuments: [],
    configDocuments: [],
    documentFields: [],
    selectedDocument: '',
    searchModel: '',
    listeners: [],
    multiplier: 0,
    associateData: [],
    vueFields: new Map(),
    adresaFirmeKlijenta: '',
    form: '',
    searchField: '',
    vueTextInputAfter: [],
    warehouses: [],
    allWarehouses: [],
    selectedWarehouse: '',
    vueAddFields: [],
    headers: [],
    tableRows: [],
    alertType: 'error',
    alertShow: false,
    selectedLocation: undefined,
    locations: [],
    checkRadio: '',
    showLocationSelector: false,
    choosenText: '',
    measureUnit: undefined,
    measureUnits: [],
    measureUnitChoosen: undefined,
    stavke: [],
    totalRefund: 0,
    itemLoading: false,
    showSelect: true,
    refundPriceInPrice: false,
    totalQuantity: undefined,
    totalBasePrice: undefined,
    totalTax: undefined,
    totalValueWithoutRebate: undefined,
    totalRetailPrice: undefined,
    totalBrutto: undefined,
    fiveDecimalStartTimestamp: 1697443176
  }),

  computed: {
    radioValues () {
      return [
        { name: 'Na firmi', value: 'company' },
        { name: 'Na prodajnom mjestu', value: 'pointOfSale' },
        { name: 'Sva', value: 'all' }
        // { name: '$vuetify.languages.en', value: 'en' }
      ]
    },
    currentStock () {
      if (this.selectedItem !== undefined && this.selectedItem !== null && this.selectedItem.warehouse_data !== undefined && this.selectedItem.warehouse_data.quantity !== undefined) {
        return this.selectedItem.warehouse_data.quantity /
                        this.multiplier
      } else {
        return 0
      }
    },
    totalWithRefund () {
      var bruttoZbroj = 0
      this.stavke.forEach(item => {
        if (item.brutto) {
          var temp = parseFloat(item.brutto)
          bruttoZbroj += temp
        }
      })

      return this.formatCurrency(bruttoZbroj + this.totalRefund / 100, 1)
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  created () {
    this.margin = state.getMargin()
  },
  async mounted () {
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.checkRadio = 'pointOfSale'
    if (state.getPointOfSale().id === undefined || state.getPointOfSale.id === '') {
      this.checkRadio = 'company'
    }
    await this.getAllWarehouses()
    await this.getAllDocuments()

    // DOHVATI SVE LOKACIJE ZA FIRMU
    await this.getLocations()

    // DOHVATI SVA SKLADISTA I PODATKE O NJIMA
    this.getMultiplier()
  },
  beforeDestroy () {
    this.detachListeners()
  },
  methods: {

    refreshSearch () {
      this.itemLoading = true
      setTimeout(() => {
        this.itemLoading = false
        var temp = clone(this.searchModel)
        this.searchModel = ''
        this.getItems()

        this.searchModel = temp

        this.$refs.mySelect.focus()
        this.$refs.mySelect.activateMenu()

        this.getItems()
      }, 2500)
    },
    openEditItem (item) {
      this.$refs.editWhItem.open(item)
    },
    zbrojiPolje (fieldId) {
      var zbroj = 0
      this.stavke.forEach(item => {
        if (item[fieldId]) {
          var temp = parseFloat(item[fieldId])

          zbroj += temp
        }
      })

      return zbroj.toFixed(2)
    },
    itemAddedToPricelist (item) {
      this.itemLoading = true
      const listener = df.doc(`location_pricelist/${item.location}/items/${item.id}`)
        .onSnapshot(doc => {
          if (doc && doc.data()) {
            var newItem = doc.data()

            if (newItem.status === 'OK' && newItem.stock === 0) {
              this.showMsgBox({ text: 'Artikl ' + item.name + ' je uspješno dodan na cjenik.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
              this.selectedItem = {
                id: newItem.id,
                item: newItem
              }
              this.setSelectedItemValues()
              this.showSelect = false
              this.itemLoading = false
              this.$forceUpdate()
            }
          }
        })

      this.listeners.push(listener)
    },
    itemCreated (item) {
      this.itemLoading = true

      const listener = df.doc(`companies/${state.getCurrentCompany().id}/items/${item.id}`)
        .onSnapshot(doc => {
          if (doc && doc.data()) {
            var newItem = doc.data()

            if (newItem.status === 'OK') {
              this.itemLoading = false
              this.confirm({
                title: 'Kreiranje artikla',
                message: `Artikl ${item.name} je uspješno kreiran, želite li ga dodati na cjenik?`,
                options: {
                  toolbar: true,
                  width: 410,
                  confirmText: 'DA',
                  cancelText: this.$t('$vuetify.close')
                }
              }).then(async (resp) => {
                if (resp) {
                  this.confirmClose()
                  this.$refs.addWhPricelistItem.open(newItem, this.selectedLocation)
                } else {
                  this.selectedItem = {
                    id: newItem.id,
                    item: newItem
                  }
                  this.setSelectedItemValues()
                  this.showSelect = false
                }
                this.itemLoading = false
              })
              this.$forceUpdate()
            } else {
              this.itemLoading = false
            }
          }
        })

      this.listeners.push(listener)
    },
    openAddWhItem () {
      if (this.$refs.addWhItem) {
        this.$refs.addWhItem.open(true)
      }
    },
    filterWh () {
      this.checkRadio = 'pointOfSale'
      this.filterWarehouses()
    },
    calculatePrices (id) {
      if (id === 'nabavnacijenaInput' || id === 'kolicinaInput') { // Mijenja se cijena po artiklu
        this.vueFields.nabavnaCijenaInput = Math.round((this.formatNumber(this.vueFields.nabavnacijenaInput) * this.formatNumber(this.vueFields.kolicinaInput, 100))) / 10000
      }
      if (id === 'nabavnaCijenaInput') { // Mijenja se ukupna cijena
        this.vueFields.nabavnacijenaInput = Math.round((this.formatNumber(this.vueFields.nabavnaCijenaInput) / this.formatNumber(this.vueFields.kolicinaInput)) * 100) / 100
      }
      //  this.vueFields.nabavnaCijenaInput = 2
      this.$forceUpdate()
    },
    sumField (key, divider = 100) {
      // sum data in give key (property)
      return this.formatCurrency(this.stavke.reduce((a, b) => a + (b[key] || 0), 0), divider)
    },
    sumFieldValue (key, divider = 100) {
      // sum data in give key (property)
      return this.stavke.reduce((a, b) => a + (b[key] || 0), 0) / 100
    },
    pdvminus (e, d) {
      var a = e
      var b = (e / (d * 0.01 + 1)) * (d * 0.01)
      var c = e / (d * 0.01 + 1)

      // b = Math.round(b * 100) / 100
      // c = Math.round(c * 100) / 100

      var floatA = Math.trunc(a * 100000)
      var floatB = Math.trunc(b * 100000)
      var floatC = Math.trunc(c * 100000)

      var pdv = {
        price: floatA / 1000,
        total: floatB / 1000,
        base: floatC / 1000
      }

      return pdv
    },
    getItemsFromDocument () {
      const rows = this.tableRows

      this.stavke = []
      this.ukupnoBezPdv = 0
      this.inputTaxTotal = 0
      this.totalRefund = 0

      // this.fobDescription = row.fob_description
      rows.forEach((row) => {
        var refundPrice = 0
        if (row.retail_price) {
          refundPrice = this.formatNumber(row.retail_price, 100)
          this.totalRefund += (this.formatNumber(row.retail_price, 100) * (this.formatNumber(row.stock) / 100))
        }

        if (row.received_amount_purchase_price_pdv) {
          var priceWithPdv = this.toNumber(clone(row.received_amount_purchase_price_pdv))

          // PROVJERI DA LI JE POVRATNA NAKNADA UKLJUCENA U CIJENU, AKO JE SKINI JE SA CIJENE
          if (row.refundPriceIncluded === true) {
            priceWithPdv -= this.toNumber(row.retail_price)
          }

          var realPdv = this.pdvminus(this.formatNumber(priceWithPdv, 100) / 100, (this.formatNumber(row.vat) / 100))
          row.received_amount_purchase_price = realPdv.base / 100
        }

        // AKO RADIMO SAMO SA CIJENOM BEZ PDVA ALI JE UKLJUCENA POVRATNA NAKNADA, SKINI JE SA CIJENE
        var priceWithoutPdv = this.toNumber(clone(row.received_amount_purchase_price))

        if (!row.received_amount_purchase_price_pdv) {
          if (row.refundPriceIncluded === true) {
            priceWithoutPdv -= this.toNumber(row.retail_price)
          }
        }

        const rabat = this.formatNumber(row.supplier_rebate, 1) / 100

        const totalPriceWithRabat = priceWithoutPdv - (priceWithoutPdv * rabat)
        // totalPriceWithRabat = Math.round(totalPriceWithRabat)
        const totalPriceNoPdv = (totalPriceWithRabat) * this.formatNumber(row.stock, 100)

        this.ukupnoBezPdv += totalPriceNoPdv
        const inputTax = totalPriceNoPdv * (this.formatNumber(row.vat) / 10000)
        this.inputTaxTotal += inputTax

        this.stavke.push({
          id: row.id,
          name: row.name,
          quantity: this.formatNumber(row.stock), // Kolicina
          measureUnit: row.measure_unit, // Mjerna jedinica
          purchasePrice: priceWithoutPdv.toFixed(5), // Nabavna cijena
          supplierRebate: this.formatNumber(row.supplier_rebate, 1), // Rabat
          totalPriceWithRabat: totalPriceWithRabat.toFixed(5), // Cijena
          totalPriceNoPdv: (totalPriceNoPdv / 100).toFixed(5), // Ukupno
          inputTax: (inputTax / 100).toFixed(5), // Predporez
          brutto: (this.formatNumber(inputTax) + this.formatNumber(totalPriceNoPdv)) / 10000, // Brutto
          vat: this.formatNumber(row.vat) / 100, // Porez
          totalPriceWithoutRabat: ((priceWithoutPdv * this.formatNumber(row.stock)) / 100).toFixed(5), // Vrijednost
          refundPrice: refundPrice / 100
        })
      })
      this.totalQuantity = this.sumField('quantity')
      this.totalBasePrice = this.zbrojiPolje('totalPriceNoPdv')
      this.totalTax = this.zbrojiPolje('inputTax')
      this.totalValueWithoutRebate = this.zbrojiPolje('totalPriceWithoutRabat')
      this.totalRetailPrice = this.totalRefund
      this.totalBrutto = this.totalWithRefund
    },
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    },
    toNumber (num) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')

      number = parseFloat(number)
      number = number.toFixed(2)

      return parseFloat(number)
    },

    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')

      number = parseFloat(number)

      return parseInt(Math.round(number * multiplier))
    },
    changeStep (nextStep) {
      this.step = nextStep
      if (nextStep === 4) {
        this.getItemsFromDocument()
      }
    },
    filterWarehouses () {
      this.warehouses = this.allWarehouses
      switch (this.checkRadio) {
        case 'company':
          this.warehouses = this.warehouses.filter(wh => {
            if (wh.warehouse.location_id === '') {
              return wh
            }
          })
          break
        case 'pointOfSale':
          this.warehouses = this.warehouses.filter((wh, k) => {
            if (wh.warehouse.location_id === this.selectedLocation.id) {
              if (wh.warehouse.selling_warehouse === true) {
                this.selectedWarehouse = this.warehouses[k]
                this.setDocuments()
              }
              return wh
            }
          })
          break
        case 'all':
      // ALREADY DONE
      }
    },
    changeRadioValue () {
      this.choosenText = ''
      this.selectedWarehouse = {}
      this.showLocationSelector = false
      switch (this.checkRadio) {
        case 'pointOfSale':

          // FILTER ZA IZMJENU RADIO BUTTONA - NE INICIJALNI
          // PRVI SLUCAJ AKO SI NA DOKUMENTE DOSAO PREKO LOKACIJE - SETIRAJ TU LOKACIJU I NJENA SKLADISTA ZA ODABIR
          // DRUGI SLUCAJ AKO SI NA DOKUMENTE DOSAO PREKO FIRME - PROVJERI DA LI SI PRIJE SETIRAO LOKACIJU I AKO JESI SETIRAJ NJENA SKLADISTA
          // TRECI SLUCAJ AKO NEMAS SETIRANU LOKACIJU OBRISI DOSTUPNA SKLADISTA
          if (this.locations.length > 0 && state.getPointOfSale().id !== undefined && state.getPointOfSale().id !== '') {
            this.locations.forEach(location => {
              if (location.id === state.getPointOfSale().id) {
                this.selectedLocation = location

                this.warehouses = this.allWarehouses
                this.warehouses = this.warehouses.filter(wh => {
                  if (wh.warehouse.location_id === this.selectedLocation.id) {
                    return wh
                  }
                })
              }
            })
          } else if ((state.getPointOfSale().id === undefined || state.getPointOfSale().id === '') && this.selectedLocation !== undefined && this.selectedLocation.id !== undefined) {
            this.warehouses = this.allWarehouses
            this.warehouses = this.warehouses.filter(wh => {
              if (wh.warehouse.location_id === this.selectedLocation.id) {
                return wh
              }
            })
          } else {
            this.warehouses = []
          }

          this.showLocationSelector = true
          break
        case 'company':
          this.warehouses = this.allWarehouses
          this.warehouses = this.warehouses.filter(wh => {
            if (wh.warehouse.location_id === '') {
              return wh
            }
          })
          break
        case 'all':
          this.warehouses = this.allWarehouses
      }
    },
    getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
              if (this.checkRadio === 'pointOfSale' && state.getPointOfSale().id !== undefined && loc.id === state.getPointOfSale().id) {
                this.selectedLocation = loc
                this.showLocationSelector = true
                this.filterWh()
              }
            })

            this.$forceUpdate()
          }
        })
    },

    async setSelectedItemValues () {
      if (this.form === 'DeliveryNote') {
        await df.doc(`warehouses/${this.selectedWarehouse.value}/extended_items/${this.selectedItem.item.id}`)
          .get()
          .then(resp => {
            if (resp !== undefined) {
              const item = resp.data()

              if (item !== undefined && item.warehouse_data !== undefined) {
                this.selectedItem.warehouse_data = item.warehouse_data
              }
            }

            if (this.selectedItem.item.warehouse_units !== undefined &&
            this.selectedItem.item.warehouse_units.units !== undefined) {
              this.measureUnits = []

              Object.keys(this.selectedItem.item.warehouse_units.units).forEach(unit => {
                const mUnit = {
                  name: this.selectedItem.item.warehouse_units.units[unit].storage_unit,
                  value: this.selectedItem.item.warehouse_units.units[unit].storage_amount,
                  unit_id: unit,
                  unit: this.selectedItem.item.warehouse_units.units[unit],
                  base_unit: false,
                  default_store: false
                }

                if (this.selectedItem.item.warehouse_units.units[unit].default_store === true) {
                  mUnit.default_store = true
                  this.measureUnit = mUnit
                }

                if (this.selectedItem.item.warehouse_units.units[unit].base_unit === true) {
                  mUnit.base_unit = true
                  // this.measureUnit = mUnit
                }

                this.measureUnits.push(mUnit)
              })

              let foundDefaultStore = false
              this.measureUnits.forEach(mUnit => {
                if (mUnit.default_store) {
                  foundDefaultStore = true
                }
              })

              if (!foundDefaultStore) {
                this.confirm({
                  title: '',
                  message: 'Odabrani artikl ne sadrži zadanu skladišnu jedinicu. <br/> U idućem koraku dodajte zadanu skladišnu jedinicu',
                  options: {
                    toolbar: true,
                    width: 410,
                    confirmText: 'DA',
                    hideCancel: true
                  }
                }).then(async (resp) => {
                  if (resp) {
                    this.confirmClose()
                    this.measureUnit = undefined
                    this.measureUnits = []
                    this.openEditItem(clone(this.selectedItem.item))

                    this.selectedItem = undefined
                  }
                })
              }
            }
          })
      } else {
        await df.doc(`companies/${state.getCurrentCompany().id}/items/${this.selectedItem.item.id}`)
          .get()
          .then(resp => {
            if (resp !== undefined) {
              const item = resp.data()

              if (item !== undefined && item.warehouse_units !== undefined) {
                this.selectedItem.item.warehouse_units = item.warehouse_units
              }
            }

            if (this.selectedItem.item.warehouse_units !== undefined &&
            this.selectedItem.item.warehouse_units.units !== undefined) {
              Object.keys(this.selectedItem.item.warehouse_units.units).forEach(unit => {
                const mUnit = {
                  name: this.selectedItem.item.warehouse_units.units[unit].storage_unit,
                  value: this.selectedItem.item.warehouse_units.units[unit].storage_amount,
                  unit_id: unit,
                  unit: this.selectedItem.item.warehouse_units.units[unit]
                }
                this.measureUnits.push(mUnit)

                if (this.selectedItem.item.warehouse_units.units[unit].base_unit === true) {
                  this.measureUnit = mUnit
                }
              })
            }
          })
      } // ovdje radim kalkulaciju kod unosa
      this.vueAddFields.forEach(addField => {
        Object.keys(this.selectedItem.item).forEach(key => {
          if (addField.data === key) {
            this.vueFields[addField.field.field_id] = this.selectedItem.item[key]
            if (key === 'stock') {
              if (this.vueFields[addField.field.field_id] > 0) {
                this.vueFields[addField.field.field_id] = parseInt(this.vueFields[addField.field.field_id]) + (this.selectedItem.item[key] / this.multiplier)
              } else {
                this.vueFields[addField.field.field_id] = this.selectedItem.item[key] / this.multiplier
              }
            }
          }
        })

        if (this.selectedItem.warehouse_data) {
          Object.keys(this.selectedItem.warehouse_data).forEach(key => {
            var divider = 100
            if (Math.floor(Date.now() / 1000) > this.fiveDecimalStartTimestamp) {
              divider = 100000
            }

            // Ovdje se na cijenu bez pdv-a dodaje pdv kako bi se mogla prikazati cijenu kod dodavanja artikla
            if (addField.data === 'received_amount_purchase_price_pdv') {
              var pp = parseFloat(this.selectedItem.warehouse_data.received_amount_purchase_price) / divider
              var vatMultiplier = (1 + (parseFloat(this.selectedItem.warehouse_data.vat) / 10000)) // 1.25 || 1.13 || 1.05
              var ppWithPdv = pp * vatMultiplier
              this.vueFields[addField.field.field_id] = parseFloat(ppWithPdv.toFixed(2))
            }

            if (addField.data === key) {
              this.vueFields[addField.field.field_id] = this.selectedItem.warehouse_data[key]
              var itemi = ['vat', 'supplier_rebate', 'purchase_price', 'received_amount_purchase_price', 'retail_price']
              if (itemi.includes(key)) {
                this.vueFields[addField.field.field_id] = parseInt(this.vueFields[addField.field.field_id]) / 100
              }

              if (key === 'value') this.vueFields[addField.field.field_id] = 0
              if (key === 'quantity') {
                if (parseInt(this.vueFields[addField.field.field_id]) > 0) {
                  this.vueFields[addField.field.field_id] = parseInt(this.vueFields[addField.field.field_id]) + (this.selectedItem.item[key] / this.multiplier)
                } else {
                  this.vueFields[addField.field.field_id] = this.selectedItem.item[key] / this.multiplier
                }
              }

              // Kod primke se radi na 5 decimala nakon ovog datuma
              if (key === 'received_amount_purchase_price' && this.form === 'DeliveryNote' && Math.floor(Date.now() / 1000) > this.fiveDecimalStartTimestamp) {
                this.vueFields[addField.field.field_id] = parseFloat((parseFloat(this.vueFields[addField.field.field_id]) / 1000).toFixed(2))
              }
            }
          })
        }
      })

      const temp1 = this.vueAddFields
      this.vueAddField = temp1

      const temp2 = this.vueFields
      this.vueFields = temp2

      // Potrebno zbog refresha varijabli sa vrijednostima
      this.$forceUpdate()
    },
    callMethod () {
      const extendedItems = []

      this.tableRows.forEach(extItem => {
        // Zamijeni purchase price iz stavki
        var vat = extItem.vat_amount
        var supplierRebate = extItem.supplier_rebate
        var refundPrice = extItem.retail_price
        var purchasePrice = 0

        this.stavke.forEach(stavka => {
          if (extItem.item.id === stavka.id) {
            purchasePrice = parseFloat(stavka.purchasePrice)

            if (stavka.vat) {
              vat = parseInt(stavka.vat)
            }

            if (stavka.supplierRebate) {
              supplierRebate = parseFloat(stavka.supplierRebate)
            }

            if (stavka.refundPrice) {
              refundPrice = parseFloat(stavka.refundPrice)
            }
          }
        })

        const receivedAmount = { measure_unit: extItem.measure_unit, quantity: this.formatNumber(extItem.stock, this.multiplier), purchase_price: purchasePrice * 100000 }

        this.measureUnits.forEach(unit => {
          if (unit.unit.base_unit === true) {
            extItem.measure_unit = unit.unit.storage_unit
          }
        })
        // PRERACUNAVANJE IZ CUSTOM JEDINICE U BASE JEDINICU
        if (this.measureUnitChoosen !== undefined) {
          this.measureUnitChoosen.forEach(unit => {
            if (unit.itemId === extItem.item.id) {
              if (unit.unit.base_unit === false) {
                extItem.stock = extItem.stock * (unit.unit.storage_amount / this.multiplier)
              }
            }
          })
        }

        if (extItem.purchase_price === 0) {
          extItem.purchase_price = this.formatNumber(extItem.received_amount_purchase_price)
        } else {
          extItem.purchase_price = this.formatNumber(extItem.purchase_price)
        }

        const newItem = {
          id: extItem.item.id,
          item: extItem.item.item,
          warehouse_data: {
            received_amount_measure_unit: receivedAmount.measure_unit,
            received_amount_quantity: receivedAmount.quantity,
            received_amount_purchase_price: receivedAmount.purchase_price,
            ean: extItem.ean ? extItem.ean : '',
            item_id: extItem.item.item.id ? extItem.item.item.id : '',
            item_key: extItem.item.item.code ? extItem.item.item.code : '',
            item_name: extItem.name ? extItem.name : extItem.item.item.name,
            location_key: this.selectedWarehouse.value,
            manufacturer_item_key: '',
            // margin: extItem.margin ? parseInt(extItem.margin * 100) : 0,
            margin: this.formatNumber(extItem.margin),
            // margin_amount: extItem.margin_amount ? parseInt(extItem.margin_amount * 100) : 0,
            margin_amount: this.formatNumber(extItem.margin_amount),
            measure_unit: extItem.storage_unit ? extItem.storage_unit : '',
            partner_item_key: '',
            //  pretax: extItem.pretax ? parseInt(extItem.pretax * 100) : 0,
            pretax: this.formatNumber(extItem.pretax),
            // pretax_amount: extItem.pretax_amount ? parseInt(extItem.pretax_amount * 100) : 0,
            pretax_amount: this.formatNumber(extItem.pretax_amount),
            // purchase_price: extItem.purchase_price ? parseInt(extItem.purchase_price * 100) : 0,
            purchase_price: extItem.purchase_price,
            // quantity: extItem.stock ? parseInt(extItem.stock * this.multiplier) : 0,
            quantity: this.formatNumber(extItem.stock, this.multiplier),
            quantity_on_hand: 0,
            quantity_on_order: 0,
            // rebate_amount: extItem.rebate_amount ? parseInt(extItem.rebate_amount * 100) : 0,
            rebate_amount: this.formatNumber(extItem.rebate_amount),
            // retail_price: extItem.retail_price ? parseInt(extItem.retail_price * 100) : 0,
            retail_price: this.formatNumber(refundPrice),
            sale_price: 0,
            short_description: '',
            stock_days: 0,
            // supplier_price: extItem.supplier_price ? parseInt(extItem.supplier_price * 100) : 0,
            supplier_price: this.formatNumber(extItem.supplier_price),
            // supplier_rebate: extItem.supplier_rebate ? parseInt(extItem.supplier_rebate * 100) : 0,
            supplier_rebate: this.formatNumber(supplierRebate),
            // value: extItem.value ? parseInt(extItem.value * 100) : 0,
            value: this.formatNumber(extItem.value),
            //    vat: extItem.vat ? parseInt(extItem.vat * 100) : 0,
            vat: this.formatNumber(vat),
            //  vat_amount: extItem.vat_amount ? parseInt(extItem.vat_amount * 100) : 0,
            vat_amount: this.formatNumber(extItem.vat_amount),
            //  wholesale_price: extItem.wholesale_price ? parseInt(extItem.wholesale_price * 100) : 0,
            wholesale_price: this.formatNumber(extItem.wholesale_price),
            // price_without_vat: extItem.price_without_vat ? parseInt(extItem.price_without_vat * 100) : 0
            price_without_vat: this.formatNumber(extItem.price_without_vat)

          }
        }

        // newItem.item.stock = parseInt(extItem.stock)
        newItem.item.stock = newItem.warehouse_data.quantity

        extendedItems.push(newItem)
      })

      let docType = ''
      this.documents.forEach(doc => {
        if (doc.document.id === this.selectedDocument) {
          docType = doc.document.topic
        }
      })

      // Exceptions for data
      if (this.form === 'InventoryTransfer') {
        this.vueFields.location_name = this.selectedWarehouse.name
      }

      const payload = {
        action: {
          operation: 'set',
          entity: 'warehouse_document',
          params: {
            id: this.selectedDocument,
            document_id: uuidv4(),
            warehouse_id: this.selectedWarehouse.value,
            warehouse_name: this.selectedWarehouse.name,
            comment: this.vueFields.comment ? this.vueFields.comment : '',
            customer_address: this.vueFields.customer_address ? this.vueFields.customer_address : '',
            customer_attention: this.vueFields.customer_attention ? this.vueFields.customer_attention : '',
            customer_city: this.vueFields.customer_city ? this.vueFields.customer_city : '',
            customer_key: this.vueFields.customer_key ? this.vueFields.customer_key : '',
            customer_mail: this.vueFields.customer_mail ? this.vueFields.customer_mail : '',
            customer_name: this.vueFields.customer_name ? this.vueFields.customer_name : '',
            customer_oib: this.vueFields.customer_oib ? this.vueFields.customer_oib : '',
            customer_order_number: this.vueFields.customer_order_number ? this.vueFields.customer_order_number : '',
            customer_phone: this.vueFields.customer_phone ? this.vueFields.customer_phone : '',
            customer_pono: this.vueFields.customer_pono ? this.vueFields.customer_pono : '',
            customer_tax_id: this.vueFields.customer_tax_id ? this.vueFields.customer_tax_id : '',
            customer_zip: this.vueFields.customer_zip ? this.vueFields.customer_zip : '',
            delivery_number: this.vueFields.delivery_number ? this.vueFields.delivery_number : '',
            due_date: this.vueFields.due_date ? parseInt((new Date(`${this.vueFields.due_date}`).getTime() / 1000).toFixed(0)) : 0,
            fob_description: this.vueFields.fob_description ? this.vueFields.fob_description : '',
            fob_key: this.vueFields.fob_key ? this.vueFields.fob_key : '',
            location_name: this.vueFields.location_name ? this.vueFields.location_name : '',
            order_date: this.vueFields.order_date ? parseInt((new Date(`${this.vueFields.order_date}`).getTime() / 1000).toFixed(0)) : 0,
            quality_check_level_id: this.vueFields.quality_check_level_id ? this.vueFields.quality_check_level_id : '',
            request_date: this.vueFields.request_date ? parseInt((new Date(`${this.vueFields.request_date}`).getTime() / 1000).toFixed(0)) : 0,
            ship_address: this.vueFields.ship_address ? this.vueFields.ship_address : '',
            ship_city: this.vueFields.ship_city ? this.vueFields.ship_city : '',
            ship_date: this.vueFields.ship_date ? parseInt((new Date(`${this.vueFields.ship_date}`).getTime() / 1000).toFixed(0)) : 0,
            ship_doc_number: this.vueFields.ship_doc_number ? this.vueFields.ship_doc_number : '',
            ship_name: this.vueFields.ship_name ? this.vueFields.ship_name : '',
            ship_to_key: this.vueFields.ship_to_key ? this.vueFields.ship_to_key : '',
            ship_zip: this.vueFields.ship_zip ? this.vueFields.ship_zip : '',
            tran_number: this.vueFields.tran_number ? this.vueFields.tran_number : '',
            vendor_id: this.vueFields.vendor_id ? this.vueFields.vendor_id : '',
            vendor_key: this.vueFields.vendor_key ? this.vueFields.vendor_key : '',
            company_id: state.getCurrentCompany().id,
            doc_type: docType,
            location_id: '',
            extended_items: extendedItems,
            total_quantity: this.toNumber(this.totalQuantity) * 100,
            total_base_price: this.toNumber(this.totalBasePrice) * 100,
            total_tax: this.toNumber(this.totalTax) * 100,
            total_value_without_rebate: this.toNumber(this.totalValueWithoutRebate) * 100,
            total_retail_price: this.toNumber(this.totalRetailPrice),
            total_brutto: this.toNumber(this.totalBrutto) * 100
          }
        }
      }

      const reqId = uuidv4()
      this.showLoader()

      df.doc(`request/${reqId}`)
        .set({
          user_id: `${auth.currentUser.uid}`,
          device_id: 'web',
          created: `${new Date().getTime()}`,
          type: 'warehousedocument',
          payload: btoa(
            unescape(encodeURIComponent(JSON.stringify(payload)))
          )
        })
        .catch(() => {
          this.hideLoader()
          this.showMsgBox({
            text: 'Greška prilikom spremanja podataka.',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
        })
        .finally(() => {})
      const unsubscribe = df.doc(`response/${reqId}`).onSnapshot((doc) => {
        if (this.$refs && doc.data()) {
          this.hideLoader()
          unsubscribe()
          if (
            !doc.data().header &&
              !doc.data().body
          ) {
            this.hideLoader()
            this.showMsgBox({
              text: 'Spremanje dokumenta nije uspjelo',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          } else {
            this.hideLoader()
            this.showMsgBox({
              text: 'Spremanje dokumenta uspjelo!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            this.warehouseItems = []
            this.step = 1
            this.selectedWarehouse = ''
            this.selectedDocument = ''
            this.items = []
            /* this.fieldData = []
            this.documentFields = []
            this.associateData = []
            this.vueFields = new Map()
            this.vueTextInputAfter = []
            this.vueAddFields = []
            this.headers = []
            this.tableRows = [] */
          }
        }
      })
    },
    openAddToItemTable () {
      this.dialog = true
    },
    changeDate (fieldId, fieldName) {
      this.vueFields[fieldName] = this.vueFields[fieldId]
    },
    capitalizeFirstLetter (variable) {
      return variable[0].toUpperCase() + variable.slice(1)
    },
    callChange (method, selected) {
      switch (method) {
        case 'selectedAssociate':
          this.selectedAssociate(selected)
          break
        case 'selectedPaymentMethod':
          this.selectedPaymentMethod(selected)
          break
        case 'setDestinationWarehouse':
          this.setDestinationWarehouse(selected)
          break
      }
    },
    setDestinationWarehouse (selected) {
      if (this.allWarehouses.length > 0) {
        this.allWarehouses.forEach(warehouse => {
          if (warehouse.warehouse.id === selected) {
            this.documentFields.forEach(docField => {
              switch (docField.data) {
                case 'selectedDestWarehouse':
                  this.vueFields.ship_to_key = warehouse.value
                  this.vueFields.ship_name = warehouse.name
              }
            })
          }
        })
      }
    },
    selectedAssociate (selected) {
      if (this.associateData.length > 0) {
        this.associateData.forEach(assoc => {
          if (assoc.all.id === selected) {
            this.documentFields.forEach(docField => {
              switch (docField.field_id) {
                case 'customer_address':
                  this.vueFields[docField.field_id] = assoc.all.address
                  break
                case 'customer_oib':
                  this.vueFields[docField.field_id] = assoc.all.oibs[0]
                  break
                case 'customer_name':
                  this.vueFields[docField.field_id] = assoc.all.name
                  break
              }
            })
          }
        })
      }
    },
    selectedPaymentMethod (selected) {
      this.documentFields.forEach(docField => {
        switch (docField.field_id) {
          case 'fob_description':
            this.vueFields[docField.field_id] = selected
            break
        }
      })
    },
    async getLocationName () {
      return new Promise((resolve, reject) => {
        df
          .doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
          .get()
          .then((response) => {
            const locations = response.data()

            const returnLocations = []
            Object.keys(locations.locations).forEach(id => {
              returnLocations.push({ name: locations.locations[id].name, value: id, all: locations.locations[id] })
            })
            resolve(returnLocations)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async getAssociateData () {
      return new Promise((resolve, reject) => {
        df
          .doc(`company_associates/${state.getCurrentCompany().id}`)
          .get()
          .then((response) => {
            const associates = response.data()

            const returnAssociates = []
            Object.keys(associates.associates).forEach(id => {
              returnAssociates.push({ name: associates.associates[id].name, value: id, all: associates.associates[id] })
            })
            resolve(returnAssociates)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generateDocumentGui () {
      this.vueFields = []
      this.documentFields = []
      this.vueAddFields = []
      this.headers = [{
        text: 'Akcija',
        value: 'Ukloni',
        priority: 10000000
      }]
      this.tableRows = []
      let tempDocumentTopic = ''
      const that = this

      this.documents.forEach(doc => {
        if (doc.document.id === this.selectedDocument) {
          tempDocumentTopic = doc.document.topic

          Object.keys(doc.document.mandatory_fields).forEach(fieldId => {
            this.fieldData.forEach(field => {
              if (fieldId === field.id) {
                const realField = {
                  id: field.id,
                  field_id: field.field_id,
                  field_name: doc.document.mandatory_fields[fieldId].field_name ? doc.document.mandatory_fields[fieldId].field_name : field.field_name,
                  priority: doc.document.mandatory_fields[fieldId].options.priority ? doc.document.mandatory_fields[fieldId].options.priority : field.priority,
                  type: doc.document.mandatory_fields[fieldId].type ? doc.document.mandatory_fields[fieldId].type : field.type,
                  data: doc.document.mandatory_fields[fieldId].options.data ? doc.document.mandatory_fields[fieldId].options.data : '',
                  mandatory: true,
                  values: [],
                  change: doc.document.mandatory_fields[fieldId].options.change ? doc.document.mandatory_fields[fieldId].options.change : '',
                  label: field.field_name,
                  hidden: doc.document.mandatory_fields[fieldId].options.hidden ? doc.document.mandatory_fields[fieldId].options.hidden : false
                }

                // Ovaj dio okida metoda za dohvat podataka za selectove - dropdown da popuni podacima - radi asinkrono zato se od tamo pusha u array
                if (realField.type === 'dropdown' && realField.data !== undefined && realField.data !== '') {
                  this.getDropdownData(realField)
                } else {
                  that.documentFields.push(realField)
                  that.documentFields = that.documentFields.sort((a, b) => a.priority - b.priority)
                }

                // Ovaj dio je da svaki dinamicki field ima svoj model i da se moze popuniti njegova vrijednost i dohvatiti
                this.vueFields[realField.field_id] = ''
                if (realField.type === 'date') {
                  this.vueFields[realField.field_id] = new Date().toISOString().substr(0, 10)
                }

                // Ovaj dio sluzi da pushne fieldove ovisno o tipu da se mogu upisati i procitati podaci
                const newField = {
                  text: doc.document.mandatory_fields[fieldId].field_name ? doc.document.mandatory_fields[fieldId].field_name : field.field_name,
                  value: doc.document.mandatory_fields[fieldId].options.data ? doc.document.mandatory_fields[fieldId].options.data : '',
                  priority: doc.document.mandatory_fields[fieldId].options.priority ? doc.document.mandatory_fields[fieldId].options.priority : field.priority,
                  data: doc.document.mandatory_fields[fieldId].options.data ? doc.document.mandatory_fields[fieldId].options.data : '',
                  field: field
                }

                switch (realField.type) {
                  case 'tableHeader':
                    this.headers.push(newField)
                    that.headers = that.headers.sort((a, b) => a.priority - b.priority)
                    break
                  case 'textInputAddForm':
                    this.vueAddFields.push(newField)
                    that.vueAddFields = that.vueAddFields.sort((a, b) => a.priority - b.priority)
                    break
                  case 'textInputAfter':
                    newField.tableData = doc.document.mandatory_fields[fieldId].options.table_data ? doc.document.mandatory_fields[fieldId].options.table_data : ''
                    this.vueTextInputAfter.push(newField)
                }
              }
            })
          })

          Object.keys(doc.document.optional_fields).forEach(fieldId => {
            this.fieldData.forEach(field => {
              if (fieldId === field.id) {
                const realField = {
                  id: field.id,
                  field_id: field.field_id,
                  field_name: doc.document.optional_fields[fieldId].field_name ? doc.document.optional_fields[fieldId].field_name : field.field_name,
                  priority: doc.document.optional_fields[fieldId].options.priority ? doc.document.optional_fields[fieldId].options.priority : field.priority,
                  type: doc.document.optional_fields[fieldId].type ? doc.document.optional_fields[fieldId].type : field.type,
                  data: doc.document.optional_fields[fieldId].options.data ? doc.document.optional_fields[fieldId].options.data : '',
                  mandatory: false,
                  values: [],
                  change: doc.document.optional_fields[fieldId].options.change ? doc.document.optional_fields[fieldId].options.change : '',
                  label: field.field_name,
                  hidden: doc.document.optional_fields[fieldId].options.hidden ? doc.document.optional_fields[fieldId].options.hidden : false
                }

                // Ovaj dio okida metoda za dohvat podataka za selectove - dropdown da popuni podacima - radi asinkrono zato se od tamo pusha u array
                if (realField.type === 'dropdown' && realField.data !== undefined && realField.data !== '') {
                  this.getDropdownData(realField)
                } else {
                  that.documentFields.push(realField)
                  that.documentFields = that.documentFields.sort((a, b) => a.priority - b.priority)
                }

                // Ovaj dio je da svaki dinamicki field ima svoj model i da se moze popuniti njegova vrijednost i dohvatiti
                this.vueFields[realField.field_id] = ''
                if (realField.type === 'date') {
                  this.vueFields[realField.field_id] = new Date().toISOString().substr(0, 10)
                }

                // Ovaj dio sluzi da pushne fieldove ovisno o tipu da se mogu upisati i procitati podaci
                const newField = {
                  text: doc.document.optional_fields[fieldId].field_name ? doc.document.optional_fields[fieldId].field_name : field.field_name,
                  value: doc.document.optional_fields[fieldId].options.data ? doc.document.optional_fields[fieldId].options.data : '',
                  priority: doc.document.optional_fields[fieldId].options.priority ? doc.document.optional_fields[fieldId].options.priority : field.priority,
                  data: doc.document.optional_fields[fieldId].options.data ? doc.document.optional_fields[fieldId].options.data : '',
                  field: field
                }

                switch (realField.type) {
                  case 'tableHeader':
                    this.headers.push(newField)
                    that.headers = that.headers.sort((a, b) => a.priority - b.priority)
                    break
                  case 'textInputAddForm':
                    this.vueAddFields.push(newField)
                    that.vueAddFields = that.vueAddFields.sort((a, b) => a.priority - b.priority)
                    break
                  case 'textInputAfter':
                    newField.tableData = doc.document.optional_fields[fieldId].options.table_data ? doc.document.optional_fields[fieldId].options.table_data : ''
                    this.vueTextInputAfter.push(newField)
                }
              }
            })
          })
        }
      })

      // Ovo jos nema koristi
      switch (tempDocumentTopic) {
        case 'deliverynote':
          this.form = 'DeliveryNote'
          break
        case 'inventorytransfer':
          this.form = 'InventoryTransfer'
          break
        case 'shipmentnote':
          this.form = 'ShipmentNote'
      }
    },
    getDropdownData (realField) {
      const that = this
      switch (realField.data) {
        case 'getAssociateData':
          if (this.associateData.length === 0) {
            this.getAssociateData().then(function (results) {
              that.associateData = results
              realField.values = results
            })
          } else {
            realField.values = this.associateData
          }
          that.documentFields.push(realField)
          that.documentFields = that.documentFields.sort((a, b) => a.priority - b.priority)
          break
        case 'getPaymentMethod':
          realField.values = [
            { name: 'Virman', value: 'Virman' },
            { name: 'Gotovina', value: 'Gotovina' },
            { name: 'Ostalo', value: 'Ostalo' }
          ]
          that.documentFields.push(realField)
          that.documentFields = that.documentFields.sort((a, b) => a.priority - b.priority)
          break
        case 'getWarehouses':
          if (this.allWarehouses.length === 0) {
          } else {
            realField.values = this.allWarehouses
          }
          that.documentFields.push(realField)
          that.documentFields = that.documentFields.sort((a, b) => a.priority - b.priority)
          break
      }
    },
    async getItems () {
      this.warehouseItems = []
      this.loading = true

      var param = 'item.meta'
      var param2 = 'item.status'
      var path = `warehouses/${this.selectedWarehouse.value}/extended_items`
      if (this.form === 'DeliveryNote') {
        param = 'meta'
        param2 = 'status'
        path = `companies/${state.getCurrentCompany().id}/items`
      }

      if (!this.searchModel || this.searchModel === '') {
        return
      }

      var triGramObject = this.triGram(this.searchModel.toLowerCase())

      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`${param}.${name}`, '==', true))
        )
      }

      searchConstraints.push(where(`${param2}`, '==', 'OK'))

      searchConstraints.push(limit(20))

      const constraints = [
        collection(df, path),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)

      const querySnapshot = await getDocs(q)

      const results = []
      querySnapshot.forEach((doc) => {
        var it = doc.data()

        if (!it.has_norm || it.has_norm === false || it.has_norm === null) {
          if (this.form === 'DeliveryNote') {
            results.push({
              id: it.id,
              item: it,
              warehouse_data: {
                created: 0,
                ean: it.barcode,
                item_it: it.id,
                item_key: '',
                item_name: it.name,
                location_key: '',
                manufacturer_item_key: '',
                margin: 0,
                margin_amount: 0,
                measure_unit: it.warehouse_units !== undefined && it.warehouse_units.units !== undefined && it.warehouse_units.units[it.warehouse_units.base_unit].storage_unit !== undefined ? it.warehouse_units.units[it.warehouse_units.base_unit].storage_unit : '',
                partner_item_key: '',
                partner_location_key: '',
                pretax: 0,
                pretax_amount: 0,
                price_without_vat: 0,
                purchase_price: 0,
                quantity: 0,
                quantity_on_hand: 0,
                quantity_on_order: 0,
                rebate_amount: 0,
                retail_price: 0,
                sale_price: 0,
                short_description: '',
                stock_days: 0,
                supplier_price: 0,
                supplier_rebate: 0,
                value: 0,
                vat: 0,
                vat_amount: 0,
                wholesale_price: 0
              }
            })
          } else {
            results.push(it)
          }
        }
      }
      )

      this.warehouseItems = results
      this.warehouseItems = this.warehouseItems.sort((a, b) => a.item.name.localeCompare(b.item.name))
      this.loading = false
    },
    async getAllDocuments () {
      const that = this
      const wdocs = await df.collection('project_constants/warehouse_documents/documents/').get()
      const confDocs = wdocs.docs.map(doc => doc.data())
      this.configDocuments = confDocs

      const tmpFields = await df.doc('project_constants/document_fields').get()
      const fieldData = tmpFields.data()
      Object.keys(fieldData.fields).forEach(id => {
        this.fieldData.push(fieldData.fields[id])
      })

      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('status', '==', 'OK')
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          confDocs.forEach((confDoc) => {
            warehouse.available_documents.forEach(wd => {
              if (wd === confDoc.id) {
                that.allDocuments.push({ name: this.capitalizeFirstLetter(confDoc.name), value: confDoc.id, document: confDoc, warehouseId: warehouse.id, locationId: warehouse.location.id })
              }
            })
          })
        })
      })
      confDocs.forEach((confDoc) => {
        that.allDocuments.push({ name: this.capitalizeFirstLetter(confDoc.name), value: confDoc.id, document: confDoc, warehouseId: '', locationId: '' })
      })

      // this.configDocuments.forEach(doc => {
      //   this.documents.push({ name: this.capitalizeFirstLetter(doc.name), value: doc.id, document: doc, warehouseId: '', locationId: '' })
      // })

      // this.documents = this.configDocuments

      // that.allDocuments = that.documents
    },
    setWarehouseChoices () {
      this.warehouses = this.allWarehouses
      this.warehouses = this.warehouses.filter(wh => {
        if (wh.warehouse.location_id === this.selectedLocation.id) {
          return wh
        }
      })
    },
    setDocuments () {
      // FILTRIRANJE DOKUMENATA PREMA ODABRANOM SKLADIŠTU
      this.documents = this.allDocuments
      this.documents = this.documents.filter(document => {
        if (document.warehouseId === this.selectedWarehouse.value) {
          return document
        }
      })

      this.choosenText = 'TRENUTNO ODABRANO:<br/>Firma: ' + state.getCurrentCompany().name + '<br/>Prodajno mjesto: ' + this.selectedLocation.name + '<br/>Skladište: ' + this.selectedWarehouse.name
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openAddWarehouseItemForm () {
      this.$refs.addWarehouseItem.open(this.multiplier)
    },
    openDialog () {
      this.dialog = true
    },
    openEditForm (item) {
      this.$refs.editWarehouseItem.open(item, this.multiplier)
    },
    async getAllWarehouses () {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('status', '==', 'OK')
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          this.warehouses.push({ name: warehouse.name, value: warehouse.id, warehouse: warehouse })
        })
      })
      this.allWarehouses = this.warehouses
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    deleteFromTable (item) {
      this.tableRows = this.tableRows.filter(row => {
        return row.id !== item.id
      })
    },
    addToTable (it) {
      var item = clone(it)
      var unitData = clone(this.measureUnit)
      unitData.itemId = item.id

      if (this.measureUnitChoosen && this.measureUnitChoosen.length > 0) { this.measureUnitChoosen.push(unitData) } else this.measureUnitChoosen = [unitData]
      // let duplicateFound = false
      this.tableRows.forEach(row => {
        if (item.id === row.id) {
          item.id = uuidv4() + '*' + item.id
          // duplicateFound = true
          // this.alertShow = true
          // this.$refs.dialogForm.reset()
          // this.dialog = false
        }
      })

      // if (duplicateFound) {
      //   return
      // }

      const newTableRow = {}
      this.headers.forEach(header => {
        if (header.data !== undefined && header.data === 'rbrV') {
          const rbrV = this.tableRows.length + 1
          newTableRow.rbrV = rbrV
        }

        Object.keys(item.item).forEach(key => {
          if (key === 'id') {
            newTableRow[key] = item.id
          } else {
            newTableRow[key] = item.item[key]
          }
        })
        Object.keys(item.warehouse_data).forEach(key => {
          newTableRow[key] = item.warehouse_data[key]
        })

        newTableRow.item = item

        this.vueAddFields.forEach(addField => {
          newTableRow[addField.data] = this.vueFields[addField.field.field_id]
        })
      })

      newTableRow.measure_unit = this.measureUnit.unit.storage_unit
      // PARAMETAR KOJI POKAZUJE DA LI JE U CIJENU ARTIKLA UKLJUCENA POVRATNA NAKNADA
      newTableRow.refundPriceIncluded = this.refundPriceInPrice
      this.tableRows.push(newTableRow)

      // LOGIKA JE DA UZMEMO SVE IZ TABLICE I AKO NJIHOV ID ODGOVARA
      // TABLE DATA ONDA AKO JE PRVI DODAMO, A ZA IDUCE ZBROJIMO
      this.vueTextInputAfter.forEach(textInputAfter => {
        this.tableRows.forEach((addField, key) => {
          if (addField[textInputAfter.tableData] !== undefined) {
            if (key === 0) {
              this.vueFields[textInputAfter.field.field_id] = (addField[textInputAfter.tableData]).toString()
            } else {
              let temp = parseInt(this.vueFields[textInputAfter.field.field_id])

              temp += parseInt(addField[textInputAfter.tableData])
              this.vueFields[textInputAfter.field.field_id] = temp.toString()
            }
          }
        })
      })
      this.showSelect = true
      this.selectedItem = undefined

      this.$refs.dialogForm.reset()
    },
    save () {
    },
    cancel () {},
    open () {},
    close () {},
    closeDialog () {
      this.dialog = false
    }
  }
}
</script>
<style scoped>
.stepper-wrapper {
  width: calc(100% - 56px);
}
.footer {
  font-weight: 700;
}
</style>
