<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="430"
    style="z-index: 6"
  >
    <v-form ref="addCategoryForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Kategorija</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  outlined
                  autofocus
                  class="pt-2"
                  label="Naziv"
                  v-model="name"
                  :rules="[rules.req]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="8">
                <v-img
                  ref="picture"
                  :src="picture"
                  contain
                  height="190"
                  :lazy-src="
                    !picture || !pictureDirty
                      ? require('@/assets/no-item.jpg')
                      : ''
                  "
                >
                  <template v-slot:placeholder>
                    <v-img :src="require('@/assets/no-item.jpg')"></v-img>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10">
                <v-file-input
                  label="Odaberi sliku kategorije"
                  style="overflow: hidden"
                  @change="onFileChange"
                  clearable
                  @click:close="onFileChange"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj kategoriju
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
export default {
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    submitting: false,
    picture: '',
    pictureDirty: false,
    rules: {
      req: rules.req('Required field')
    }
  }),
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.$emit('close')
      this.visible = false
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = e => {
        this.picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      this.pictureDirty = true
      if (!file) { // set fake image
        this.picture = require('@/assets/no-item.jpg')
        setTimeout(() => {
          this.picture = ''
        }, 10)
        return
      }

      if (file.size > 500000) {
        this.showMsgBox({
          text: 'Prevelika datoteka, maksimalna dopuštena veličina datoteke je 500 KB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.$refs.addCategoryForm.reset()
        return
      }
      this.createImage(file)
    },
    submit () {
      const that = this
      if (!this.$refs.addCategoryForm.validate()) return
      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()

      this.picture = this.picture && this.picture.split(',').length > 0 ? this.picture.split(',')[1] : undefined

      const payload = {
        action: {
          operation: 'set',
          entity: 'category',
          params: {
            name: this.name,
            company_id: state.getCurrentCompany().id
          }
        }
      }

      if (this.picture !== undefined) {
        payload.action.params.picture = this.picture
      }

      that.$emit('processing', true)
      try {
        df.doc(`request/${reqId}`).set(
          {
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'company',
            payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
          }
        ).then(function () {
          setTimeout(() => {
            that.$refs.addCategoryForm.reset()
            that.submitting = false
            that.visible = false
            that.$emit('add', { ...payload.action.params, action: 'ADD' })
          }, 100)
        }).catch(function (err) {
          that.$emit('processing', false)
          that.showMsgBox({ text: err && err !== '' ? err : 'An error has occurred', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          that.submitting = false
        })
      } catch (err) {
        that.$emit('processing', false)
        that.showMsgBox({ text: err && err !== '' ? err : 'An error has occurred', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        that.submitting = false
      }
    }
  }
}
</script>
