<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    style="z-index: 6"
    max-width="800"
    min-width="800"
  >
    <v-overlay v-show="loader" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-form ref="priceListForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Artikl</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="itemIsPreselected && selectedItem && selectedItem.item"
                cols="12"
              >
                <h2>{{ selectedItem.item.name }}</h2>
              </v-col>
              <v-col
                v-else
                cols="12"
                sm="6"
                class="d-flex justify-start align-center pb-0"
              >
                <v-select
                  label="Artikl sa skladišta"
                  bottom
                  outlined
                  dense
                  v-model="selectedItem"
                  :items="warehouseItems"
                  return-object
                  item-text="item.name"
                  :menu-props="{ bottom: true }"
                  hide-selected
                  :rules="[rules.req]"
                  id="itemSelection"
                  @change="onSelectItem"
                  autofocus
                >
                  <template v-slot:prepend-item>
                    <v-text-field
                      autofocus
                      class="pr-5 pl-5"
                      label="Pretraži skladište"
                      @click:prepend-inner="getItems"
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchModel"
                      @keyup.enter="getItems"
                      :loading="loading"
                      id="searchItem"
                    >
                    </v-text-field>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.item.name }}, Stock:
                    {{ item.item.stock / multiplier }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="priceInEUR !== 0">
                <v-col v-if="priceInEUR !== 0">
                  <v-card
                    :class="[margin > 0 ? 'margin-ok' : 'margin-not-ok']"
                    style="width: 60%"
                  >
                    <v-card-text class="white--text">
                      <v-row class="">
                        <v-col cols="9" class="pr-0 mr-0">
                          Marža: {{ margin }} {{ currency }}

                          <br />
                          Postotak: {{ marginPercent }} %
                        </v-col>
                        <v-col class="pl-3" @click="openDialog()">
                          <v-icon class="pb-1" small @click="openDialog()"
                            >mdi-arrow-expand</v-icon
                          >
                        </v-col>
                      </v-row></v-card-text
                    >
                  </v-card>
                </v-col>
                <v-dialog
                  v-model="showMarginDialog"
                  @keydown.esc="close"
                  persistent
                  scrollable
                  style="z-index: 6"
                  max-width="800"
                >
                  <v-card
                    class="overflow-auto"
                    :class="{
                      'mobile-dialog-card': $vuetify.breakpoint.smAndDown,
                    }"
                  >
                    <v-card-title class="d-flex" style="vertical-align: middle">
                      <v-row class="pa-3 mb-2">
                        <h2 class="text--secondary">Izračun marže</h2>
                        <v-spacer></v-spacer>
                        <v-icon large @click="closeDialog()">mdi-close</v-icon>
                      </v-row>
                    </v-card-title>
                    <v-card-text class="pl-2 pr-2">
                      <v-data-table
                        v-if="margins.length === 1"
                        :headers="marginHeaders"
                        :items="margins[0].qbpp"
                        :items-per-page="-1"
                        class="elevation-3"
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>
                              {{ $options.filters.capitalize(item.name) }}
                            </td>

                            <!-- Dostupna količina po određenoj cijeni -->
                            <td class="text-center">
                              {{ item.available_quantity }}
                            </td>
                            <!-- Količina artikla u normativu-->
                            <td class="text-center">{{ item.quantity }}</td>
                            <!-- Nabavna cijena po potrebnoj količini -->
                            <td class="text-center">
                              {{ item.purchase_price | money(1, currency) }}
                            </td>
                            <!-- Izračun trenutne marže -->
                            <td class="text-center">
                              {{
                                (priceInEUR - item.purchase_price)
                                  | money(1, currency)
                              }}
                            </td>
                            <!-- Izračun trenutnog postotka marže -->
                            <td class="text-center">
                              {{
                                (
                                  ((priceInEUR - item.purchase_price) /
                                    item.purchase_price) *
                                  100
                                ).toFixed(2)
                              }}
                              %
                            </td>
                          </tr>
                        </template>
                      </v-data-table>

                      <template v-for="(mg, kk) in margins">
                        <v-data-table
                          :key="kk"
                          v-if="margins.length > 1"
                          :headers="marginMultiItemHeaders"
                          :items="mg.qbpp"
                          :items-per-page="-1"
                          class="elevation-1"
                          hide-default-footer
                        >
                          <template v-slot:item="{ item }">
                            <tr>
                              <td>
                                {{ $options.filters.capitalize(item.name) }}
                              </td>
                              <td>{{ item.quantity }}</td>
                              <td>
                                {{ item.available_quantity }}
                              </td>
                              <td>
                                {{ item.purchase_price | money(1, currency) }}
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                class="d-flex justify-start align-center pb-0"
              >
                <v-select
                  multiple
                  outlined
                  dense
                  clearable
                  label="Odaberi prodajna mjesta"
                  :items="locations"
                  id="locations"
                  item-text="nameDropDown"
                  item-value="id"
                  persistent-hint
                  v-model="selectedLocations"
                  :rules="[rules.arrReq]"
                  ref="warehouses"
                  @change="onPointOfSaleSelect"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <v-checkbox
                  v-model="manualPriceInput"
                  label="Upis cijene na blagajni"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-select
                  multiple
                  outlined
                  dense
                  label="Odaberite kategoriju"
                  :items="categories"
                  return-object
                  item-text="name"
                  v-model="selectedCategories"
                  :rules="[rules.arrReq]"
                  :loading="categoryProcessing"
                ></v-select>
              </v-col>
              <v-col cols="5">
                <v-btn
                  x-small
                  class="mt-2"
                  style="z-index: 4"
                  bottom
                  right
                  plain
                  text
                  @click="openAddCategoryForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj kategoriju</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-select
                  outlined
                  dense
                  label="Odaberite ustrojstvenu jedinicu"
                  :items="orgUnits"
                  item-text="name"
                  item-value="id"
                  v-model="selectedOrgUnit"
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="selectedItem !== undefined">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="pb-0">
                      <v-col cols="4">
                        <v-text-field
                          outlined
                          disabled
                          v-if="saleUnit !== undefined"
                          v-model="saleUnit.storage_unit"
                          label="Jedinica mjere"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <quantity-input
                          outlined
                          label="Količina"
                          disabled
                          v-if="saleUnit !== undefined"
                          v-model="saleUnit.storage_amount"
                        ></quantity-input>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          outlined
                          v-if="saleUnit !== undefined"
                          :value="
                            saleUnit.storage_unit ? saleUnit.storage_unit : 0
                          "
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <div style="width: 10% !important">
                        <v-checkbox
                          outlined
                          width="2%"
                          v-if="saleUnit !== undefined"
                          v-model="saleUnit.active"
                          :disabled="
                            sellingUnits && sellingUnits.length > 2
                              ? true
                              : false
                          "
                          @click.stop=""
                          label="Aktivan"
                        >
                        </v-checkbox>
                      </div>
                      <div style="width: 5% !important" justify="center">
                        <v-icon
                          small
                          v-if="sellingUnits && sellingUnits.length > 2"
                          @click="showHelpPopup()"
                          @click.stop=""
                          >mdi-help</v-icon
                        >
                      </div>
                      <v-row class="pl-3">
                        <v-col cols="8" @click.stop="">
                          <v-combobox
                            outlined
                            autocomplete="off"
                            v-model="selectedPrices"
                            :items="[]"
                            dense
                            label="Cijene za artikl u valutama"
                            multiple
                            chips
                            clearable
                            v-if="
                              selectedLocations && selectedLocations.length > 0
                            "
                            @click="openAddPriceForm"
                            @keydown.enter="openAddPriceForm"
                            @keydown="preventTextInput($event)"
                            :rules="
                              saleUnit && saleUnit.active === true
                                ? [rules.arrReq]
                                : []
                            "
                          >
                            <template v-slot:selection="{ item }">
                              <v-chip
                                class="mt-1 mr-0"
                                close
                                @click="editPrice(item)"
                                @click:close="removePrice(item)"
                              >
                                <div v-if="manualPriceInput">
                                  {{ item.currency }}
                                </div>
                                <div v-else>
                                  {{ item.price | money(1, item.currency) }}
                                </div>
                              </v-chip>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-checkbox
                          @click.stop=""
                          class="pa-1"
                          v-model="rounding"
                          :disabled="saleUnit && saleUnit.active === false"
                          outlined
                          @change="roundAll()"
                          label="Cjelobrojno zaokruživanje"
                        >
                        </v-checkbox>
                      </v-row>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col v-if="measureUnits.length > 0" class="pa-0">
                      <v-flex
                        v-for="(unit, idx) in measureUnits"
                        :key="idx"
                        class="pa-0"
                      >
                        <v-divider
                          v-if="unit.default_sale === false"
                        ></v-divider>
                        <v-row class="pa-5">
                          <v-col cols="4">
                            <v-text-field
                              outlined
                              disabled
                              v-model="unit.storage_unit"
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              label="Jedinica mjere"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <quantity-input
                              outlined
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              label="Količina"
                              disabled
                              v-model="unit.storage_amount"
                            ></quantity-input>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              outlined
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              :value="
                                measureUnits.storage_unit
                                  ? measureUnits.storage_unit
                                  : 0
                              "
                              disabled
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-checkbox
                              outlined
                              :disabled="
                                unit.default_sale === true ? true : false
                              "
                              width="20%"
                              v-if="
                                measureUnits.length > 0 &&
                                unit.default_sale === false
                              "
                              v-model="unit.active"
                              label="Aktivan"
                            >
                            </v-checkbox>
                          </v-col>
                          <v-row class="pl-3 pr-3">
                            <v-col cols="10">
                              <v-combobox
                                outlined
                                autocomplete="off"
                                v-if="measureUnits[idx].default_sale !== true"
                                v-model="measureUnits[idx].prices"
                                :items="[]"
                                dense
                                label="Cijene za artikl u valutama"
                                multiple
                                chips
                                clearable
                                @click="openAddPriceWhForm(measureUnits[idx])"
                                @keydown.enter="
                                  openAddPriceWhForm(measureUnits[idx])
                                "
                                @keydown="preventTextInput($event)"
                                :rules="
                                  unit.active === true ? [rules.arrReq] : []
                                "
                              >
                                <template v-slot:selection="{ item }">
                                  <v-chip
                                    class="mt-1 mr-0"
                                    close
                                    @click="
                                      editPriceWh(item, measureUnits[idx])
                                    "
                                    @click:close="
                                      removePriceWh(item, measureUnits[idx])
                                    "
                                  >
                                    <div v-if="manualPriceInput">
                                      {{ item.currency }}
                                    </div>
                                    <div v-else>
                                      {{ item.price | money(1, item.currency) }}
                                    </div>
                                  </v-chip>
                                </template>
                              </v-combobox>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-flex>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="showNorms">
                  <v-expansion-panel-header>
                    <v-row class="d-flex justify-center pa-0" v-if="showNorms">
                      <p class="text-h6 align-center font-weight-bold">
                        Izračun nabavne cijene prema normativu
                      </p>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center pa-0">
                      <v-col cols="12" v-if="showNorms">
                        <v-data-table
                          v-if="showNorms"
                          :headers="normHeaders"
                          :items="normRows"
                          :items-per-page="-1"
                          class="elevation-1"
                          hide-default-footer
                        >
                          <template v-slot:item="{ item }">
                            <tr>
                              <td>{{ item.name }}</td>
                              <td>{{ item.quantity }}</td>
                              <td>
                                {{ item.purchasePrice | money(1, currency) }}
                              </td>
                            </tr>
                          </template>
                          <template slot="body.append">
                            <tr>
                              <th>Ukupna nabavna cijena</th>
                              <th></th>
                              <th>
                                {{
                                  sumField("purchasePrice") | money(1, currency)
                                }}
                              </th>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
            id="lastElement"
          >
            Spremi artikl
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-category
      ref="addCategory"
      @processing="setCategoryProcessing"
    ></add-category>
    <add-price
      ref="addPrice"
      :taxes="taxes"
      :isManual="manualPriceInput"
      @add="onAddPrice"
      @addWh="onAddPriceWh"
    ></add-price>
  </v-dialog>
</template>
<script>

import state from '@/state'
import { df, auth, functions } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import AddCategory from '@/modules/company/components/AddCategory'
import AddPrice from '@/modules/point-of-sale/components/AddPrice'
import EventBus from '@/plugins/event-bus'
import QuantityInput from '../../../components/QuantityInput.vue'
import { collection, query, getDocs, where, limit } from 'firebase/firestore'
import triGram from '@/mixins/trigram'

export default {
  components: { AddCategory, AddPrice, QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert', 'showMsgBoxHtml', 'confirm', 'confirmClose'],
  mixins: [triGram],
  data: () => ({
    visible: false,
    submitting: false,
    loading: false,
    categoryProcessing: false,
    itemIsPreselected: false,
    selectedItem: undefined,
    selectedItemWh: undefined,
    searchModel: '',
    item: undefined,
    warehouseItems: [],
    selectedCategories: [],
    categories: [],
    taxes: [],
    selectedLocations: [],
    locations: [],
    selectedPrices: [],
    itemSelection: false,
    selected: false,
    warehouse: undefined,
    multiplier: undefined,
    rounding: false,
    measureUnits: [],
    measureUnit: undefined,
    saleUnit: undefined,
    currency: 'EUR',
    currencies: ['EUR', 'USD', 'GBP'],
    selectedOrgUnit: undefined,
    orgUnits: [],
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq(),
      money: rules.money()
    },
    normHeaders: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Potrebna količina',
      value: 'quantity'
    },
    {
      text: 'Nabavna cijena za količinu',
      value: 'purchasePrice'
    }],
    marginHeaders: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Dostupne količine',
      value: 'available_quantity'
    },

    {
      text: 'Potrebna količina',
      value: 'quantity'
    },
    {
      text: 'Nabavna cijena za količinu',
      value: 'purchase_price'
    },
    {
      text: 'Iznos marže',
      value: 'margin_amount'
    },
    {
      text: 'Postotak marže',
      value: 'margin_percent'
    }
    ],
    marginMultiItemHeaders: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Potrebna količina',
      value: 'quantity'
    },
    {
      text: 'Dostupne količine',
      value: 'available_quantity'
    },
    {
      text: 'Nabavna cijena za količinu',
      value: 'purchase_price'
    }
    ],
    normRows: [],
    margins: [],
    manualPriceInput: false,
    listeners: [],
    warehouseModule: false,
    showNorms: false,
    priceInEUR: 0,
    showMarginDialog: false,
    loader: false,
    sellingUnits: [],
    submitAction: 'ADD',
    pointOfSaleId: null
  }),

  computed: {
    margin () {
      if (this.priceInEUR && this.priceInEUR !== 0) {
        if (this.showNorms === true) {
          return (this.priceInEUR - this.sumField('purchasePrice')).toFixed(2)
        } else {
          if (this.selectedItem.warehouse_data && this.selectedItem.warehouse_data.purchase_price) {
            return (this.priceInEUR - this.selectedItem.warehouse_data.purchase_price / 100)
          }
          return 0
        }
      } else {
        return 0
      }
    },
    marginPercent () {
      if (this.margin && this.margin !== 0) {
        if (this.showNorms === true) {
          return ((this.margin / this.sumField('purchasePrice')) * 100).toFixed(2)
        } else {
          if (this.selectedItem.warehouse_data && this.selectedItem.warehouse_data.purchase_price) {
            return ((this.margin / (this.selectedItem.warehouse_data.purchase_price / 100)) * 100).toFixed(2)
          }
          return 0
        }
      } else {
        return 0
      }
    }
  },
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.getMultiplier()

    // this.getWarehouse()

    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }
    EventBus.$on('open-add-pointOfSale', () => this.open())
    this.setDefaultDateTime()

    document.onkeydown = this.handleKeyEvents
    const rule = (v) =>
      this.warehouseItems.includes(v) || 'Please select a warehouse item'

    this.rules = { ...this.rules, ...{ itemExists: rule } }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    async onPointOfSaleSelect () {
      if (this.selectedLocations.length > 0) {
        await this.getWarehouse(this.selectedLocations[0])
        this.getOrgUnitsForPointOfSale(this.selectedLocations[0])
        this.filterLocationWithSamePnp()
        this.pointOfSaleId = this.selectedLocations[0]
        this.checkIfItemExists()
      }
    },

    async onSelectItem () {
      this.setSelectedItemValues()
      this.checkIfItemExists()
    },

    async filterLocationWithSamePnp () {
      if (this.selectedItem && this.selectedItem.id) { this.setSelectedItemValues() }
      if (this.selectedLocations && this.selectedLocations.length >= 1) {
        let hasPnp = false
        let pnpAmt = 0
        this.selectedLocations.forEach((selected, key) => {
          this.locations.forEach(loc => {
            if (loc.id === selected) {
              if (loc.has_pnp === undefined) {
                loc.has_pnp = false
              }

              if (loc.id === selected && key === 0) {
                hasPnp = loc.has_pnp
                pnpAmt = loc.pnp
              }

              if (key > 0 && (hasPnp !== loc.has_pnp || pnpAmt !== loc.pnp)) {
                this.showMsgBox({
                  text: 'Odabrali ste lokacije koje nemaju isti porez na potrošnju. Artikl možete istovremeno dodati samo na lokacije sa istim porezom na potrošnju.',
                  actions: ['cancel'],
                  cancelBtnText: 'OK',
                  color: 'error'
                })

                this.selectedLocations = []
              }
            }
          })
        })
      }
    },

    showHelpPopup () {
      this.showMsgBoxHtml({ text: 'Ukoliko želite deaktivirati zadanu prodajnu jedinicu, morate postaviti drugu jedinicu kao zadanu. <br/> To možete učiniti klikom na: <br/> Meni Artikli -> Lista artikala -> Uređivanje artikla -> Odabir druge zadane prodajne jedinice, te klik na gumb Spremi.', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
    },

    async checkDefaultUnitSelector (active) {
      if (active === false && this.sellingUnits.length === 2) {
        this.loader = true
        var units = []
        var item = clone(this.selectedItem.item)
        var defaultSale = clone(item.warehouse_units.default_sale)

        if (item.warehouse_units.units) {
          Object.keys(item.warehouse_units.units).forEach(key => {
            var unit = item.warehouse_units.units[key]
            item.warehouse_units.units[key].storage_amount *= 1000
            if (unit.id === defaultSale) {
              item.warehouse_units.units[key].default_sale = false
            }

            if (unit.id !== defaultSale && unit.is_selling_item === true) {
              item.warehouse_units.units[key].default_sale = true
              item.warehouse_units.default_sale = unit.id
            }

            var uit = clone(item.warehouse_units.units[key])
            delete uit.prices

            units.push(uit)
          })
        }

        var newItem = {
          warehouse_units: item.warehouse_units
        }
        newItem.warehouse_units.units = units

        var that = this
        const payload = {
          action: {
            operation: 'update',
            entity: 'warehouse_module_item',
            resource_id: item.id,
            params: {
              ...newItem,
              ...{ company_id: state.getCurrentCompany().id }
            }
          }
        }

        try {
          var companyitemApi = functions.httpsCallable('companyitemapi')

          companyitemApi({
            action: {
              operation: payload.action.operation,
              entity: payload.action.entity,
              resource_id: payload.action.resource_id,
              params: payload.action.params
            }
          })
            .then((result) => {
              if (result.data.code === 200) {
                this.loader = false
              } else {
                this.showMsgBox({
                  text: result.data.message ?? 'Dogodila se pogreška.',
                  actions: ['cancel'],
                  cancelBtnText: 'OK',
                  color: 'error'
                })
                this.loader = false
              }
            })
        } catch (err) {
          that.showMsgBox({
            text: err && err !== '' ? err : 'An error has occurred',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
          // that.loader = false
        }
      }
    },

    closeDialog () {
      this.showMarginDialog = false
    },
    openDialog () {
      this.showMarginDialog = true
    },
    sumField (key) {
      // sum data in give key (property)
      return this.normRows.reduce((a, b) => a + (b[key] || 0), 0)
    },
    roundAll () {
      if (this.measureUnits.length <= 0) {

      } else {
        this.selectedPrices.forEach(price => {
          this.measureUnits.forEach(unit => {
            unit.prices.forEach(unitPrice => {
              if (price.currency === unitPrice.currency) {
                if (this.rounding) {
                  unitPrice.price = Math.ceil((price.price / this.saleUnit.storage_amount) * unit.storage_amount)
                } else {
                  unitPrice.price = (price.price / this.saleUnit.storage_amount) * unit.storage_amount
                }
              }
            })
          })
        })
      }
    },

    async checkIfItemExists () {
      this.submitAction = 'ADD'
      let pointOfSaleId
      if (!this.selectedItem || !this.selectedItem.id) return

      if (this.pointOfSaleId !== null) {
        pointOfSaleId = this.pointOfSaleId
        this.pointOfSaleId = null
      } else {
        if (state.getPointOfSale() && state.getPointOfSale().id) {
          pointOfSaleId = state.getPointOfSale().id
        }
      }
      df.doc(`location_pricelist/${pointOfSaleId}/items/${this.selectedItem.id}`)
        .get()
        .then(resp => {
          const item = resp.data()
          if (item && item.categories) {
            this.submitAction = 'EDIT'
            this.selectedItem.item = { ...item }
            this.selectedCategories = [...item.categories]
            this.selectedOrgUnit = item.organizational_unit_id
            this.selectedPrices = []

            this.currencies.forEach(currency => {
              if (item.prices[currency]) {
                const priceObj = item.prices[currency]
                priceObj.price = priceObj.price / 100
                priceObj.currency = currency
                this.selectedPrices.push(priceObj)
              }
            })
          } else {
            this.selectedCategories = []
            this.selectedPrices = []
          }
        }).catch((e) => {
          console.error('Dogodila se greška', e)
        })
    },

    async setSelectedItemValues () {
      this.sellingUnits = []
      await df.doc(`companies/${state.getCurrentCompany().id}/items/${this.selectedItem.item.id}`)
        .get()
        .then(resp => {
          if (resp !== undefined) {
            const item = resp.data()

            if (item && item.warehouse_units) {
              this.selectedItem.item.warehouse_units = item.warehouse_units
            }

            if (item && item.norm && item.norm.components) {
              this.selectedItem.item.norm = item.norm
              Object.keys(item.norm.components).forEach(compKey => {
                df.doc(`warehouses/${this.warehouse.id}/extended_items/${compKey}`)
                  .get()
                  .then(resp => {
                    const extItem = resp.data()
                    const tempMargin = []
                    if (extItem.warehouse_data.quantity_by_purchase_price && extItem.warehouse_data.quantity_by_purchase_price.length > 0) {
                      extItem.warehouse_data.quantity_by_purchase_price.forEach(qbpp => {
                        if (qbpp.available_quantity > 0) {
                          tempMargin.push({ name: extItem.item.name, quantity: this.selectedItem.item.norm.components[compKey].quantity / this.multiplier, available_quantity: qbpp.available_quantity / this.multiplier, purchase_price: (qbpp.purchase_price / (qbpp.total_quantity / this.multiplier)) * (this.selectedItem.item.norm.components[compKey].quantity / this.multiplier), marginAmount: 0, marginPercent: 0 })
                        }
                      })

                      this.margins.push({ name: extItem.item.name, quantity: this.selectedItem.item.norm.components[compKey].quantity / this.multiplier, qbpp: tempMargin })
                    }

                    let tempPp = 0
                    if (extItem.warehouse_data.quantity_by_purchase_price && extItem.warehouse_data.quantity_by_purchase_price.length > 0) {
                      let found = false
                      extItem.warehouse_data.quantity_by_purchase_price.forEach(qbpp => {
                        if (qbpp.available_quantity > 0 && !found) {
                          tempPp = qbpp.purchase_price / (qbpp.total_quantity / this.multiplier)
                          found = true
                        }
                      })
                    }

                    let pp = 0
                    if (tempPp === 0) {
                      pp = extItem.warehouse_data.purchase_price / (extItem.warehouse_data.quantity_on_hand / this.multiplier)
                    } else {
                      pp = tempPp
                    }
                    this.selectedItem.item.norm.components[compKey].purchasePrice = pp

                    this.selectedItem.item.norm.components[compKey].hasCustom = false
                    this.selectedItem.item.norm.components[compKey].customQuantity = 0

                    if (this.selectedItem.item.norm.components[compKey].custom_unit === true) {
                      const customUnitId = this.selectedItem.item.norm.components[compKey].custom_unit_id
                      df.doc(`companies/${state.getCurrentCompany().id}/items/${compKey}`)
                        .get()
                        .then(lagerResp => {
                          const lagerItem = lagerResp.data()
                          if (lagerItem.warehouse_units !== undefined && lagerItem.warehouse_units.units !== undefined && lagerItem.warehouse_units.units[customUnitId]) {
                            this.selectedItem.item.norm.components[compKey].hasCustom = true
                            this.selectedItem.item.norm.components[compKey].customQuantity = lagerItem.warehouse_units.units[customUnitId].storage_amount / this.multiplier
                          }
                        })
                    }
                  })
              })
              this.showNorms = true
            }

            setTimeout(() => {
              if (this.selectedItem && this.selectedItem.item && this.selectedItem.item.norm && this.selectedItem.item.norm.components) {
                Object.keys(this.selectedItem.item.norm.components).forEach(compKey => {
                  let pp = this.selectedItem.item.norm.components[compKey].purchasePrice

                  if (this.selectedItem.item.norm.components[compKey].hasCustom === true) {
                    pp = pp * this.selectedItem.item.norm.components[compKey].customQuantity
                  }

                  this.normRows.push({ name: this.selectedItem.item.norm.components[compKey].item_name, quantity: this.selectedItem.item.norm.components[compKey].quantity / this.multiplier, purchasePrice: pp * (this.selectedItem.item.norm.components[compKey].quantity / this.multiplier) })
                })
              }
            }, 1000)

            if (item && !item.norm) {
              df.doc(`warehouses/${this.warehouse.id}/extended_items/${item.id}`)
                .get()
                .then(resp => {
                  const extItem = resp.data()
                  const tempMargin = []
                  if (extItem.warehouse_data.quantity_by_purchase_price && extItem.warehouse_data.quantity_by_purchase_price.length > 0) {
                    extItem.warehouse_data.quantity_by_purchase_price.forEach(qbpp => {
                      if (qbpp.available_quantity > 0) {
                        tempMargin.push({ name: extItem.item.name, quantity: 1, available_quantity: qbpp.available_quantity / this.multiplier, purchase_price: qbpp.purchase_price / 100, marginAmount: 0, marginPercent: 0 })
                      }
                    })
                    this.margins.push({ name: extItem.item.name, qbpp: tempMargin })
                  }
                })
            }
          }

          if (this.selectedItem.item.warehouse_units !== undefined &&
            this.selectedItem.item.warehouse_units.units !== undefined) {
            Object.keys(this.selectedItem.item.warehouse_units.units).forEach(unit => {
              if (this.selectedItem.item.warehouse_units.units[unit].is_selling_item === true) {
                this.sellingUnits.push(this.selectedItem.item.warehouse_units.units[unit])
              }

              if (this.selectedItem.item.warehouse_units.units[unit].default_sale === true) {
                this.selectedItem.item.warehouse_units.units[unit].prices = []
                this.selectedItem.item.warehouse_units.units[unit].active = true
                this.selectedItem.item.warehouse_units.units[unit].storage_amount /= this.multiplier
                this.measureUnits.push(this.selectedItem.item.warehouse_units.units[unit])
                this.saleUnit = this.selectedItem.item.warehouse_units.units[unit]
              }
            })

            if (this.saleUnit === undefined) {
              this.selectedItem = undefined
              this.measureUnits = []
              this.measureUnit = undefined
              this.normRows = []
              this.priceInEUR = 0
              this.selectedCategories = []
              this.selectedPrices = []
              this.showMsgBox({
                text: 'Na cjenik nije moguće dodati artikl koji nema zadanu prodajnu jedinicu!',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
            }

            Object.keys(this.selectedItem.item.warehouse_units.units).forEach(unit => {
              if (this.selectedItem.item.warehouse_units.units[unit].is_selling_item === true && this.selectedItem.item.warehouse_units.units[unit].default_sale === false) {
                this.selectedItem.item.warehouse_units.units[unit].storage_amount /= this.multiplier
                this.selectedItem.item.warehouse_units.units[unit].prices = []
                this.selectedItem.item.warehouse_units.units[unit].active = true
                this.measureUnits.push(this.selectedItem.item.warehouse_units.units[unit])

                if (this.selectedItem.item.warehouse_units.units[unit].base_unit === true) {
                  this.measureUnit = this.selectedItem.item.warehouse_units.units[unit]
                }
              }
            })
          }
        })
      // if (this.selectedItem) {
      //   this.checkIfItemExists('setSelectedItemValues')
      // }
    },
    async getWarehouse (posId = null) {
      let pointOfSaleId = state.getPointOfSale().id
      if (!pointOfSaleId && posId) {
        pointOfSaleId = posId
      }
      const that = this
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('selling_warehouse', '==', true)
        .where('status', '==', 'OK')
        .where('location_id', '==', pointOfSaleId)

      await query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            that.warehouse = doc.data()
          })
        })
    },
    preventTextInput (e) {
      if (e.keyCode !== 9 && e.keyCode !== 27) {
        this.openAddPriceForm()
        e.preventDefault()
      }
    },
    handleKeyEvents (e) {
      e = e || window.event
      if (e.keyCode === 9) {
        if (document.activeElement.id.includes('lastElement')) {
          e.preventDefault()
          document.getElementById('itemSelection').focus()
        }
      }
    },
    // setFocusOnNext () {
    //   document.getElementById('locations').focus()
    // },
    async open (preselectedItem = false, deliveryNoteLocation = '') {
      this.visible = true
      this.submitting = false
      this.setDefaults()
      this.listeners.push(
        df
          .doc(`company_categories/${state.getCurrentCompany().id}`)
          .onSnapshot((doc) => {
            if (
              doc &&
              doc.data() &&
              doc.data().categories &&
              Object.keys(doc.data().categories).length > 0
            ) {
              const categories = Object.keys(doc.data().categories).map(
                (key) => {
                  return doc.data().categories[key]
                }
              )
              this.categories = clone(categories.sort((a, b) => a.name.localeCompare(b.name)))
              this.categoryProcessing = false
            }
          })
      )

      df.collection(`location_units/${state.getPointOfSale().id}/units`)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            this.orgUnits = []
            const orgUnits = []
            documentSnapshots.docs.forEach((orgUnit) => {
              orgUnits.push(orgUnit.data())
            })
            this.orgUnits = clone(orgUnits.sort((a, b) => a.name.localeCompare(b.name)))
          }
        })

      this.listeners.push(
        df.doc('project_constants/taxes').onSnapshot((doc) => {
          if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
            // this.$refs.addCashRegisterForm.reset()
            this.taxes = doc.data().pdv.map((tax) => {
              return {
                ...tax,
                ...{
                  disabled:
                    !state.getCurrentCompany().is_taxed && tax.rate !== 0
                }
              }
            })
          }
        })
      )

      this.listeners.push(
        df
          .doc(
            `user_locations/${auth.currentUser.uid}.${
              state.getCurrentCompany().id
            }`
          )
          .onSnapshot((doc) => {
            if (
              doc.data() &&
              doc.data().locations &&
              Object.keys(doc.data().locations).length > 0
            ) {
              this.locations = Object.keys(doc.data().locations)
                .map((key) => {
                  return doc.data().locations[key]
                })
                .filter((location) => location.status === 'OK')

              this.locations.forEach((loc, key) => {
                this.locations[key].nameDropDown = loc.name
                if (loc.has_pnp === true && loc.pnp > 0) {
                  this.locations[key].nameDropDown += ' - PNP - ' + loc.pnp / 100 + '%'
                }
              })

              // this.selectedLocations = this.locations.map((location) => {
              //   if (location.has_pnp === false) {
              //     return location.id
              //   }
              // })
            }
          })
      )

      if (preselectedItem) {
        this.itemIsPreselected = true
        this.warehouseItems = [preselectedItem]
        this.selectedItem = {}
        this.selectedItem.id = preselectedItem.id
        this.selectedItem.name = preselectedItem.name
        this.selectedItem.item = { ...preselectedItem }

        if (state.getPointOfSale().id && deliveryNoteLocation === '') {
          if (!this.selectedLocations.includes(state.getPointOfSale().id)) {
            this.selectedLocations.push(state.getPointOfSale().id)
          }
          await this.getWarehouse(state.getPointOfSale().id)
          await this.filterLocationWithSamePnp()
        }

        if (deliveryNoteLocation !== '') {
          if (!this.selectedLocations.includes(deliveryNoteLocation.id)) {
            this.selectedLocations.push(deliveryNoteLocation.id)
          }
          await this.getWarehouse(deliveryNoteLocation.id)
          await this.filterLocationWithSamePnp()
        }

        // await this.setSelectedItemValues()

        // const whUnit = {
        //   custom_item_unit: this.selectedItem.warehouse_unit.storage_unit,
        //   custom_item_amount: this.selectedItem.warehouse_unit.storage_amount,
        //   selling_item: this.selectedItem.warehouse_unit.selling_item,
        //   default_sale: this.selectedItem.warehouse_unit.default_sale,
        //   default_store: this.selectedItem.warehouse_unit.default_store
        // }

        // this.selectedItemWh.warehouseUnits = []

        // this.selectedItem.warehouse_units.forEach(unit => {
        //   const whUnit = {
        //     custom_item_unit: unit.storage_unit,
        //     custom_item_amount: unit.storage_amount,
        //     selling_item: unit.selling_item,
        //     default_sale: unit.default_sale,
        //     default_store: unit.default_store
        //   }

        //   this.selectedItemWh.warehouseUnits.push(whUnit)
        // })
        // this.selectedItemWh.warehouseUnits.push(whUnit)
      } else {
        this.itemIsPreselected = false
        if (state.getPointOfSale().id) {
          this.selectedLocations.push(state.getPointOfSale().id)
          await this.getWarehouse(state.getPointOfSale().id)
          await this.filterLocationWithSamePnp()
        }
      }

      this.$forceUpdate()
      if (this.selectedItem) {
        this.checkIfItemExists()
      }
    },
    close () {
      this.detachListeners()
      this.selectedItem = undefined
      this.measureUnits = []
      this.measureUnit = undefined
      this.normRows = []
      this.orgUnits = []
      this.priceInEUR = 0
      this.selectedCategories = []
      this.selectedPrices = []
      this.selectedLocations = []
      this.$nextTick(() => {
        // this.selectedPrices = []
        // this.$refs.priceListForm.reset()
        this.visible = false
      })
    },
    openAddCategoryForm () {
      this.$refs.addCategory.open()
    },
    openAddPriceForm () {
      this.locations.forEach(loc => {
        if (loc.id === this.selectedLocations[0]) {
          this.$refs.addPrice.open(null, loc, null, this.selectedItem)
        }
      })
    },
    openAddPriceWhForm (unit) {
      this.locations.forEach(loc => {
        if (loc.id === this.selectedLocations[0]) {
          this.$refs.addPrice.open(null, loc, unit.id, this.selectedItem)
        }
      })
    },
    onAddPriceWh (priceItem, unitID) {
      this.measureUnits.forEach((unit, key) => {
        if (unit.id === unitID) {
          if (
            this.measureUnits[key].prices.some((item) => item.currency === priceItem.currency)
          ) {
            const sameItem = this.measureUnits[key].prices.find(
              (it) => it.currency === priceItem.currency
            )
            this.measureUnits[key].prices = this.measureUnits[key].prices.map((item) => {
              item.price = item === sameItem ? priceItem.price : item.price
              return item
            })
          } else {
            this.measureUnits[key].prices.push(priceItem)
          }
        }
      })
    },
    onAddPrice (priceItem) {
      if (
        this.selectedPrices.some((item) => item.currency === priceItem.currency)
      ) {
        const sameItem = this.selectedPrices.find(
          (it) => it.currency === priceItem.currency
        )
        this.selectedPrices = this.selectedPrices.map((item) => {
          item.price = item === sameItem ? priceItem.price : item.price
          return item
        })
      } else {
        this.selectedPrices.push(priceItem)
      }

      this.selectedPrices.forEach(price => {
        if (price.currency === this.currency) {
          this.priceInEUR = price.price
        }
      })

      if (this.measureUnits.length > 1) {
        this.confirm({
          title: 'Promjena cijene',
          message: 'Promijenili ste cijenu zadanoj prodajnoj mjernoj jedinici. Želite li promijeniti i ostale cijene u odnosu?',
          options: {
            toolbar: true,
            width: 410,
            confirmText: 'DA',
            cancelText: 'NE'
          }
        }).then((resp) => {
          if (resp) {
            this.confirmClose()

            if (this.measureUnits.length > 0) {
              this.measureUnits.forEach((unit, key) => {
                if (unit.default_sale === false) {
                  if (
                    this.measureUnits[key].prices.some((item) => item.currency === priceItem.currency)
                  ) {
                    const onePrice = priceItem.price / this.saleUnit.storage_amount

                    const sameItem = this.measureUnits[key].prices.find(
                      (it) => it.currency === priceItem.currency
                    )
                    this.measureUnits[key].prices = this.measureUnits[key].prices.map((item) => {
                      if (item === sameItem) {
                        if (this.rounding) {
                          Math.ceil(this.measureUnits[key].storage_amount * onePrice)
                        } else {
                          item.price = this.measureUnits[key].storage_amount * onePrice
                        }
                      }
                      return item
                    })
                  } else {
                    const itemPrice = clone(priceItem)
                    const clonePrice = clone(priceItem.price)
                    let onePrice = clonePrice

                    if (this.saleUnit !== undefined && this.saleUnit.storage_amount !== undefined) {
                      onePrice /= this.saleUnit.storage_amount
                    }

                    if (this.rounding) {
                      itemPrice.price = Math.ceil(this.measureUnits[key].storage_amount * onePrice)
                    } else {
                      itemPrice.price = this.measureUnits[key].storage_amount * onePrice
                    }

                    this.measureUnits[key].prices.push(itemPrice)
                  }
                }
              })
            }
          }
        })
      }
    },
    removePriceWh (priceItem, un) {
      this.measureUnits.forEach((unit, key) => {
        if (un.id === unit.id) {
          this.measureUnits[key].prices = this.measureUnits[key].prices.filter(
            (item) => item !== priceItem
          )
        }
      })
    },
    editPriceWh (priceItem, unit) {
      this.$refs.addPrice.open(priceItem, null, unit.id)
    },
    removePrice (priceItem) {
      this.selectedPrices = this.selectedPrices.filter(
        (item) => item !== priceItem
      )

      this.measureUnits.forEach((unit, key) => {
        this.measureUnits[key].prices = this.measureUnits[key].prices.filter(
          (item) => item.currency !== priceItem.currency
        )
      })
    },
    editPrice (priceItem) {
      this.$refs.addPrice.open(priceItem)
    },
    setCategoryProcessing (processing) {
      if (processing) {
        this.categoryProcessing = true
      } else {
        this.categoryProcessing = false
      }
    },
    setDefaults () {
      this.dateFrom = new Date().toISOString().substr(0, 10)
      this.timeFrom = new Date().toLocaleTimeString('it-IT').substr(0, 5)
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    async submit () {
      if (!this.$refs.priceListForm.validate()) {
        return
      }

      if (this.saleUnit.active === false) {
        this.checkDefaultUnitSelector(this.saleUnit.active)
        setTimeout(() => {
          this.callSubmit()
        }, 4000)
      } else {
        this.callSubmit()
      }
    },
    callSubmit () {
      const categories = this.selectedCategories.map((category) => {
        return {
          id: category.id,
          name: category.name,
          picture: category.picture
        }
      })
      try {
        this.submitting = true
        let prices = {}

        let manualPrice = false
        if (this.manualPriceInput !== undefined) {
          manualPrice = this.manualPriceInput
        }

        prices = this.selectedPrices.reduce((prices, it) => {
          return Object.assign(prices, {
            [it.currency]: {
              active: this.saleUnit.active,
              price: Math.round(it.price * 100),
              taxes: it.taxes.map((tax) => {
                return { name: tax.name, rate: tax.rate }
              })
            }
          })
        }, {})

        const unitPrices = []

        if (this.saleUnit !== undefined && this.saleUnit.id !== undefined) {
          unitPrices.push({
            unit_id: this.saleUnit.id,
            prices: prices
          })
        }

        this.measureUnits.forEach((unit, key) => {
          if (this.saleUnit !== undefined && this.saleUnit.id !== undefined) {
            if (unit.id !== this.saleUnit.id) {
              prices = this.measureUnits[key].prices.reduce((prices, it) => {
                return Object.assign(prices, {
                  [it.currency]: {
                    active: this.measureUnits[key].active,
                    price: Math.round(it.price * 100),
                    taxes: it.taxes.map((tax) => {
                      return { name: tax.name, rate: tax.rate }
                    })
                  }
                })
              }, {})

              unitPrices.push({
                unit_id: this.measureUnits[key].id,
                prices: prices
              })
            }
          } else {
            prices = this.measureUnits[key].prices.reduce((prices, it) => {
              return Object.assign(prices, {
                [it.currency]: {
                  active: this.measureUnits[key].active,
                  price: Math.round(it.price * 100),
                  taxes: it.taxes.map((tax) => {
                    return { name: tax.name, rate: tax.rate }
                  })
                }
              })
            }, {})

            unitPrices.push({
              unit_id: this.measureUnits[key].id,
              prices: prices
            })
          }
        })

        const payload = {
          action: {
            operation: 'set',
            entity: 'warehouse_module_pricelist_item',
            params: {
              warehouses: this.selectedLocations,
              company_id: state.getCurrentCompany().id,
              item_id: this.selectedItem.id,
              pricing_info: {
                active: true,
                categories: categories,
                manual_price_input: manualPrice,
                unit_prices: unitPrices
              },
              organizational_unit_id: this.selectedOrgUnit && this.selectedOrgUnit !== '' ? this.selectedOrgUnit : ''
            }
          }
        }

        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.$emit('refresh')
              this.submitting = false
              this.loader = false
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.close()
              this.loader = false
              this.submitting = false
            }
            this.$nextTick(() => {
              this.loader = false
              this.$refs.priceListForm.reset()
              this.selectedPrices = []
              this.visible = false
              this.close()
            })
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.close()
        this.loader = false
        this.submitting = false
      }
    },
    async getWarehouseItems () {
      this.warehouseItems = []
      this.loading = true

      const where = this.searchModel ? this.searchModel.toLowerCase() : ''
      df.collection(`warehouses/${this.warehouse.id}/extended_items`)
        .where('item.name', '>=', where)
        .where('item.name', '<=', where + '\uf8ff')
        .where('item.status', '==', 'OK')
        .orderBy('item.name')
        .limit(30)
        .get()
        .then((documentSnapshots) => {
          this.loading = false
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              this.warehouseItems.push(item.data())
            })
          }
        })
    },
    setDefaultDateTime () {
      this.dateFrom = new Date().toISOString().substr(0, 10)
      this.timeFrom = new Date().toLocaleTimeString('it-IT').substr(0, 5)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    async getOrgUnitsForPointOfSale (posId) {
      if (!posId) return
      df.collection(`location_units/${posId}/units`)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            this.orgUnits = []
            const orgUnits = []
            documentSnapshots.docs.forEach((orgUnit) => {
              orgUnits.push(orgUnit.data())
            })
            this.orgUnits = clone(orgUnits.sort((a, b) => a.name.localeCompare(b.name)))
          }
        })
    },

    async getItems () {
      this.warehouseItems = []
      this.loading = true

      var param = 'item.meta'
      var param2 = 'item.status'
      var path = `warehouses/${this.warehouse.id}/extended_items`

      if (!this.searchModel || this.searchModel === '') {
        return
      }

      var triGramObject = this.triGram(this.searchModel.toLowerCase())

      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`${param}.${name}`, '==', true))
        )
      }

      searchConstraints.push(where(`${param2}`, '==', 'OK'))

      searchConstraints.push(limit(10))

      const constraints = [
        collection(df, path),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)
      const querySnapshot = await getDocs(q)

      const results = []
      querySnapshot.forEach((doc) => {
        var it = doc.data()
        results.push(it)
      })

      this.warehouseItems = results.sort((a, b) => a.item.name.localeCompare(b.item.name))
      this.loading = false
    },

    save () {},
    cancel () {}
  }
}
</script>
<style>
.items {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
