<template>
  <v-dialog
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="showInventoryItems"
  >
    <v-card>
      <v-card-title>
        <v-row class="pa-3 mb-2">
          <h2 class="text--secondary">Pregled artikala u inventuri</h2>
          <v-spacer></v-spacer>
          <v-icon large @click="showInventoryItems = false">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          justify="center"
          :items="previewItems"
          dense
          :headers="previewHeaders"
          :items-per-page="-1"
          class="elevation-2 pb-5 mb-5"
          :search="searchItem"
          hide-default-footer
          :custom-filter="filterOnlyCapsText"
        >
          <template v-slot:top>
            <v-text-field
              style="width: 30%"
              v-model="searchItem"
              label="Pretraži artikl"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td colspan="1">
                {{ item.rbr }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.naziv }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.trenutna_kolicina / multiplier }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.pocetna_kolicina / multiplier }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.inventurna_kolicina / multiplier }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.inventurna_kolicina / multiplier }}
              </td>
              <td colspan="1" style="text-align: center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @keypress.enter.stop=""
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="(it, index) in item.options"
                      :key="index"
                      style="cursor: pointer"
                      @click="`${it.action(item)}`"
                      @keypress.enter.prevent="`${it.action(item)}`"
                    >
                      <v-list-item-title>{{ it.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- <v-btn @click="finishInventory">Završi inventuru</v-btn> -->
      </v-card-text>
    </v-card>
    <inventory-item-count-list
      ref="inventoryItemCountList"
    ></inventory-item-count-list>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { clone } from '@/plugins/utils'
import InventoryItemCountList from './InventoryItemCountList.vue'
// import { v4 as uuidv4 } from 'uuid'

export default {
  components: { InventoryItemCountList },
  name: 'WarehouseDocuments',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  data: () => ({
    margin: '56px',
    searchModel: '',
    listeners: [],
    multiplier: 0,
    selectedWarehouse: '',
    activeInventory: '',
    company: undefined,
    warehouseItems: [],
    previewHeaders: [
      {
        text: 'Rb.',
        align: 'start',
        sortable: false,
        filterable: false,
        value: 'rbr'
      },
      { text: 'Naziv', value: 'naziv', sortable: false, align: 'center', filterable: true },
      { text: 'Trenutna količina', value: 'trenutna_kolicina', align: 'center', sortable: false },
      { text: 'Početna količina', value: 'pocetna_kolicina', align: 'center', sortable: false },
      { text: 'Inventurna količina', value: 'inventurna_kolicina', align: 'center', sortable: false },
      { text: '', value: 'controls', sortable: false }
    ],
    showInventoryItems: false,
    previewItems: [],
    searchItem: undefined
  }),
  created () {
    this.margin = state.getMargin()
  },
  async mounted () {
    this.company = clone(state.getCurrentCompany())

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.getMultiplier()
  },
  methods: {
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    async open (selectedWarehouse, activeInventory) {
      this.previewItems = []
      this.selectedWarehouse = selectedWarehouse
      this.activeInventory = activeInventory
      await df.collection(`warehouse_inventories/${selectedWarehouse}/inventories/${activeInventory}/items`).get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach(async (item) => {
              if (item.data()) {
                var inItem = item.data()
                var whItem = await this.getItemFromWarehouse(selectedWarehouse, inItem)
                inItem.trenutna_kolicina = whItem.warehouse_data.quantity
                inItem.item = whItem

                inItem.options = [{ title: 'Pregled povijesti brojanja', action: this.showItemHistory }
                ]

                this.previewItems.push(inItem)
              }
            })

            this.previewItems.sort((a, b) => (a.rbr < b.rbr) ? 1 : 0)
            this.showInventoryItems = true
          }
        })
    },
    async getItemFromWarehouse (warehouse, item) {
      var existingItem
      const response = await df.doc(`warehouses/${warehouse}/extended_items/${item.id}`).get()
      if (response.data()) {
        existingItem = response.data()
      }

      return existingItem
    },
    filterOnlyCapsText (value, searchItem, item) {
      return value != null &&
      searchItem.toLowerCase() != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(searchItem.toLowerCase()) !== -1
    },
    showItemHistory (item) {
      this.$refs.inventoryItemCountList.open(item)
    }
  }
}
</script>
