<template>
  <div style="width: 100%" class="fill-height">
    <v-row>
      <v-col class="stepper-wrapper" :style="`margin-left: ${margin}`">
        <v-row style="margin-top: 20px" class="justify-center">
          <div style="min-width: 20%; max-width: 20% !important">
            <v-select
              label="Odaberite prodajno mjesto"
              v-model="selectedLocation"
              outlined
              dense
              hide-details
              :items="locations"
              item-text="name"
              item-value="value"
              return-object
              @change="filterWarehouses()"
            >
            </v-select>
          </div>
          <div style="min-width: 20%; max-width: 20%; padding-left: 3px">
            <v-select
              label="Odaberite skladište"
              outlined
              dense
              hide-details
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </div>

          <div
            v-if="selectedLocation && selectedWarehouse.value !== ''"
            style="min-width: 10%; padding-left: 3px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="white--text okButton"
                    :disabled="activeInventory ? true : false"
                    height="40"
                    v-on:click="newInventura"
                    >Započni inventuru</v-btn
                  >
                </div>
              </template>
              <span>{{
                activeInventory
                  ? "Završi aktivnu inventuru"
                  : "Započni inventuru"
              }}</span>
            </v-tooltip>
          </div>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="11" lg="11" :class="['px-0']">
            <v-card
              class="pb-5"
              v-for="(inventory, i) in inventories"
              :key="i"
              hover
              style="height: 48px !important"
            >
              <v-row
                :class="[
                  'ma-0',
                  inventory.status === 'OK'
                    ? 'status-ok-exp'
                    : `${
                        inventory.status === 'PENDING'
                          ? 'status-processing-exp'
                          : `${
                              inventory.status === 'PARTIAL'
                                ? 'status-processing-partial-exp'
                                : 'status-failed-exp'
                            }`
                      }`,
                ]"
                style="height: 48px !important"
              >
                <div style="text-align: right; padding: 0px 0px 0px 3px">
                  <div class="caption grey--text text-center">Početak</div>
                  <div class="text-center">
                    {{ inventory.created | fullDateTime }}
                  </div>
                </div>
                <div style="text-align: center; width: 20%">
                  <div class="caption grey--text">Pokrenuo</div>
                  <div class="text-capitalize">
                    {{ inventory.started_user }}
                  </div>
                </div>
                <div style="text-align: center; width: 20%">
                  <div class="caption grey--text">Status</div>
                  <div class="text-capitalize">
                    {{ showStatus(inventory.status) }}
                  </div>
                </div>
                <div style="text-align: center; width: 20%">
                  <div class="caption grey--text">Odobrio</div>
                  <div
                    v-if="
                      inventory.finished_user && inventory.finished_user !== ''
                    "
                    class="text-capitalize"
                  >
                    {{ inventory.finished_user }}
                  </div>
                </div>
                <div style="text-align: center; width: 20%">
                  <div class="caption grey--text">Kraj</div>
                  <div
                    v-if="inventory.finished && inventory.finished > 0"
                    class="text-capitalize"
                  >
                    {{ inventory.finished | fullDateTime }}
                  </div>
                </div>
                <div style="text-align: right" class="flex-grow-1">
                  <div class="d-flex justify-space-between">
                    <div>
                      <div class="caption grey--text text-left"></div>
                      <div class="text-left"></div>
                    </div>
                    <div
                      class="d-flex justify-end align-center pl-2"
                      style="z-index: 1"
                    >
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @keypress.enter.stop=""
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-for="(item, index) in inventory.options"
                            :key="index"
                            style="cursor: pointer"
                            @click="`${item.action(inventory)}`"
                            @keypress.enter.prevent="
                              `${item.action(inventory)}`
                            "
                          >
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <inventory-items-list ref="inventoryItemsList"></inventory-items-list>
    <change-inventory-status
      ref="changeInventoryStatus"
      @finishedChange="onFinishedChange"
    ></change-inventory-status>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'
import InventoryItemsList from './InventoryItemsList.vue'
import ChangeInventoryStatus from './ChangeInventoryStatus.vue'

export default {
  components: { InventoryItemsList, ChangeInventoryStatus },
  name: 'Inventories',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  data: () => ({
    margin: '56px',
    loading: false,
    listeners: [],
    multiplier: 0,
    warehouses: [],
    allWarehouses: [],
    selectedWarehouse: '',
    selectedLocation: undefined,
    locations: [],
    company: undefined,
    inventories: [],
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq()
    },
    selectedItems: [],
    warehouseItems: [],
    targetModifier: 'company',
    activeInventory: undefined
  }),
  created () {
    this.margin = state.getMargin()
  },
  async mounted () {
    this.activeInventory = undefined
    this.setTargetModifier()
    this.company = clone(state.getCurrentCompany())

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    await this.getAllWarehouses()
    await this.getLocations()

    // Provjera da li si dosao preko započni inventuru
    if (this.$route.params.location && this.$route.params.warehouse) {
      this.selectedLocation = this.$route.params.location
      this.selectedWarehouse = { name: this.$route.params.warehouse.name, value: this.$route.params.warehouse.id, warehouse: this.$route.params.warehouse }
      this.getAllInventories()
    }

    this.getMultiplier()
  },
  methods: {
    setTargetModifier () {
      var sideNav = this.$route.meta.sideNav
      switch (sideNav) {
        case 'Companies':
          break
        case 'Company':
          this.targetModifier = 'company'
          break
        case 'PointOfSale':
          this.targetModifier = 'pointOfSale'
          break
        case 'CashRegister':
          this.targetModifier = 'pointOfSale'
          break
      }
    },
    showStatus (status) {
      switch (status) {
        case 'PENDING':
          return 'U izradi'
        case 'OK':
          return 'Izvršen'
        case 'DENIED':
          return 'Odbijen'
        case 'PARTIAL':
          return 'Djelomično izvršen'
        default:
          return 'Nepoznat'
      }
    },
    filterWarehouses () {
      this.activeInventory = undefined
      this.inventories = []
      this.warehouses = this.allWarehouses
      this.warehouses = this.warehouses.filter((wh, k) => {
        if (wh.warehouse.location_id === this.selectedLocation.id) {
          if (wh.warehouse.selling_warehouse === true) {
            this.selectedWarehouse = this.warehouses[k]
            this.getAllInventories()
          }
          return wh
        }
      })
    },
    getLocations () {
      this.showLocationSelector = false
      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
              if (this.checkRadio === 'pointOfSale' && state.getPointOfSale().id !== undefined && loc.id === state.getPointOfSale().id) {
                this.selectedLocation = loc
                this.showLocationSelector = true
                this.filterWarehouses()
              }
            })

            this.$forceUpdate()
          }
        })
    },
    async getAllWarehouses () {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('status', '==', 'OK')
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          this.warehouses.push({ name: warehouse.name, value: warehouse.id, warehouse: warehouse })
        })
      })
      this.allWarehouses = this.warehouses
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    async getAllInventories () {
      this.activeInventory = undefined
      if (this.selectedWarehouse && this.selectedWarehouse.value !== '') {
        await df.collection(`warehouse_inventories/${this.selectedWarehouse.value}/inventories`).get()
          .then((documentSnapshots) => {
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              documentSnapshots.docs.forEach(async (item) => {
                var inventory = item.data()
                inventory.options = [{ title: 'Pregled dokumenta', action: this.showItems }
                ]
                if (inventory.status === 'PENDING') {
                  this.activeInventory = item.data().id
                  inventory.options.push({ title: 'Nastavi inventuru', action: this.continueInventura })
                  inventory.options.push({ title: 'Završi inventuru', action: this.changeStatus })
                }
                if (inventory.status === 'PARTIAL') {
                  inventory.options.push({ title: 'Završi inventuru', action: this.changeStatus })
                }

                this.inventories.push(inventory)
              })

              this.inventories.sort((a, b) => (b.created - a.created))

              this.started = true
            } else {
              this.started = false
            }
          })
      }
    },
    showItems (inventory) {
      this.$refs.inventoryItemsList.open(this.selectedWarehouse.value, inventory.id)
    },
    continueInventura (inventory) {
      this.$router.push({
        name: this.targetModifier + '.warehouseModule.activeInventory',
        params: {
          inventory: inventory,
          location: this.selectedLocation,
          warehouse: this.selectedWarehouse.warehouse
        }
      })
    },
    newInventura () {
      this.$router.push({
        name: this.targetModifier + '.warehouseModule.activeInventory',
        params: {
          location: this.selectedLocation,
          warehouse: this.selectedWarehouse.warehouse
        }
      })
    },
    changeStatus (inventory) {
      this.$refs.changeInventoryStatus.open(inventory, this.selectedWarehouse.value)
    },
    onFinishedChange () {
      this.inventories = []
      this.getAllInventories()
    }
  }
}
</script>
