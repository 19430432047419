<template>
  <v-dialog
    v-model="visible"
    @keydown.esc.stop="close"
    scrollable
    max-width="350"
    min-width="350"
  >
    <v-form ref="addPriceForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">
              <div v-if="!isManual">Dodaj cijenu</div>
              <div v-else>Dodaj valutu</div>
            </h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row v-show="!isManual">
              <v-col cols="6">
                <label for="priceInput">Željena cijena</label>
                <div v-if="priceInputEnabled">
                  <CurrencyInput
                    :options="currencyOptions"
                    v-model="price"
                    id="priceInput"
                    ref="priceInput"
                    v-on:input="setBase"
                    currency="EUR"
                  >
                  </CurrencyInput>
                </div>
                <div v-else @click="setInputField">
                  <CurrencyInput
                    :options="currencyOptions"
                    v-model="price"
                    id="priceInputDisabled"
                    currency="EUR"
                  >
                  </CurrencyInput>
                </div>
              </v-col>
              <v-col cols="6">
                <label for="baseInput">Osnovica</label>
                <div v-if="!priceInputEnabled">
                  <CurrencyInput
                    id="baseInput"
                    ref="baseInput"
                    :options="currencyOptions"
                    v-model="base"
                    v-on:input="calculatePrice"
                    currency="EUR"
                  >
                  </CurrencyInput>
                </div>
                <div v-else @click="setInputField">
                  <CurrencyInput
                    id="baseInputDisabled"
                    :options="currencyOptions"
                    v-model="base"
                    currency="EUR"
                  >
                  </CurrencyInput>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Valuta"
                  v-model="currency"
                  :items="currencies"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="PDV"
                  :items="priceTaxes"
                  return-object
                  v-model="pdv"
                  item-text="name"
                  item-value="rate"
                  :rules="[rules.req]"
                  @change="calculatePrice"
                  :messages="!isTaxed ? 'Firma nije u sustavu PDV-a' : ''"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="location && location.has_pnp === true">
              <v-col class="pt-0 pb-0">
                <v-checkbox
                  outlined
                  dense
                  class="mt-0 mb-0"
                  width="20%"
                  v-model="hasPnp"
                  @change="calculatePrice"
                  label="Porez na potrošnju"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row
              v-if="location && location.has_pnp === true"
              class="mt-0 mb-0"
            >
              <v-col>
                <v-select
                  disabled
                  item-text="name"
                  :items="pnpNames"
                  label="Porez na potrošnju"
                  v-model="pnpName"
                  :messages="!isTaxed ? 'Firma nije u sustavu PDV-a' : ''"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="isPriceNull"
            class="okButton white--text"
            height="45"
            type="submit"
          >
            Dodaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>

import rules from '@/plugins/rules'
import CurrencyInput from '@/components/CurrencyInput'
import { clone } from '@/plugins/utils'
import state from '@/state'

export default {
  components: { CurrencyInput },
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  props: ['taxes', 'isManual'],
  data: () => ({
    currencyOptions: {
      locale: 'de-DE',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      precision: 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false
    },
    visible: false,
    price: 0,
    base: 0,
    currency: 'EUR',
    currencies: ['EUR', 'USD', 'GBP'],
    rules: {
      req: rules.req(),
      money: rules.money(),
      arrReq: rules.arrReq()
    },
    pdv: {},
    selectedTaxes: [],
    messages: [],
    priceFieldDisabled: false,
    priceTaxes: [],
    unitID: '',
    pnp: 0,
    hasPnp: false,
    pnpName: '',
    pnpNames: [],
    location: undefined,
    pnpChoosen: false,
    priceInputEnabled: true
  }),
  computed: {

    baseInput () {
      return this.base
    },
    isTaxed () {
      return state.getCurrentCompany().is_taxed
    },
    isPriceNull () {
      if (this.isManual) {
        return false
      } else {
        return this.price === 0 || this.base === 0
      }
    }
  },
  watch: {
    currency (val) {
      this.currencyOptions.currency = val
    },
    isManual (val) {
      this.priceFieldDisabled = val
    }
  },
  methods: {
    checkNumber (ev) {
      const maskRe = /^[0-9]*$/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },

    setInputField () {
      this.priceInputEnabled = !this.priceInputEnabled
    },

    open (existing = null, loc = null, unitID = null, newItem = null) {
      if (unitID) {
        this.unitID = unitID
      }
      this.visible = true
      if (loc && loc.has_pnp && loc.has_pnp === true) {
        this.pnp = loc.pnp
        this.pnpName = { name: this.pnp / 100 + ' %' }
        this.pnpNames = [{ name: this.pnp / 100 + ' %' }]

        this.location = loc
      }

      //  this.$nextTick(() => {
      this.priceTaxes = clone(this.taxes)
      if (existing) {
        this.setExistingItemPrice(clone(existing))
      } else {
        this.setNewItemPrice(loc, newItem)
      }
    },
    setNewItemPrice (loc, newItem) {
      this.resetValues()
      if (!this.isTaxed) {
        this.priceTaxes = clone(this.taxes.map(tax => { return { ...tax, ...{ disabled: tax.rate !== 0 } } }))
        this.pdv = this.taxes.find(tax => tax.rate === 0)
      } else {
        this.pdv = this.taxes.find(tax => tax.rate === 2500)
      }

      if (newItem && newItem.item && newItem.item.tax_group !== '') {
        var newIt = clone(newItem)
        switch (newIt.item.tax_group) {
          case 'hrana':
            this.hasPnp = false
            this.priceTaxes.forEach(tax => {
              if (tax.rate === 1300) {
                this.pdv = tax
              }
            })
            break
          case 'pice':
            if (loc && loc.has_pnp && loc.has_pnp === true) {
              this.hasPnp = true
            }
            this.priceTaxes.forEach(tax => {
              if (tax.rate === 2500) {
                this.pdv = tax
              }
            })
            break
          case 'ostalo-0':
            this.priceTaxes.forEach(tax => {
              if (tax.rate === 0) {
                this.pdv = tax
              }
            })
            break
          case 'ostalo-5':
            this.priceTaxes.forEach(tax => {
              if (tax.rate === 500) {
                this.pdv = tax
              }
            })
            break
          case 'ostalo-13':
            this.priceTaxes.forEach(tax => {
              if (tax.rate === 1300) {
                this.pdv = tax
              }
            })
            break
          case 'ostalo-25':
            this.priceTaxes.forEach(tax => {
              if (tax.rate === 2500) {
                this.pdv = tax
              }
            })
            break
        }
      }
    },
    setExistingItemPrice (existing) {
      this.currency = existing.currency

      this.price = parseFloat(existing.price)
      if (!this.isTaxed) {
        this.priceTaxes = clone(this.taxes.map(tax => { return { ...tax, ...{ disabled: tax.rate !== 0 } } }))
      }
      this.pdv = existing.taxes.map(tax => { return { name: tax.name, rate: parseInt(tax.rate) } })[0]

      existing.taxes.forEach(tax => {
        if (tax.name === 'PNP') {
          this.pnp = parseInt(tax.rate)
          this.pnpName = { name: this.pnp / 100 + ' %' }
          this.pnpNames = [{ name: this.pnp / 100 + ' %' }]
          this.hasPnp = true
        }
      })
      this.setBase(existing)
    },

    close () {
      this.$emit('close')
      this.visible = false
    },

    setBase (item, price, pdvRate) {
      var base = 0
      if (item && item.base && item.base > 0) {
        base = parseFloat(item.base)
      }
      if (base <= 0) {
        if (!price) { price = this.price }
        if (!pdvRate) { pdvRate = this.pdv.rate / 10000 }
        if (this.hasPnp && this.pnp > 0) {
          pdvRate += this.pnp / 10000
        }
        base = parseFloat(price / (1 + pdvRate))
      }
      this.base = base
    },

    calculatePrice () {
      const base = parseFloat(this.base)

      const pdvRate = clone(this.pdv.rate)
      let pnpRate = 0
      if (this.hasPnp && this.pnp > 0) {
        pnpRate = clone(this.pnp)
      }

      var taxRate = pdvRate + pnpRate
      taxRate /= 10000

      var price = parseFloat(base + (base * taxRate))

      this.price = price
    },

    submit () {
      if (!this.$refs.addPriceForm.validate()) return
      var taxes = [this.pdv].map(tax => {
        return { name: tax.name, rate: tax.rate }
      })

      if (this.hasPnp && this.pnp > 0) {
        taxes.push({
          name: 'PNP', rate: this.pnp
        })
      }
      const price = this.price
      const base = this.base

      if (this.unitID) {
        this.$emit('addWh', { price: price, base: base, taxes: taxes, currency: this.currency }, this.unitID)
      } else {
        this.$emit('add', { price: price, base: base, taxes: taxes, currency: this.currency })
      }

      this.resetValues()
      this.visible = false
    },

    resetValues () {
      this.price = 0
      this.base = 0
      this.currency = 'HRK'
      if (Date.now() / 1000 > 1672527599) {
        this.currency = 'EUR'
      }
      this.selectedTaxes = []
    }
  }
}
</script>
