<template>
  <v-dialog v-model="visible" persistent scrollable width="400">
    <v-form ref="inventoryStatusChangeForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto py-3 pa-2"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="mb-2">
            <h3 class="text--secondary">Završi inventuru</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <label>
                Trenutna inventura:
                {{
                  showStatus(
                    inventory && inventory.status ? inventory.status : ""
                  )
                }}
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                label="Promjena inventure"
                dense
                :items="statuses"
                v-model="selectedStatus"
                item-text="name"
                item-value="value"
                :rules="[rules.req]"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="close"> Odustani </v-btn>
          <v-btn class="white--text okButton" @click="submit"> Završi </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-overlay v-if="loading" absolute z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
// import state from '@/state'
import { v4 as uuidv4 } from 'uuid'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      visible: false,
      loading: false,
      inventory: undefined,
      selectedStatus: undefined,
      warehouse: undefined,
      statuses: [{
        name: 'Izvrši inventuru', value: 'OK'
      },
      {
        name: 'Odbij inventuru', value: 'DENIED'
      }],
      rules: {
        req: rules.req()
      }
    }
  },
  computed: {},
  methods: {
    showStatus (status) {
      switch (status) {
        case 'PENDING':
          return 'U izradi'
        case 'OK':
          return 'Izvršen'
        case 'DENIED':
          return 'Odbijen'
        default:
          return 'Nepoznat'
      }
    },
    open (inventory, warehouse) {
      if (!inventory) return
      this.visible = true
      this.inventory = clone(inventory)
      this.warehouse = clone(warehouse)
    },
    close () {
      this.visible = false
    },
    submit () {
      if (!this.selectedStatus || this.selectedStatus === '') return
      var that = this
      this.loading = true
      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'update',
            entity: 'inventura',
            resource_id: this.inventory.id,
            params: {
              id: this.inventory.id,
              warehouse_id: this.warehouse,
              status: this.selectedStatus
            }
          }
        }

        df.doc(`request/${reqId}`)
          .set({
            msg_id: reqId,
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'inventura',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .catch((error) => {
            // console.error('Error writing document: ', error)
            throw new Error(error)
          })

        const docRef = df.doc(`response/${reqId}`)
        const unsubscribe = docRef.onSnapshot(function (doc) {
          if (doc.data()) {
            unsubscribe()

            if (!doc.data().body || !doc.data()) {
              if (doc.data().header && doc.data().header.code !== 200 && doc.data().header.error !== '') {
                that.showMsgBox({
                  title: 'Uspješna promjena statusa inventure',
                  text: doc.data().header.error,
                  actions: ['cancel'],
                  cancelBtnText: that.$t('$vuetify.close'),
                  color: 'error'
                })
              } else {
                that.showMsgBox({
                  title: 'Neuspješna promjena statusa inventure',
                  text: 'Promjena statusa inventure nije uspjela.',
                  actions: ['cancel'],
                  cancelBtnText: that.$t('$vuetify.close'),
                  color: 'error'
                })
              }
            }
            that.close()
            that.$emit('finishedChange')
            that.loading = false
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err,
          actions: ['cancel'],
          cancelBtnText: 'OK'
        })
      }
    }
  }
}
</script>
