<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addItemForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Artikl</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-textarea
                    rows="2"
                    dense
                    class="pt-2"
                    outlined
                    label="Naziv"
                    autofocus
                    v-model="item.name"
                    :rules="[rules.req]"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0 mt-0 mb-0">
                <v-col class="pt-0 pb-4 mt-0 mb-0">
                  <v-text-field
                    hide-details
                    dense
                    outlined
                    label="Barkodovi sa zarezom"
                    v-model="item.barcodes"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-4 mt-0 mb-0">
                  <v-text-field
                    dense
                    outlined
                    label="Šifra"
                    v-model="item.code"
                    @input="checkCodeAvailability"
                    validate-on-blur
                    :rules="[rules.codeused]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0 mt-0 mb-0">
                <v-col class="pt-0 pb-0 mt-0 mb-0">
                  <v-select
                    dense
                    outlined
                    label="Vrsta artikla"
                    v-model="item.type"
                    item-text="name"
                    item-value="value"
                    :items="itemTypes"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
                <v-col class="pt-0 pb-0 mt-0 mb-0">
                  <v-select
                    dense
                    outlined
                    label="Porezna grupa"
                    v-model="item.tax_group"
                    item-text="text"
                    item-value="value"
                    :items="taxGroups"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="pt-0 pb-0 mt-0 mb-0 flex-wrap justify-center">
                <div style="width: 70%" class="pl-3 pr-2 pa-0 ma-0">
                  <v-select
                    dense
                    outlined
                    label="Dobavljač"
                    v-model="supplier"
                    item-text="name"
                    return-object
                    :items="suppliers"
                  ></v-select>
                </div>

                <div style="width: 30%" class="pa-0 pr-3 ma-0">
                  <v-btn
                    x-small
                    class="white--text okButton"
                    height="40"
                    style="width: 100%"
                    @click="openSupplier()"
                    :disabled="supplier === undefined ? true : false"
                    >Uredi dobavljača</v-btn
                  >
                </div>
              </v-row>
              <v-row
                v-if="
                  item.realSuppliers !== undefined &&
                  item.realSuppliers.length > 0
                "
                class="pt-0 mt-0 flex-wrap justify-center"
              >
                <v-col class="pt-0 mt-0 pb-2 mb-5">
                  <v-data-table
                    v-if="
                      item.realSuppliers !== undefined &&
                      item.realSuppliers.length > 0
                    "
                    :headers="itemSupplierHeader"
                    :items="item.realSuppliers"
                    class="elevation-3"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template v-slot:body="{ items, headers }">
                      <tbody>
                        <tr v-for="(item, idx) in items" :key="idx">
                          <td v-for="(header, key) in headers" :key="key">
                            <v-edit-dialog
                              :return-value.sync="item[header.value]"
                            >
                              {{ item[header.value] }}
                              <template v-slot:input>
                                <v-text-field
                                  v-if="headers.length - 1 !== key"
                                  v-model="item[header.value]"
                                  label="Uredi"
                                  single-line
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                            <v-icon
                              class="pb-4"
                              small
                              v-if="headers.length - 1 === key"
                              @click="removeSupplier(idx)"
                            >
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-text-field
                  class="pa-0"
                  v-if="warehouseModule"
                  outlined
                  dense
                  hide-details
                  v-model="baseUnit.storage_unit"
                  label="Osnovna jedinica"
                ></v-text-field>
                <v-text-field
                  class="pa-0 pl-2"
                  v-if="warehouseModule"
                  outlined
                  dense
                  hide-details
                  v-model="item.selling_unit"
                  label="Jedinica za prodaju"
                >
                </v-text-field>
                <v-checkbox
                  class="pl-2"
                  v-model="baseUnit.is_selling_item"
                  outlined
                  dense
                  v-if="warehouseModule"
                  label="Prodajna jedinica"
                >
                </v-checkbox>
              </v-row>
              <!-- <v-row class="ma-0 pa-0 pb-2">
                <v-col class="pl-0 pt-0 pb-0" cols="4">
                  <v-text-field
                    class="pa-0 pl-0"
                    v-if="warehouseModule"
                    outlined
                    dense
                    hide-details
                    :rules="[rules.req]"
                    v-model="item.selling_unit"
                    label="Jedinica za prodaju"
                  >
                  </v-text-field>
                </v-col>
              </v-row> -->
              <v-row class="pl-3 pa-0 ma-0">
                <v-col class="d-flex justify-center pa-0 ma-0">
                  <v-checkbox
                    v-model="baseUnit.default_sale"
                    class="pa-0 ma-0"
                    outlined
                    @change="changeDefaultSale(baseUnit.default_sale)"
                    label="Zadana prodajna jedinica"
                  >
                  </v-checkbox>
                  <v-checkbox
                    v-model="baseUnit.default_store"
                    class="pa-0 pl-1 ma-0"
                    outlined
                    @change="changeDefaultStore(baseUnit.default_store)"
                    label="Zadana skladišna jedinica"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <!-- <v-col v-if="customUnits.length > 0">
                <v-flex v-for="(unit, idx) in customUnits" :key="idx">
                  <v-row class="pa-10">
                    <v-col cols="4">
                      <v-text-field
                        :disabled="
                          unit.enabled !== undefined && unit.enabled === true
                            ? !unit.enabled
                            : true
                        "
                        outlined
                        v-model="unit.storage_unit"
                        label="Jedinica mjere"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <quantity-input
                        :disabled="
                          unit.enabled !== undefined && unit.enabled === true
                            ? !unit.enabled
                            : true
                        "
                        outlined
                        label="Količina"
                        v-model="unit.storage_amount"
                      ></quantity-input>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        outlined
                        :value="baseUnit.storage_unit"
                        disabled
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox
                        :disabled="
                          unit.enabled !== undefined && unit.enabled === true
                            ? !unit.enabled
                            : true
                        "
                        outlined
                        width="20%"
                        v-model="unit.is_selling_item"
                        label="Prodajna jedinica"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-row class="pa-0">
                      <v-col class="d-flex justify-center">
                        <v-checkbox
                          :disabled="
                            unit.enabled !== undefined && unit.enabled === true
                              ? !unit.enabled
                              : true
                          "
                          class="pa-2"
                          outlined
                          v-model="unit.default_sale"
                          label="Zadana prodajna jedinica"
                          @change="
                            changeDefaultSaleCustom(unit.default_sale, idx)
                          "
                        >
                        </v-checkbox>
                        <v-checkbox
                          :disabled="
                            unit.enabled !== undefined && unit.enabled === true
                              ? !unit.enabled
                              : true
                          "
                          class="pa-2"
                          outlined
                          v-model="unit.default_store"
                          @change="
                            changeDefaultStoreCustom(unit.default_store, idx)
                          "
                          label="Zadana skladišna jedinica"
                        >
                        </v-checkbox>
                        <v-btn
                          class="white--text okButton mx-2"
                          height="45"
                          color="grey"
                          dark
                          @keyup.enter="removeUnit(idx)"
                          @click.stop="removeUnit(idx)"
                          >Ukloni jedinicu</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider></v-divider>
                </v-flex>
              </v-col> -->
              <!-- <v-row class="pa-5">
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="white--text okButton mx-2"
                    height="45"
                    v-if="this.warehouseModule"
                    color="grey"
                    dark
                    @keyup.enter="addMoreUnits"
                    @click.stop="addMoreUnits"
                    >Dodaj jedinicu</v-btn
                  >
                  <v-btn
                    class="white--text okButton mx-2"
                    height="45"
                    v-if="this.warehouseModule"
                    color="grey"
                    dark
                    @keyup.enter="removeUnits"
                    @click.stop="removeUnits"
                    >Ukloni jedinicu</v-btn
                  >
                </v-col>
              </v-row> -->
              <v-row class="justify-center pa-0 ma-0" v-if="!showUnits">
                <v-btn
                  class="pa-0"
                  style="z-index: 8"
                  bottom
                  right
                  plain
                  text
                  v-if="this.warehouseModule"
                  @keyup.enter="openAddUnitForm"
                  @click.stop="openAddUnitForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj jedinicu</span></v-btn
                >
              </v-row>
              <v-expansion-panels v-if="showUnits">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="d-flex justify-center pa-0" v-if="showUnits">
                      <p class="text-h6 align-center font-weight-bold">
                        Dodatne jedinice mjere
                      </p>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center pa-0">
                      <v-data-table
                        v-if="showUnits"
                        :headers="unitHeaders"
                        :items="unitTableRows"
                        :items-per-page="-1"
                        class="elevation-1"
                        hide-default-footer
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>{{ item.customItemUnit }}</td>
                            <td>{{ item.customItemAmount }}</td>
                            <td>{{ baseUnit.storage_unit }}</td>
                            <td>
                              <v-icon v-if="item.sellingItem === true"
                                >mdi-check</v-icon
                              >
                              <v-icon v-if="item.sellingItem === false"
                                >mdi-close</v-icon
                              >
                            </td>
                            <td>
                              <v-icon v-if="item.defaultSale === true"
                                >mdi-check</v-icon
                              >
                              <v-icon v-if="item.defaultSale === false"
                                >mdi-close</v-icon
                              >
                            </td>
                            <td>
                              <v-icon v-if="item.defaultStore === true"
                                >mdi-check</v-icon
                              >
                              <v-icon v-if="item.defaultStore === false"
                                >mdi-close</v-icon
                              >
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-btn
                        class="pa-5 pt-7"
                        style="z-index: 8"
                        bottom
                        right
                        plain
                        text
                        @click="openAddUnitForm"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span>{{ unitButton }}</span>
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider> </v-divider>
              <v-row class="d-flex justify-center pa-0" v-if="!showNorms">
                <v-btn
                  class="pa-0 pt-5"
                  style="z-index: 8"
                  bottom
                  right
                  plain
                  text
                  @click="openAddNormForm"
                >
                  <v-icon>mdi-plus</v-icon>
                  <span>Dodaj normativ</span>
                </v-btn>
              </v-row>

              <v-expansion-panels v-if="showNorms">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="d-flex justify-center pa-0" v-if="showNorms">
                      <p class="text-h6 align-center font-weight-bold">
                        Normativ
                      </p>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center pa-0">
                      <v-data-table
                        v-if="showNorms"
                        :headers="headers"
                        :items="tableRows"
                        :items-per-page="-1"
                        class="elevation-1"
                        hide-default-footer
                      >
                      </v-data-table>
                    </v-row>
                    <v-row class="d-flex justify-center pa-0">
                      <v-btn
                        class="pa-5 pt-7"
                        style="z-index: 8"
                        bottom
                        right
                        plain
                        text
                        @click="openAddNormForm"
                      >
                        <v-icon>mdi-plus</v-icon>
                        <span>{{ normButton }}</span>
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" class="pt-0 ma-0">
              <v-row class="justify-center pa-0 ma-0">
                <v-col cols="3">
                  <v-img
                    :src="item.picture"
                    v-if="item.picture"
                    contain
                    height="123px"
                    width="124px"
                    :lazy-src="require('@/assets/no-item-small.jpg')"
                  >
                  </v-img>
                  <v-img
                    v-else
                    contain
                    height="123px"
                    width="124px"
                    :src="
                      defaultImg
                        ? defaultImg
                        : require('@/assets/no-item-small.jpg')
                    "
                  ></v-img>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="6">
                  <v-file-input
                    label="Odaberi sliku artikla"
                    ref="imageUp"
                    style="overflow: hidden"
                    @change="onFileChange"
                    clearable
                    @click:close="onFileChange"
                  >
                    <!-- <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{on, attrs}">
                            <v-btn
                              icon
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Izbriši sliku artikla</span>
                        </v-tooltip>
                      </template> -->
                  </v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting || isExistingCode"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-norm ref="addNorm" @add="onAddNorm"></add-norm>
    <add-unit ref="addUnit" @add="onAddUnit"></add-unit>
    <add-item-supplier-codes
      ref="addCodes"
      @add="onAddCodes"
    ></add-item-supplier-codes>
  </v-dialog>
</template>
<script>
import { df, auth, functions } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import { clone } from '@/plugins/utils'
import requestChecker from '@/mixins/requestChecker'
import AddNorm from '@/modules/company/components/AddNorm'
import AddUnit from '@/modules/company/components/AddUnit'
import AddItemSupplierCodes from '@/modules/company/components//AddItemSupplierCodes'

export default {
  components: { AddNorm, AddUnit, AddItemSupplierCodes },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [requestChecker],
  data: () => ({
    visible: false,
    submitting: false,
    itemTypes: [],
    storageUnits: [],
    sellingUnits: [],
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    checking: false,
    isExistingCode: false,
    selectedLocations: [],
    locations: [],
    tableRows: [],
    unitTableRows: [],
    item: {},
    defaultImg: '',
    warehouseModule: false,
    listeners: [],
    baseUnit: {},
    customUnits: [],
    normItemSelected: undefined,
    norms: [],
    showNorms: false,
    selectedNormType: undefined,
    items: [],
    currentItemCode: null,
    headers: [{
      text: 'Artikl',
      value: 'name'
    },
    {
      text: 'Količina',
      value: 'quantity'
    }],
    unitHeaders: [{
      text: 'Mjerna jedinica',
      value: 'customItemUnit'
    },
    {
      text: 'Količina',
      value: 'customItemAmount'
    },
    {
      text: 'Osnovna mjerna jedinica',
      value: 'whStorageUnit'
    },
    {
      text: 'Prodajna jedinica',
      value: 'sellingItem'
    },
    {
      text: 'Zadana prodajna jedinica',
      value: 'defaultSale'
    },
    {
      text: 'Zadana skladišna jedinica',
      value: 'defaultStore'
    }],
    showUnits: false,
    supplier: undefined,
    itemCode: undefined,
    suppliers: [],
    itemSuppliers: [],
    itemSupplierHeader: [{
      text: 'Ime dobavljača',
      value: 'name'
    },
    {
      text: 'Šifra kod dobavljača',
      value: 'item_code'
    },
    {
      text: 'Ukloni',
      value: 'delete'
    }],
    taxGroups: [{
      text: 'Hrana - 13%',
      value: 'hrana'
    },
    {
      text: 'Piće - 25%',
      value: 'pice'
    },
    {
      text: 'Ostalo - 0%',
      value: 'ostalo-0'
    },
    {
      text: 'Ostalo - 5%',
      value: 'ostalo-5'
    },
    {
      text: 'Ostalo - 13%',
      value: 'ostalo-13'
    },
    {
      text: 'Ostalo - 25%',
      value: 'ostalo-25'
    }]
  }),
  mounted () {
    var query = df.collection('suppliers').where('company_ids', 'array-contains', state.getCurrentCompany().id)

    var listener = query
      .onSnapshot((doc) => {
        this.suppliers = []
        doc.docs.forEach((rec) => {
          this.suppliers.push(rec.data())
        })
      })

    this.suppliers = this.suppliers.sort((a, b) => a.name.localeCompare(b.name))
    this.listeners.push(listener)
    this.getCompItems()
    this.getMultiplier()
    if (state.getCurrentCompany().warehouse === 'module') {
      this.warehouseModule = true
    }

    this.itemTypes = [
      { name: this.$t('$vuetify.lager.itemTypes.goods'), value: 'GOODS' },
      { name: this.$t('$vuetify.lager.itemTypes.service'), value: 'SERVICE' }
    ]

    this.storageUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' }
    ]

    this.sellingUnits = [
      { name: 'Komad', value: 'komad' },
      { name: 'Kilogram', value: 'kilogram' },
      { name: 'Litra', value: 'litra' },
      { name: 'Sat', value: 'sat' },
      { name: 'm2', value: 'm2' }
    ]

    this.rules.codeused = (val) => {
      return (
        !this.isExistingCode ||
        `Artikl sa šifrom ${val} već postoji.`
      )
    }
  },
  computed: {
    normButton () {
      if (this.norms.length > 0) {
        return 'Uredi'
      } else {
        return 'Dodaj normativ'
      }
    },
    unitButton () {
      if (this.customUnits.length > 0) {
        return 'Uredi'
      } else {
        return 'Dodaj jedinicu'
      }
    }
  },
  methods: {
    async checkCodeAvailability () {
      const code = this.item.code
      this.checking = true

      if (this.item.code !== undefined && this.item.code !== '') {
        const isExistingCode = await df
          .collection(`companies/${state.getCurrentCompany().id}/items`)
          .where('code', '==', code)
          .where('status', '==', 'OK')
          .where('id', '!=', this.item.id)
          .get()

        this.isExistingCode = !isExistingCode.empty
      } else {
        this.isExistingCode = false
      }
      this.checking = false
    },
    async checkCode () {
      if (!this.item.code || this.item.code === '') return
      const query = await df.collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('code', '==', this.item.code)
        .where('id', '!=', this.item.id)
        .limit(1)

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc.data.length > 0) {
              this.item.code = this.currentItemCode
              this.showMsgBox({
                text: this.$t('$vuetify.errors.itemCodeDuplicate'),
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
            }
          })
        })
    },
    onAddCodes (supplierData) {
      if (supplierData.codes.length > 0) {
        if (this.item.realSuppliers && this.item.realSuppliers.length > 0) {
          this.item.realSuppliers = this.item.realSuppliers.filter(sup => sup.id !== this.supplier.id)
        }

        if (this.item.realSuppliers === undefined) {
          this.item.realSuppliers = []
        }

        supplierData.codes.forEach(code => {
          this.item.realSuppliers.push({ id: this.supplier.id, name: this.supplier.name, type: this.supplier.type, item_code: code, item_codes: [code] })
        })
      }

      this.$forceUpdate()
    },
    openSupplier () {
      var codes = []
      if (this.item.realSuppliers && this.item.realSuppliers.length > 0) {
        this.item.realSuppliers.forEach(supp => {
          if (supp.id === this.supplier.id) {
            codes.push(supp.item_code)
          }
        })
      }
      this.$refs.addCodes.open(this.supplier, codes)
    },
    removeSupplier (key) {
      this.item.realSuppliers.splice(key, 1)

      const tmp = this.item.realSuppliers
      this.item.realSuppliers = []
      this.item.realSuppliers = JSON.parse(JSON.stringify(tmp))

      this.$forceUpdate()
    },
    addSupplier () {
      if (this.supplier !== undefined && this.itemCode !== undefined) {
        if (this.item.realSuppliers === undefined) {
          this.item.realSuppliers = []
        }
        this.item.realSuppliers.push({ id: this.supplier.id, name: this.supplier.name, item_code: this.itemCode })

        const tmp = this.item.realSuppliers
        this.item.realSuppliers = []
        this.item.realSuppliers = JSON.parse(JSON.stringify(tmp))
        this.$forceUpdate()

        this.resetSupplierData()
      }
    },
    resetSupplierData () {
      this.itemCode = undefined
      this.supplier = undefined
    },
    async getCompItems () {
      const query = await df
        .collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('status', '==', 'OK')

      query.get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.items.push(doc.data())
          })
        })
    },
    onAddUnit (resp) {
      if (resp && resp.units && resp.units.length > 0) {
        this.customUnits = []
        resp.units.forEach(unit => {
          const id = unit.id ? unit.id : uuidv4()
          this.customUnits.push({ id: id, storage_unit: unit.customItemUnit, storage_amount: unit.customItemAmount, is_selling_item: unit.sellingItem, default_sale: unit.defaultSale, default_store: unit.defaultStore })
        })
        if (this.customUnits.length > 0) {
          this.showUnits = true
        } else {
          this.showUnits = false
        }
        this.unitTableRows = []

        this.customUnits.forEach(unit => {
          this.unitTableRows.push({ id: unit.id, customItemUnit: unit.storage_unit, customItemAmount: unit.storage_amount, whStorageUnit: this.baseUnit.storage_unit, sellingItem: unit.is_selling_item, defaultSale: unit.default_sale, defaultStore: unit.default_store })

          if (unit.default_store) {
            this.baseUnit.default_store = false
          }

          if (unit.default_sale) {
            this.baseUnit.default_sale = false
          }
        })
      }

      if (resp !== undefined && resp.units.length === 0) {
        this.customUnits = []
        this.unitTableRows = []
        this.showUnits = false
      }
    },
    onAddNorm (resp) {
      this.selectedNormType = resp.normType
      this.norms = resp.norms
      if (this.norms.length > 0) {
        this.showNorms = true
      } else {
        this.showNorms = false
      }

      this.tableRows = []

      this.norms.forEach(norm => {
        this.tableRows.push({ name: norm.item.name, quantity: norm.quantity, item: norm.item })
      })
    },
    openAddUnitForm () {
      if (this.customUnits !== undefined && this.customUnits.length > 0) {
        this.$refs.addUnit.open(this.baseUnit.storage_unit, this.unitTableRows)
      } else {
        this.$refs.addUnit.open(this.baseUnit.storage_unit, undefined, true)
      }
    },
    openAddNormForm () {
      if (this.norms !== undefined && this.norms.length > 0) {
        this.$refs.addNorm.open(this.item, this.items, this.norms)
      } else {
        this.$refs.addNorm.open(this.item, this.items)
      }
    },
    changeDefaultSaleCustom (value, index) {
      if (value) {
        if (this.customUnits !== undefined && this.customUnits.length > 0) {
          this.customUnits.forEach((_, key) => {
            if (key !== index) {
              this.customUnits[key].default_sale = false
              this.unitTableRows[key].defaultSale = false
            }
          })
        }
        this.baseUnit.default_sale = false
      }
    },
    changeDefaultStoreCustom (value, index) {
      if (value) {
        if (this.customUnits !== undefined && this.customUnits.length > 0) {
          this.customUnits.forEach((_, key) => {
            if (key !== index) {
              this.customUnits[key].default_store = false
              this.unitTableRows[key].defaultStore = false
            }
          })
        }
        this.baseUnit.default_store = false
      }
    },
    changeDefaultSale (value) {
      if (value) {
        if (this.baseUnit !== undefined && this.customUnits.length > 0) {
          this.customUnits.forEach((_, key) => {
            this.customUnits[key].default_sale = false
            this.unitTableRows[key].defaultSale = false
          })
        }
      }
    },
    changeDefaultStore (value) {
      if (value) {
        if (this.baseUnit !== undefined && this.customUnits.length > 0) {
          this.customUnits.forEach((_, key) => {
            this.customUnits[key].default_store = false
            this.unitTableRows[key].defaultStore = false
          })
        }
      }
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.item.picture = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        this.item.picture = this.defaultImg
        return
      }
      if (file.size > 500000) {
        this.showMsgBox({
          text: 'Prevelika datoteka, maksimalna dopuštena veličina datoteke je 500 KB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.item.picture = ''
        this.$refs.imageUp.reset()
        return
      }
      this.createImage(file)
    },
    open (item) {
      this.visible = true
      this.submitting = false
      this.item = clone(item)
      if (this.item.barcodes !== undefined && this.item.barcodes.length > 0) {
        this.item.barcodes = this.item.barcodes.join(',')
      }

      if (this.item.suppliers !== undefined && this.item.suppliers !== null) {
        this.item.realSuppliers = []
        Object.keys(this.item.suppliers).forEach(supp => {
          if (this.item.suppliers[supp].item_codes && this.item.suppliers[supp].item_codes.length > 0) {
            this.item.suppliers[supp].item_codes.forEach(code => {
              this.item.realSuppliers.push({ id: this.item.suppliers[supp].id, item_code: code, name: this.item.suppliers[supp].name })
            })
          } else {
            this.item.realSuppliers.push({ id: this.item.suppliers[supp].id, item_code: this.item.suppliers[supp].item_code, name: this.item.suppliers[supp].name })
          }
        })
      }

      if (this.item.warehouse_units !== undefined && this.item.warehouse_units.units !== undefined) {
        Object.keys(this.item.warehouse_units.units).forEach(key => {
          if (key !== this.item.warehouse_units.base_unit) {
            const amount = this.item.warehouse_units.units[key].storage_amount / this.multiplier
            this.item.warehouse_units.units[key].storage_amount = amount.toString().replace('.', ',')
            this.customUnits.push(this.item.warehouse_units.units[key])
          }
        })

        const amount = this.item.warehouse_units.units[this.item.warehouse_units.base_unit].storage_amount / this.multiplier
        this.item.warehouse_units.units[this.item.warehouse_units.base_unit].storage_amount = amount.toString().replace('.', ',')
        this.baseUnit = this.item.warehouse_units.units[this.item.warehouse_units.base_unit]
        if (this.customUnits.length > 0) {
          this.customUnits.forEach(unit => {
            this.unitTableRows.push({ id: unit.id, customItemUnit: unit.storage_unit, customItemAmount: unit.storage_amount, whStorageUnit: this.baseUnit.storage_unit, sellingItem: unit.is_selling_item, defaultSale: unit.default_sale, defaultStore: unit.default_store })
          })
          this.showUnits = true
        }
      }

      if (this.item.norm !== undefined) {
        Object.keys(this.item.norm.components).forEach(key => {
          this.norms.push({ name: this.item.norm.components[key].item_name, quantity: this.item.norm.components[key].quantity / this.multiplier, item: this.item.norm.components[key] })
        })

        this.norms.forEach(norm => {
          this.tableRows.push({ name: norm.item.item_name, quantity: norm.quantity, item: norm.item })
        })

        this.showNorms = true
      }

      this.defaultImg = clone(item.picture)

      df.doc(
        `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
      )
        .get()
        .then((doc) => {
          if (
            doc.data() &&
            doc.data().locations &&
            Object.keys(doc.data().locations).length > 0
          ) {
            this.locations = Object.keys(doc.data().locations)
              .map((key) => {
                return doc.data().locations[key]
              })
              .filter((location) => location.status === 'OK')
          }
        })
      this.registerRequestParams(this.item)

      this.currentItemCode = this.item.code
    },
    close () {
      this.$refs.addItemForm.reset()
      this.$refs.imageUp.reset()
      this.norms = []
      this.tableRows = []
      this.customUnits = []
      this.unitTableRows = []
      this.showUnits = false
      this.selectedNormType = undefined
      this.visible = false
    },
    submit () {
      const that = this
      if (!this.$refs.addItemForm.validate()) return
      //   this.showLoader()
      this.submitting = true

      if (this.item.picture !== this.defaultImg) {
        if (this.item.picture.split(',').length > 0) {
          this.item.picture = this.item.picture.split(',')[1]
        }
      }
      delete this.item.created

      if (this.item.barcodes && this.item.barcodes !== '') {
        this.item.barcodes = this.item.barcodes.trim()
        const barcodes = this.item.barcodes.split(',')
        if (barcodes[0] !== '') {
          this.item.barcode = barcodes[0]
        }
        this.item.barcodes = barcodes
      }

      if (this.item.realSuppliers !== undefined && this.item.realSuppliers.length > 0) {
        this.item.suppliers = []
        var allSupps = []

        this.item.realSuppliers.forEach(supp => {
          if (allSupps.length === 0) {
            allSupps.push({ id: supp.id, name: supp.name, item_code: supp.item_code, item_codes: [supp.item_code] })
          } else if (allSupps.length > 0) {
            const idx = allSupps.findIndex(s => s?.id === supp.id)

            if (idx > -1) {
              allSupps[idx].item_codes.push(supp.item_code)
            }
          }
        })

        allSupps.forEach(supp => {
          this.item.suppliers.push({ id: supp.id, name: supp.name, item_code: supp.item_code, item_codes: supp.item_codes })
        })
      }

      if (this.item.realSuppliers) {
        delete this.item.realSuppliers
      }

      if (this.item.name) {
        this.item.name = this.item.name.trim()
      }

      const payload = {
        action: {
          operation: 'update',
          entity: 'item',
          resource_id: this.item.id,
          params: {
            ...this.item,
            ...{ company_id: state.getCurrentCompany().id }
          }
        }
      }

      // Ako je skladiste upaljeno salje se na drugi entitet sa drugim podacima
      if (this.warehouseModule) {
        payload.action.entity = 'warehouse_module_item'
        const units = []
        const mpUnits = new Map()

        const newUuid = uuidv4()
        let defaultStore = ''
        let defaultSale = ''

        if (!this.baseUnit.id) {
          this.baseUnit.id = uuidv4()
        }

        const baseUnit = {
          id: this.baseUnit.id,
          base_unit: this.baseUnit.base_unit ? this.baseUnit.base_unit : true,
          storage_unit: this.baseUnit.storage_unit,
          storage_amount: this.baseUnit.storage_amount ? this.baseUnit.storage_amount * this.multiplier : 1 * this.multiplier,
          is_selling_item: this.baseUnit.is_selling_item ? this.baseUnit.is_selling_item : false,
          default_sale: this.baseUnit.default_sale ? this.baseUnit.default_sale : false,
          default_store: this.baseUnit.default_store ? this.baseUnit.default_store : false
        }

        units.push(baseUnit)
        mpUnits.set(newUuid, baseUnit)

        if (this.baseUnit.default_sale !== undefined && this.baseUnit.default_sale !== false) {
          defaultSale = this.baseUnit.id
        }

        if (this.baseUnit.default_store !== undefined && this.baseUnit.default_store !== false) {
          defaultStore = this.baseUnit.id
        }

        this.customUnits.forEach((unit) => {
          if (unit.default_sale !== undefined && unit.default_sale !== false) {
            defaultSale = unit.id
          }

          if (unit.default_store !== undefined && unit.default_store !== false) {
            defaultStore = unit.id
          }

          const temp = {
            id: unit.id,
            storage_unit: unit.storage_unit,
            storage_amount: (parseFloat(unit.storage_amount.replace(',', '.')) * this.multiplier),
            is_selling_item: unit.is_selling_item ? unit.is_selling_item : false,
            default_sale: unit.default_sale ? unit.default_sale : false,
            default_store: unit.default_store ? unit.default_store : false,
            base_unit: false
          }

          units.push(temp)
          mpUnits.set(newUuid, temp)
        })

        const whUnit = {
          base_unit: baseUnit.id ? baseUnit.id : uuidv4(),
          default_sale: defaultSale,
          default_store: defaultStore,
          units: units
        }

        payload.action.params.warehouse_units = whUnit

        if (this.norms !== undefined && this.norms.length > 0) {
          let components = {}

          components = this.norms.reduce((components, it) => {
            return Object.assign(components, {
              [it.itemId]: {
                item_id: it.itemId,
                item_name: it.item.name,
                custom_unit_id: it.customUnitId ? it.customUnitId : '',
                custom_unit: !!(it.customUnitId !== '' && it.customUnitId !== undefined),
                quantity: typeof it.quantity === 'string' ? (parseFloat(it.quantity.replace(',', '.')) * this.multiplier) : it.quantity * this.multiplier
              }
            })
          }, {})

          payload.action.params.norm = {
            type: this.selectedNormType,
            components: components
          }
        }

        if (this.norms === undefined || this.norms === null || this.norms.length === 0) {
          delete payload.action.params.norm
          payload.action.params.remove_norm = true
        }
      }

      const cleanParams = this.getCleanParams(this.item)
      Object.keys(cleanParams).forEach((key) => {
        if (key !== 'warehouse_units') {
          delete payload.action.params[key]
        }
      })

      // Potrebno zbog promjene processing statusa
      payload.action.params.status = 'OK'

      var companyitemApi = functions.httpsCallable('companyitemapi')

      companyitemApi({
        action: {
          operation: payload.action.operation,
          entity: payload.action.entity,
          resource_id: payload.action.resource_id,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            that.submitting = false
            that.close()
            that.visible = false
          } else {
            this.showMsgBox({
              text: result.data.message ?? 'Dogodila se pogreška.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.submitting = false
          }
        })
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
