<template>
  <div style="width: 100%" class="fill-height">
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži artikle"
      ></Search-box
    ></v-row>
    <v-row class="justify-center mb-0">
      <v-col cols="6">
        <v-row>
          <v-col cols="6" :class="['px-0', 'pb-0']">
            <v-select
              dense
              outlined
              label="Odaberite prodajno mjesto"
              v-model="selectedLocation"
              :items="locations"
              item-text="name"
              item-value="value"
              return-object
              @change="setWarehouseChoices"
              hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="6" :class="['px-0', 'pl-3', 'pb-0']">
            <v-select
              dense
              outlined
              label="Odaberite skladište"
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              return-object
              @change="resetHandler"
              hide-details
            >
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.warehouse.location.location_id }} -
                {{ data.item.warehouse.name }}
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-container class="pt-0">
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(extendedItem, i) in items" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                extendedItem.item.status === 'OK'
                  ? 'status-ok-exp'
                  : `${
                      extendedItem.item.status === 'PROCESSING'
                        ? 'status-processing-exp'
                        : 'status-failed-exp'
                    }`,
              ]"
              style="height: 48px !important"
            >
              <div
                style="width: 6% !important"
                v-if="density !== 'greater'"
                :class="[densityPadding]"
              >
                <v-img
                  v-if="extendedItem.item.picture"
                  :src="extendedItem.item.picture"
                  contain
                  :height="density === 'normal' ? '44' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '44' : '68'"
                ></v-img>
              </div>
              <v-col
                style="min-width: 400px; max-width: 90%"
                :class="[densityPadding]"
              >
                <div class="caption grey--text">Naziv</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="
                    hoverOver(
                      `hover-${extendedItem.item.id}`,
                      extendedItem.item
                    )
                  "
                  @mouseleave="
                    extendedItem.item[`hover-${extendedItem.item.id}`] = false
                  "
                  :id="`hover-${extendedItem.item.id}`"
                  :ref="`hover-${extendedItem.item.id}`"
                >
                  {{ $options.filters.capitalize(extendedItem.item.name) }}
                </div>
              </v-col>
              <v-col
                style="min-width: 50px; max-width: 10%"
                :class="[densityPadding]"
              >
                <div class="caption grey--text">Šifra</div>
                <div>{{ extendedItem.item.code }}</div>
              </v-col>
              <v-col
                :class="[densityPadding]"
                style="min-width: 100px; max-width: 10%"
              >
                <div class="caption grey--text text-center">Stanje</div>
                <div class="text-center">
                  {{
                    extendedItem.item.type === "GOODS"
                      ? extendedItem.item.stock.toString().replace(".", ",")
                      : "-"
                  }}
                </div>
              </v-col>
              <v-col
                :class="[densityPadding]"
                style="min-width: 100px; max-width: 20%"
              >
                <div
                  v-if="
                    extendedItem.unit !== undefined && extendedItem.unit !== ''
                  "
                  class="caption grey--text text-center"
                >
                  Mj. jedinica
                </div>
                <div
                  class="text-center"
                  v-if="
                    extendedItem.unit !== undefined && extendedItem.unit !== ''
                  "
                >
                  {{ extendedItem.unit }}
                </div>
              </v-col>
              <v-col :class="[densityPadding]">
                <div class="caption grey--text text-center">Status</div>
                <div
                  :class="[
                    extendedItem.item.status === 'OK'
                      ? 'success--text'
                      : `${
                          extendedItem.item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                    'text-center',
                  ]"
                >
                  {{ extendedItem.item.status }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <add-warehouse-module-item
      ref="addWarehouseModuleItem"
      @success="refreshWarehouse"
    ></add-warehouse-module-item>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import addWarehouseModuleItem from '@/modules/point-of-sale/components/warehouse/warehouse-module/AddWarehouseModuleItem'
import InfiniteLoading from 'vue-infinite-loading'
// import editWarehouseModuleItem from '@/modules/point-of-sale/components/warehouse/warehouse-module/EditWarehouseModuleItem'
import EventBus from '@/plugins/event-bus'
import { collection, query, orderBy, getDocs, where, limit, startAfter } from 'firebase/firestore'

export default {
  name: 'WarehouseItems',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { addWarehouseModuleItem, InfiniteLoading },
  data: () => ({
    dialog: false,
    loading: false,
    totalArticles: 0,
    lastiItem: false,
    items: [],
    allWarehouses: [],
    warehouses: [],
    selectedWarehouse: '',
    listeners: [],
    multiplier: 0,
    selectedLocation: undefined,
    locations: [],
    checkRadio: '',
    showLocationSelector: false,
    choosenText: '',
    innerSearch: undefined,
    barcode: [],
    searchEmitDestination: 'warehouseItemsSearch',
    lastDocSnapshot: null,
    searchObject: undefined
  }),
  computed: {
    radioValues () {
      return [
        { name: 'Na firmi', value: 'company' },
        { name: 'Na prodajnom mjestu', value: 'pointOfSale' },
        { name: 'Sva', value: 'all' }
      ]
    },
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
  },
  async mounted () {
    this.checkRadio = 'pointOfSale'
    if (state.getPointOfSale().id === undefined || state.getPointOfSale.id === '') {
      this.checkRadio = 'company'
    }
    // FILTER STAVLJEN PRIJE JER NE STIGNE DOHVATITI PA ISPADNE DA NEMA
    // this.filterWarehouses()

    // DOHVATI SVE LOKACIJE ZA FIRMU
    await this.getLocations()

    // DOHVATI SVA SKLADISTA I PODATKE O NJIMA
    await this.getAllWarehouses()
    document.onkeydown = this.handleKeyEvents

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchObject = searchObject

      if (!searchObject.triGramObject) {
        this.lastDocSnapshot = null
        this.detachListeners()
        this.resetHandler()
      } else {
        this.lastDocSnapshot = null
        this.detachListeners()
        this.resetHandler()
        // this.getItemsNew(searchObject.triGramObject)
      }
    })

    this.getMultiplier()
  },
  methods: {
    resetHandler () {
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    async infiniteHandler ($state) {
      const newItemsCount = await this.getItemsNew()
      if (newItemsCount > 0) {
        return $state.loaded() // getItemsNew je vratio vise od nula artikala, znaci da ih ima jos
      }
      return $state.complete() // getItemsNew je vratio 0 artikala, znaci da ih vise nema
    },
    async getItemsNew () {
      var triGramObject = this.searchObject?.triGramObject
      if (!this.selectedWarehouse || this.selectedWarehouse === null || this.selectedWarehouse.value === '') {
        return 0
      }
      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`item.meta.${name}`, '==', true))
        )
      }

      if (!triGramObject) {
        searchConstraints.push(orderBy('item.name', 'asc'))
      }

      if (this.lastDocSnapshot) {
        searchConstraints.push(startAfter(this.lastDocSnapshot))
      }

      searchConstraints.push(where('item.type', '==', 'GOODS'))

      searchConstraints.push(limit(10))

      const constraints = [
        collection(df, `warehouses/${this.selectedWarehouse.value}/extended_items`),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)

      const querySnapshot = await getDocs(q)
      this.lastDocSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1]

      const results = []
      querySnapshot.forEach(doc => {
        var whIt = doc.data()
        results.push({ _id: whIt.id, ...whIt })
      })

      // if (triGramObject) {
      //   this.items = []
      // }

      for (const whIt of results) {
        df.doc(`companies/${state.getCurrentCompany()?.id}/items/${whIt.id}`)
          .get()
          .then((it) => {
            if (it && it.data()) {
              var normOnSale = false

              let unit = whIt.selling_unit
              if (it && it.data() && it.data().warehouse_units !== undefined && it.data().warehouse_units.units !== undefined && it.data().warehouse_units.base_unit !== '') {
                unit = it.data().warehouse_units.units[it.data().warehouse_units.base_unit].storage_unit
              }

              if (it.data() !== undefined && it.data().norm !== undefined && it.data().norm.components !== undefined) {
                const item = it.data()
                if (item.norm.type === 'ON_SALE' || item.norm.type === 'sale') {
                  normOnSale = true
                }
              }

              if (!normOnSale) {
                whIt.item.stock = whIt.item.stock / this.multiplier
                whIt.warehouse_data.quantity_available = whIt.warehouse_data.quantity_available / this.multiplier
                whIt.unit = unit

                this.items.push({ _id: whIt.id, ...whIt })
                this.items = this.items.sort((a, b) => a.item.name.localeCompare(b.item.name))
              }
            }
          })
      }

      // if (triGramObject) {
      //   return true
      // }

      return results.length
    },

    barcodeFind (e) {
      if (e.key !== 'Enter') { this.barcode.push(e.key) }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        if (this.validateBarcode(this.barcode.join(''))) {
          this.innerSearch = this.barcode.join('')
          // this.innerSearchItems()
          // this.search = this.barcode.join('')
          // this.searchItems()
        } this.barcode = []
      }, 100)
    },
    validateBarcode (b) {
      var Barcoder = require('barcoder')
      return Barcoder.validate(b)
    },
    handleKeyEvents (e) {
      e = e || window.event
      this.barcodeFind(e)
    },
    filterWarehouses () {
      this.warehouses = this.allWarehouses
      switch (this.checkRadio) {
        case 'company':
          this.warehouses = this.warehouses.filter(wh => {
            if (wh.warehouse.location_id === '') {
              return wh
            }
          })
          break
        case 'pointOfSale':
          this.warehouses = this.warehouses.filter((wh, k) => {
            if (wh.warehouse.location_id === this.selectedLocation.id) {
              if (wh.warehouse.location_id === this.selectedLocation.id && wh.warehouse.selling_warehouse === true) {
                this.selectedWarehouse = this.warehouses[k]
                this.resetHandler()
              }
              return wh
            }
          })
          break
        case 'all':
      // ALREADY DONE
      }
    },
    setWarehouseChoices () {
      this.warehouses = this.allWarehouses
      this.warehouses = this.warehouses.filter(wh => {
        if (wh.warehouse.location_id === this.selectedLocation.id) {
          if (wh.warehouse.location_id === this.selectedLocation.id && wh.warehouse.selling_warehouse === true) {
            this.selectedWarehouse = wh.warehouse
            this.resetHandler()
          }
          return wh
        }
      })
    },
    getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
              if (this.checkRadio === 'pointOfSale' && state.getPointOfSale().id !== undefined && loc.id === state.getPointOfSale().id) {
                this.selectedLocation = loc
                this.filterWarehouses()
              }
            })
          }
        })
    },
    async getAllWarehouses () {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('status', '==', 'OK')
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          this.warehouses.push({ name: warehouse.name, value: warehouse.id, warehouse: warehouse })
        })
      })
      this.allWarehouses = this.warehouses
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openAddWarehouseModuleItemForm () {
      this.$refs.addWarehouseModuleItem.open(this.multiplier)
    },
    openDialog () {
      this.dialog = true
    },
    openEditForm (item) {
      this.$refs.editWarehouseModuleItem.open(item, this.multiplier)
    },
    refreshWarehouse (item) {
      if (item.action === 'ADD') {
        this.items.unshift({ ...item, ...{ status: 'PROCESSING' } })
        this.$vuetify.goTo(0)
      } else if (item.action === 'EDIT') {
        this.items = this.items.map((_item) => {
          if (_item.id === item.id) {
            _item.name = item.name
            _item.status = 'PROCESSING'
          }
          return _item
        })
      }
    },
    // modifyItems (change, unit) {
    //   // Filter za micanje artikala sa skladista koji imaju samo normative koji se skidaju pri izdavanju racuna
    //   // to znaci da njih uopce ne treba ispisivati koliko ih ima na stanju
    //   if (change.type === 'added') {
    //     if (
    //       this.items.some(
    //         (extendedItem) =>
    //           extendedItem.item.id === change.doc.data().item.id ||
    //           (extendedItem.item.name.toLowerCase() === change.doc.data().item.name &&
    //             extendedItem.item.status === 'PROCESSING')
    //       )
    //     ) {
    //       this.items = this.items.map((extendedItem) => {
    //         let ret = extendedItem
    //         if (
    //           extendedItem.item.id === change.doc.data().item.id ||
    //           (extendedItem.item.name === change.doc.data().item.name.toLowerCase() &&
    //             extendedItem.item.status === 'PROCESSING')
    //         ) {
    //           ret = change.doc.data()
    //           ret.item.stock = ret.item.stock / this.multiplier
    //           ret.warehouse_data.quantity_available = ret.warehouse_data.quantity_available / this.multiplier
    //         }
    //         ret.unit = unit
    //         return { ...ret, ...{ [`hover-${change.doc.data().item.id}`]: false } }
    //       })
    //     } else {
    //       const extendedItem = change.doc.data()
    //       extendedItem.item.stock = extendedItem.item.stock / this.multiplier
    //       extendedItem.warehouse_data.quantity_available = extendedItem.warehouse_data.quantity_available / this.multiplier
    //       extendedItem.unit = unit
    //       this.items.push({
    //         ...extendedItem,
    //         ...{ [`hover-${extendedItem.item.id}`]: false }
    //       })
    //     }
    //   }
    //   if (change.type === 'modified') {
    //     this.items = this.items.map((extendedItem) => {
    //       let ret = extendedItem
    //       if (extendedItem.item.id === change.doc.data().item.id) {
    //         ret = change.doc.data()
    //         ret.item.stock = ret.item.stock / this.multiplier
    //         ret.warehouse_data.quantity_available = ret.warehouse_data.quantity_available / this.multiplier
    //         ret.unit = unit
    //       }
    //       return { ...ret, ...{ [`hover-${change.doc.data().item.id}`]: false } }
    //     })
    //   }
    // },
    // getItems () {
    //   const that = this
    //   if (this.selectedWarehouse === undefined || this.selectedWarehouse.value === '') {
    //     return
    //   }

    //   let search = 'item.name'
    //   if (this.isBarcodeSearch(this.search)) {
    //     search = 'item.barcodes'
    //   }

    //   const where = that.search ? that.search.toLowerCase().trim() : ''
    //   let query = df.collection(`warehouses/${this.selectedWarehouse.value}/extended_items`)
    //     .orderBy(search, 'asc')

    //   if (where === '') {
    //     query = query
    //       .limit(12)
    //   }

    //   if (where && search === 'item.name') {
    //     query = query
    //       .where(search.trim(), '>=', where)
    //       .where(search.trim(), '<=', where + '\uf8ff')
    //   }

    //   if (where && search === 'item.barcodes') {
    //     query = query
    //       .where('item.barcodes', 'array-contains', state.search)
    //   }

    //   query.onSnapshot(
    //     (doc) => {
    //       doc.docChanges().forEach((change) => {
    //         if (change && change.doc.data() && change.doc.data().id) {
    //           df.doc(`companies/${state.getCurrentCompany()?.id}/items/${change.doc.data().id}`)
    //             .get()
    //             .then((it) => {
    //               if (it && it.data()) {
    //                 var normOnSale = false

    //                 let unit = change.doc.data().selling_unit
    //                 if (it && it.data() && it.data().warehouse_units !== undefined && it.data().warehouse_units.units !== undefined && it.data().warehouse_units.base_unit !== '') {
    //                   unit = it.data().warehouse_units.units[it.data().warehouse_units.base_unit].storage_unit
    //                 }

    //                 if (it.data() !== undefined && it.data().norm !== undefined && it.data().norm.components !== undefined) {
    //                   const item = it.data()
    //                   if (item.norm.type === 'ON_SALE') {
    //                     normOnSale = true
    //                   }
    //                 }

    //                 if (!normOnSale) {
    //                   that.modifyItems(change, unit)
    //                 }
    //               }
    //             })
    //         }
    //       })
    //     }
    //   )
    // },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
