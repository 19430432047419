<template>
  <v-dialog v-model="supplierDialog" max-width="400">
    <v-card
      class="overflow-auto"
      :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
    >
      <v-card-title class="d-flex" style="vertical-align: middle">
        <v-row class="pa-3 mb-2">
          <h3 class="text--secondary">
            Dobavljač {{ supplier && supplier.name ? supplier.name : "" }}
          </h3>
          <v-spacer></v-spacer>
          <v-icon large @click="close">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-text-field
                dense
                outlined
                hide-details
                v-model="code"
                label="Šifra"
              >
              </v-text-field>

              <v-btn @click="addCode()">Dodaj šifru</v-btn>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  dense
                  :headers="codeHeaders"
                  :items="itemCodes"
                  item-key="name"
                  class="elevation-1 ml-5 mr-5"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:body="{ items, headers }">
                    <tbody>
                      <tr v-for="(item, idx) in items" :key="idx">
                        <td v-for="(header, key) in headers" :key="key">
                          <v-edit-dialog
                            :return-value.sync="item[header.value]"
                          >
                            {{ item[header.value] }}
                            <template v-slot:input>
                              <v-text-field
                                v-if="headers.length - 1 !== key"
                                v-model="item[header.value]"
                                label="Uredi"
                                single-line
                              ></v-text-field>
                            </template>
                          </v-edit-dialog>
                          <v-icon
                            class="pb-4"
                            small
                            v-if="headers.length - 1 === key"
                            @click="itemCodes.splice(idx, 1)"
                          >
                            mdi-delete
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-5">
        <v-btn @click="submit">Spremi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import { df, auth } from '@/plugins/firebase'
// import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    code: '',
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    codeHeaders: [{ text: 'Šifra artikla', value: 'code' }, { text: 'Ukloni', value: 'delete' }],
    itemCodes: [],
    supplier: undefined,
    supplierDialog: false
  }),

  methods: {
    addCode () {
      this.itemCodes.push({ code: this.code })
      this.code = ''
    },
    open (supplier, itemCodes) {
      this.supplier = supplier
      if (itemCodes && itemCodes.length > 0) {
        itemCodes.forEach(itCode => {
          this.itemCodes.push({ code: itCode })
        })
      }
      this.supplierDialog = true
    },
    resetSupplierData () {
      this.itemCodes = []
      this.code = ''
      this.supplierDialog = false
    },
    submit () {
      if (this.itemCodes.length === 0) {
        return
      }
      var codes = []
      this.itemCodes.forEach(itCode => {
        codes.push(itCode.code)
      })
      this.$emit('add', { codes: codes })

      this.resetSupplierData()
    },
    close () {
      this.itemCodes = []
      this.supplier = undefined
      this.code = ''
      this.supplierDialog = false
    }
  }

}
</script>
