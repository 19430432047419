<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="365"
    style="z-index: 6"
  >
    <v-form ref="warehouseForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">{{ $tc("$vuetify.item", 1) }}</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  :label="$tc('$vuetify.item', 1)"
                  bottom
                  outlined
                  v-model="selectedItem"
                  :items="lagerItems"
                  return-object
                  item-text="name"
                  :menu-props="{ bottom: true }"
                  hide-selected
                  :rules="[rules.req]"
                  id="itemSelection"
                  autofocus
                  @input="setFocusOnNext"
                >
                  <template v-slot:prepend-item>
                    <v-text-field
                      autofocus
                      class="pr-5 pl-5"
                      :label="$t('$vuetify.pointOfSale.searchItemsLabel')"
                      @click:prepend-inner="getLagerItems"
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchModel"
                      @keyup.enter="getLagerItems"
                      :loading="loading"
                      id="searchItem"
                    >
                    </v-text-field>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }} - {{ item.code }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <quantity-input
                  :label="$t('$vuetify.pointOfSale.stock')"
                  outlined
                  autofocus
                  v-model="stock"
                  :rules="[rules.req]"
                  id="stock"
                ></quantity-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.pointOfSale.addWarehouseItemBtn") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
import state from '@/state'
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import QuantityInput from '../../../components/QuantityInput.vue'

export default {
  components: { QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'showAlert'],
  data: () => ({
    visible: false,
    submitting: false,
    loading: false,
    selectedItem: undefined,
    stock: undefined,
    lagerItems: [],
    searchModel: '',
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq()
    }
  }),
  methods: {
    open (multiplier) {
      this.multiplier = clone(multiplier)
      this.visible = true
      this.submitting = false
    },
    close () {
      this.$emit('close')
      this.$nextTick(() => {
        this.$refs.warehouseForm.reset()
        this.visible = false
      })
    },
    setFocusOnNext () {
      document.getElementById('stock').focus()
    },
    getLagerItems () {
      this.loading = true
      const where = this.searchModel ? this.searchModel.toLowerCase() : ''
      df.collection(`companies/${state.getCurrentCompany().id}/items`)
        .where('name', '>=', where)
        .where('name', '<=', where + '\uf8ff')
        .orderBy('name')
        .limit(15)
        .get()
        .then((documentSnapshots) => {
          this.loading = false
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            this.lagerItems = []
            documentSnapshots.docs.forEach((item) => {
              if (item.data().status === 'OK') {
                this.lagerItems.push(item.data())
              }
            })
          }
        })
    },
    submit (item = undefined, external = false) {
      if (!external && !this.$refs.warehouseForm.validate()) return

      try {
        const payload = {
          action: {
            operation: 'update',
            entity: 'warehouse',
            resource_id: external ? item.id : this.selectedItem.id,
            params: {
              location_id: state.getPointOfSale().id,
              status: this.selectedItem.status,
              // company_id: state.getCurrentCompany().id,
              // item_id: external ? item.id : this.selectedItem.id,
              stock: external ? 0 : (parseFloat(this.stock.toString().replace(',', '.')) * this.multiplier)
            }
          }
        }

        this.submitting = true
        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: 'update',
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              if (!external) {
                this.$refs.warehouseForm.reset()
                this.submitting = false
                this.visible = false
              }
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.submitting = false
            }
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
<style>
.items {
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}
</style>
