<template>
  <div style="width: 100%" class="fill-height">
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži artikle"
      ></Search-box>
    </v-row>
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in items" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing'
                        : 'status-failed'
                    }`,
              ]"
            >
              <v-col
                cols="4"
                sm="4"
                md="1"
                v-if="density !== 'greater'"
                :class="[densityPadding]"
              >
                <v-img
                  v-if="item.picture"
                  :src="item.picture"
                  contain
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
              </v-col>
              <v-col
                cols="5"
                sm="5"
                md="3"
                lg="4"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Naziv artikla</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.name) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">{{ item.name }}</div>
                </v-tooltip>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                md="2"
                lg="1"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Sifra artikla</div>
                <div>{{ item.code }}</div>
              </v-col>
              <v-col cols="4" sm="4" md="2" :class="[densityPadding]">
                <div class="caption grey--text text-right">
                  Stanje na skladištu
                </div>
                <div class="text-right">
                  {{
                    item.type === "GOODS"
                      ? item.stock.toString().replace(".", ",")
                      : "-"
                  }}
                </div>
              </v-col>
              <v-col cols="4" sm="4" md="2" :class="[densityPadding]">
                <div class="caption grey--text">Status artikla</div>
                <div
                  :class="[
                    item.status === 'OK'
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                  ]"
                >
                  {{ item.status }}
                </div>
              </v-col>
              <v-col cols="2" lg="1" :class="[densityPadding]">
                <div class="caption grey--text text-center">Akcije</div>
                <div class="d-flex align-start justify-center">
                  <v-tooltip v-if="item.type !== 'SERVICE'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="item.status === 'PROCESSING'"
                        @click="openEditForm(item)"
                        @keypress="openEditForm(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi artikl</span>
                  </v-tooltip>
                  <span v-else>-</span>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(item, i) in items"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <v-img
            height="190"
            width="250"
            v-if="item.picture"
            :src="item.picture"
          >
          </v-img>
          <v-img height="190" width="250" v-else src="@/assets/no-item.jpg">
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ $options.filters.capitalize(item.name) }}
              </h3>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Status: {{ item.status }}
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  :disabled="item.status === 'PROCESSING'"
                  @click="openEditForm(item)"
                  @keypress="openEditForm(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Uredi artikl</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <add-warehouse-item
      ref="addWarehouseItem"
      @success="refreshWarehouse"
      @refresh="refresh"
    ></add-warehouse-item>
    <edit-warehouse-item
      ref="editWarehouseItem"
      @success="refreshWarehouse"
      @refresh="refresh"
    ></edit-warehouse-item>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import addWarehouseItem from '@/modules/point-of-sale/components/AddWarehouseItem'
import editWarehouseItem from '@/modules/point-of-sale/components/EditWarehouseItem'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { collection, query, getDocs, orderBy, where, limit, startAfter } from 'firebase/firestore'
import EventBus from '@/plugins/event-bus'

export default {
  name: 'Warehouse',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { addWarehouseItem, InfiniteLoading, editWarehouseItem },
  data: () => ({
    dialog: false,
    loading: false,
    totalArticles: 0,
    lastVisible: '',
    lastiItem: false,
    items: [],
    searchEmitDestination: 'simpleWarehouseSearch'
  }),
  computed: {
    listView () {
      return state.isListView()
    },
    search () {
      return state.search
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    }
    // search () {
    //   this.lastVisible = ''
    //   this.items = []
    //   if (this.$refs.InfiniteLoading) {
    //     this.$refs.InfiniteLoading.stateChanger.reset()
    //   }
    // }
  },
  mounted () {
    this.getMultiplier()

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      if (!searchObject.triGramObject) {
        this.lastDocSnapshot = null
        this.detachListeners()
        this.resetHandler()
      } else {
        this.getItemsNew(searchObject.triGramObject)
      }
    })
  },
  methods: {
    refresh () {
      this.lastDocSnapshot = null
      this.detachListeners()
      this.resetHandler()
    },
    async infiniteHandler ($state) {
      const newItemsCount = await this.getItemsNew()
      if (newItemsCount > 0) {
        return $state.loaded() // getItems je vratio vise od nula artikala, znaci da ih ima jos
      }
      return $state.complete() // getItems je vratio 0 artikala, znaci da ih vise nema
    },

    async getItemsNew (triGramObject = undefined) {
      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`meta.${name}`, '==', true))
        )
      }

      if (!triGramObject) {
        searchConstraints.push(orderBy('name', 'asc'))
      }

      if (this.lastDocSnapshot && !triGramObject) {
        searchConstraints.push(startAfter(this.lastDocSnapshot))
      }

      searchConstraints.push(limit(10))

      const constraints = [
        collection(df, `location_warehouse/${state.getPointOfSale().id}/items`),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)

      const querySnapshot = await getDocs(q)
      this.lastDocSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1]

      const results = []
      querySnapshot.forEach(doc => {
        const d = doc.data()
        d.stock = d.stock / this.multiplier
        d.stock = d.stock.toString().replace('.', ',')
        if (d.prices) {
          Object.keys(d.prices).forEach(key => {
            d.prices[key].currency = key
          })
        }

        results.push({ _id: d.id, ...d })
      })

      if (triGramObject) {
        this.items = results
        this.items = this.items.sort((a, b) => a.name.localeCompare(b.name))
        return true
      }

      this.items.push(...results)
      return results.length
    },
    resetHandler () {
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].clientHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openAddWarehouseItemForm () {
      this.$refs.addWarehouseItem.open(this.multiplier)
    },
    openDialog () {
      this.dialog = true
    },
    openEditForm (item) {
      this.$refs.editWarehouseItem.open(item, this.multiplier)
    },
    refreshWarehouse (item) {
      if (item.action === 'ADD') {
        this.items.unshift(
          { ...item, ...{ status: 'PROCESSING' } }
        )
        this.$vuetify.goTo(0)
      }

      if (item.action === 'EDIT') {
        df.doc(`location_warehouse/${state.getPointOfSale().id}/items/${item.id}`).update({ status: 'PROCESSING' })
        const idx = this.items.findIndex(it => it?.id === item.id)
        if (idx > -1) {
          this.items[idx].status = 'PROCESSING'
          this.$forceUpdate()
        }
      }

      const listener = df.doc(`location_warehouse/${state.getPointOfSale().id}/items/${item.id}`)
        .onSnapshot(doc => {
          if (doc && doc.data()) {
            var newItem = doc.data()

            if (newItem.status === 'OK') {
              const idx = this.items.findIndex(it => it?.id === item.id)

              if (idx > -1) {
                newItem.stock = newItem.stock / this.multiplier
                this.items[idx] = newItem
              }

              this.$forceUpdate()
            }
          }
        })

      this.listeners.push(listener)

      // if (item.action === 'ADD') {
      //   this.items.unshift({ ...item, ...{ status: 'PROCESSING' } })
      //   this.$vuetify.goTo(0)
      // } else if (item.action === 'EDIT') {
      //   this.items = this.items.map((_item) => {
      //     if (_item.id === item.id) {
      //       _item.name = item.name
      //       _item.status = 'PROCESSING'
      //     }
      //     return _item
      //   })
      // }
    },
    // modifyItems (change) {
    //   if (change.type === 'added') {
    //     if (
    //       this.items.some(
    //         (item) =>
    //           item.id === change.doc.data().id ||
    //           (item.name.toLowerCase() === change.doc.data().name &&
    //             item.status === 'PROCESSING')
    //       )
    //     ) {
    //       this.items = this.items.map((item) => {
    //         let ret = item
    //         if (
    //           item.id === change.doc.data().id ||
    //           (item.name === change.doc.data().name.toLowerCase() &&
    //             item.status === 'PROCESSING')
    //         ) {
    //           ret = change.doc.data()
    //           ret.stock = ret.stock / this.multiplier
    //         }
    //         return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
    //       })
    //     } else {
    //       const item = change.doc.data()
    //       item.stock = item.stock / this.multiplier
    //       this.items.push({
    //         ...item,
    //         ...{ [`hover-${item.id}`]: false }
    //       })
    //     }
    //   }
    //   if (change.type === 'modified') {
    //     this.items = this.items.map((item) => {
    //       let ret = item
    //       if (item.id === change.doc.data().id) {
    //         ret = change.doc.data()
    //         ret.stock = ret.stock / this.multiplier
    //       }
    //       return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
    //     })
    //   }
    // },
    // infiniteHandler ($state) {
    //   const that = this

    //   let search = 'name'
    //   if (this.isBarcodeSearch(this.search)) {
    //     search = 'barcode'
    //   }

    //   const where = that.search ? that.search.toLowerCase() : ''
    //   let query = df
    //     .collection(`location_warehouse/${state.getPointOfSale().id}/items`)
    //     .orderBy(search, 'asc')
    //     .limit(10)
    //     .startAfter(that.lastVisible)

    //   if (where) {
    //     query = query
    //       .where(search, '>=', where)
    //       .where(search, '<=', where + '\uf8ff')
    //   }

    //   const listener = query.onSnapshot(
    //     (doc) => {
    //       doc.docChanges().forEach((change) => {
    //         that.modifyItems(change)
    //       })
    //       if (doc && !doc.empty) {
    //         if (doc.docs[doc.docs.length - 1]) {
    //           that.lastVisible = doc.docs[doc.docs.length - 1]
    //         }
    //         $state.loaded()
    //       } else {
    //         $state.complete()
    //       }
    //     }
    //     // (error) => {
    //     //   console.log('error', error)
    //     // }
    //   )

    //   this.listeners.push(listener)
    // },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
