var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"pt-3 pr-3"},[_c('v-spacer'),_c('Search-box',{attrs:{"emitDestination":_vm.searchEmitDestination,"searchPlaceholder":"Pretraži artikle"}})],1),_c('v-row',{staticClass:"justify-center mb-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{class:['px-0', 'pb-0'],attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Odaberite prodajno mjesto","items":_vm.locations,"item-text":"name","item-value":"value","return-object":"","hide-details":""},on:{"change":_vm.setWarehouseChoices},model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}})],1),_c('v-col',{class:['px-0', 'pl-3', 'pb-0'],attrs:{"cols":"6"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Odaberite skladište","items":_vm.warehouses,"item-text":"name","item-value":"value","return-object":"","hide-details":""},on:{"change":_vm.resetHandler},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.warehouse.location.location_id)+" - "+_vm._s(data.item.warehouse.name)+" ")]}}]),model:{value:(_vm.selectedWarehouse),callback:function ($$v) {_vm.selectedWarehouse=$$v},expression:"selectedWarehouse"}})],1)],1)],1)],1),_c('v-container',{staticClass:"pt-0"},[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.items),function(extendedItem,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
              'ma-0',
              extendedItem.item.status === 'OK'
                ? 'status-ok-exp'
                : ("" + (extendedItem.item.status === 'PROCESSING'
                      ? 'status-processing-exp'
                      : 'status-failed-exp')) ],staticStyle:{"height":"48px !important"}},[(_vm.density !== 'greater')?_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"6% !important"}},[(extendedItem.item.picture)?_c('v-img',{attrs:{"src":extendedItem.item.picture,"contain":"","height":_vm.density === 'normal' ? '44' : '68'}}):_c('v-img',{attrs:{"src":require("@/assets/no-item.jpg"),"height":_vm.density === 'normal' ? '44' : '68'}})],1):_vm._e(),_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"400px","max-width":"90%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Naziv")]),_c('div',{ref:("hover-" + (extendedItem.item.id)),refInFor:true,staticClass:"text-subtitle-1 one-liner-text",attrs:{"id":("hover-" + (extendedItem.item.id))},on:{"mouseover":function($event){return _vm.hoverOver(
                    ("hover-" + (extendedItem.item.id)),
                    extendedItem.item
                  )},"mouseleave":function($event){extendedItem.item[("hover-" + (extendedItem.item.id))] = false}}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(extendedItem.item.name))+" ")])]),_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"50px","max-width":"10%"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Šifra")]),_c('div',[_vm._v(_vm._s(extendedItem.item.code))])]),_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"100px","max-width":"10%"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Stanje")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(extendedItem.item.type === "GOODS" ? extendedItem.item.stock.toString().replace(".", ",") : "-")+" ")])]),_c('v-col',{class:[_vm.densityPadding],staticStyle:{"min-width":"100px","max-width":"20%"}},[(
                  extendedItem.unit !== undefined && extendedItem.unit !== ''
                )?_c('div',{staticClass:"caption grey--text text-center"},[_vm._v(" Mj. jedinica ")]):_vm._e(),(
                  extendedItem.unit !== undefined && extendedItem.unit !== ''
                )?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(extendedItem.unit)+" ")]):_vm._e()]),_c('v-col',{class:[_vm.densityPadding]},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Status")]),_c('div',{class:[
                  extendedItem.item.status === 'OK'
                    ? 'success--text'
                    : ("" + (extendedItem.item.status === 'PROCESSING'
                          ? 'info--text'
                          : 'error--text')),
                  'text-center' ]},[_vm._v(" "+_vm._s(extendedItem.item.status)+" ")])])],1),_c('v-divider')],1)}),1)],1):_vm._e(),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-results"},slot:"no-results"})])],1),_c('add-warehouse-module-item',{ref:"addWarehouseModuleItem",on:{"success":_vm.refreshWarehouse}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }