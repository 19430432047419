<template>
  <v-dialog
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="showCountList"
  >
    <v-card>
      <v-card-title>
        <v-row class="pa-3 mb-2">
          <h2 class="text--secondary">Povijest brojanja</h2>
          <v-spacer></v-spacer>
          <v-icon large @click="showCountList = false">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          justify="center"
          :items="countList"
          dense
          :headers="countHeaders"
          :items-per-page="-1"
          class="elevation-2 pb-5 mb-5"
          :search="searchItem"
          hide-default-footer
          :custom-filter="filterOnlyCapsText"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td colspan="1" style="text-align: center">
                {{ item.izbrojana_kolicina / multiplier }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.korisnik }}
              </td>
              <td colspan="1" style="text-align: center">
                {{ item.vrijeme | fullDateTime }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
// import { clone } from '@/plugins/utils'
// import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'WarehouseDocuments',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  data: () => ({
    margin: '56px',
    searchModel: '',
    listeners: [],
    multiplier: 0,
    countHeaders: [
      { text: 'Izbrojana količina', value: 'izbrojana_kolicina', sortable: false, align: 'center', filterable: true },
      { text: 'Korisnik', value: 'korisnik', align: 'center', sortable: false },
      { text: 'Vrijeme', value: 'vrijeme', align: 'center', sortable: false }

    ],
    showCountList: false,
    countList: [],
    searchItem: undefined
  }),
  created () {
    this.margin = state.getMargin()
  },
  async mounted () {
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.getMultiplier()
  },
  methods: {
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    open (item) {
      this.countList = []
      this.countList = item.povijest
      this.showCountList = true
    },
    filterOnlyCapsText (value, searchItem, item) {
      return value != null &&
      searchItem.toLowerCase() != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(searchItem.toLowerCase()) !== -1
    }

  }
}
</script>
