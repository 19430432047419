<template>
  <div style="width: 100%" class="fill-height">
    <v-row>
      <v-col class="stepper-wrapper" :style="`margin-left: ${margin}`">
        <v-row style="margin-top: 20px" class="justify-center">
          <div>
            <v-btn plain text @click="goBack()"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
          </div>
          <div style="min-width: 25% !important">
            <v-row class="pa-0 ma-0">
              <v-select
                label="Odaberite prodajno mjesto"
                v-model="selectedLocation"
                outlined
                dense
                hide-details
                :items="locations"
                item-text="name"
                item-value="value"
                return-object
                @change="filterWarehouses()"
              >
              </v-select>
            </v-row>
          </div>
          <div style="min-width: 25%; padding-left: 3px">
            <v-select
              label="Odaberite skladište"
              outlined
              dense
              hide-details
              v-model="selectedWarehouse"
              :items="warehouses"
              item-text="name"
              item-value="value"
              return-object
            >
            </v-select>
          </div>
          <!-- <div v-if="!started" style="min-width: 20%; padding-left: 3px">
            <v-btn
              class="white--text okButton"
              height="40"
              @click="startInventory"
              >Kreiraj inventurni dokument</v-btn
            >
          </div> -->
          <div style="padding: 5px 0px 0px 0px">
            <HelpButton helpMode="inventura" helpTag="dodavanje"></HelpButton>
          </div>
        </v-row>
        <v-row v-if="started === true" class="justify-center pt-5">
          <div class="pr-3">
            <v-select
              :label="$tc('$vuetify.item', 1)"
              multiple
              outlined
              dense
              clearable
              return-object
              v-model="selectedItems"
              :items="warehouseItems"
              item-text="item.name"
              :menu-props="{ bottom: true }"
              :disabled="!searchAdd"
              :rules="[rules.req]"
              id="itemSelection"
              @clear="clearListAndWarehouseItems"
            >
              <template v-slot:prepend-item>
                <v-text-field
                  autofocus
                  class="pr-5 pl-5"
                  :label="$t('$vuetify.pointOfSale.searchItemsLabel')"
                  prepend-inner-icon="mdi-magnify"
                  v-model="searchModel"
                  @keyup.enter="searchItems()"
                  :loading="loading"
                  @focus="clearListAndWarehouseItems"
                  id="searchItem"
                >
                </v-text-field>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </div>
          <div class="pr-3">
            <v-btn
              autofocus
              :disabled="
                !searchAdd || !selectedItems || selectedItems.length <= 0
              "
              class="white--text okButton"
              height="40"
              @click="addItemsToList()"
              >Dodaj</v-btn
            >
          </div>
          <div>
            <v-btn
              class="white--text okButton"
              height="40"
              @click="getInventoryItems()"
              >Prikaži listu artikala u inventuri</v-btn
            >

            <!-- <v-btn
              :disabled="!searchAdd"
              class="white--text okButton"
              height="40"
              @click="getAllItems()"
              >Dodaj sve artikle</v-btn
            > -->
          </div>
        </v-row>

        <div class="d-flex justify-center" v-if="started === true">
          <div justify="center">
            <v-data-table
              justify="center"
              :items="allItems"
              dense
              :headers="headers"
              :items-per-page="-1"
              class="elevation-2 pb-5 mb-5"
              hide-default-footer
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td colspan="1">
                    {{ item.rbr }}
                  </td>
                  <td colspan="1">
                    {{ item.naziv }}
                  </td>
                  <td style="text-align: center" colspan="1">
                    {{ item.trenutna_kolicina / multiplier }}
                  </td>
                  <td style="text-align: center" colspan="1">
                    {{ item.pocetna_kolicina / multiplier }}
                  </td>
                  <td style="text-align: center" colspan="1">
                    {{ item.inventurna_kolicina / multiplier }}
                  </td>
                  <td colspan="1">
                    <div style="width: 100px">
                      <v-text-field
                        style=""
                        v-model="item.dodavanje"
                        outlined
                        dense
                        hide-details
                        @keypress="checkInput($event)"
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:body.append>
                <tr>
                  <th colspan="5" style="text-align: right">
                    <v-btn
                      class="ma-2 white--text okButton"
                      medium
                      @click="goBack()"
                      >Završi</v-btn
                    >
                  </th>
                  <th style="text-align: center" class="pa-0 ma-0">
                    <v-btn
                      class="ma-2 white--text okButton"
                      medium
                      @click="saveItemToDocument()"
                      >Spremi</v-btn
                    >
                  </th>
                </tr>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <inventory-items-list ref="inventoryItemsList"></inventory-items-list>
  </div>
</template>
<script>
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
import { v4 as uuidv4 } from 'uuid'
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'
import InventoryItemsList from './InventoryItemsList.vue'

export default {
  components: { InventoryItemsList },
  name: 'ActiveInventory',
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  data: () => ({
    step: 1,
    margin: '56px',
    loading: false,
    currency: 'EUR',
    searchModel: '',
    listeners: [],
    multiplier: 0,
    searchField: '',
    warehouses: [],
    allWarehouses: [],
    selectedWarehouse: '',
    selectedLocation: undefined,
    locations: [],
    stavke: [],
    company: undefined,
    allItems: [],
    started: false,
    rules: {
      req: rules.req(),
      arrReq: rules.arrReq()
    },
    selectedItems: [],
    warehouseItems: [],
    searchAdd: true,
    targetModifier: 'company',
    headers: [
      {
        text: 'Rb.',
        align: 'start',
        sortable: false,
        value: 'rbr'
      },
      { text: 'Naziv', value: 'item.name', sortable: false },
      { text: 'Trenutna količina', value: 'item.stock', sortable: false },
      { text: 'Početna količina', value: 'item.stock', sortable: false },
      { text: 'Inventurna količina', value: 'item.inventory_quantity', sortable: false },
      { text: 'Upis/dodavanje', value: 'item.', sortable: false }
    ]
  }),
  created () {
    this.margin = state.getMargin()
  },
  async mounted () {
    this.setTargetModifier()
    await this.getAllWarehouses()
    await this.getLocations()
    this.company = clone(state.getCurrentCompany())

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    // Provjera da li si dosao preko nastavi inventuru
    if (this.$route.params.location && this.$route.params.warehouse && this.$route.params.inventory) {
      this.selectedLocation = this.$route.params.location
      this.selectedWarehouse = { name: this.$route.params.warehouse.name, value: this.$route.params.warehouse.id, warehouse: this.$route.params.warehouse }

      this.activeInventory = this.$route.params.inventory.id
      await this.getInitialItemsForInventory(this.activeInventory, this.selectedWarehouse.value)
      this.started = true
    }

    // Provjera da li si dosao preko započni inventuru
    if (this.$route.params.location && this.$route.params.warehouse && !this.$route.params.inventory) {
      this.selectedLocation = this.$route.params.location
      this.selectedWarehouse = { name: this.$route.params.warehouse.name, value: this.$route.params.warehouse.id, warehouse: this.$route.params.warehouse }

      await this.startInventory()
      this.started = true
    }

    this.getMultiplier()
  },
  methods: {
    goBack () {
      this.$router.push({
        name: this.targetModifier + '.warehouseModule.inventories',
        params: {
          location: this.selectedLocation,
          warehouse: this.selectedWarehouse.warehouse
        }
      })
    },
    async getInventoryItems () {
      this.$refs.inventoryItemsList.open(this.selectedWarehouse.value, this.activeInventory)
    },
    clearListAndWarehouseItems () {
      this.searchModel = ''
      this.selectedItems = []
      this.allItems = []
      this.warehouseItems = []
    },
    async saveItemToDocument () {
      if (this.allItems && this.allItems.length > 0) {
        this.allItems.forEach(async (item, key) => {
          if (item.dodavanje && item.dodavanje !== '') {
            var dodavanje = this.formatNumber(item.dodavanje, 1000)

            // Mijenjanje inventurne kolicine
            item.inventurna_kolicina += dodavanje
            item.razlika_kolicine = item.pocetna_kolicina - item.inventurna_kolicina
            delete item.dodavanje

            var rbr = this.allItems[key].rbr
            this.allItems[key].rbr = 0
            this.allItems[key].rbr = rbr

            // Ovo sluzi za prepisivanje itema na skladistu
            var itemZaSkladiste = clone(item.item)

            var now = parseInt(
              (new Date().getTime() / 1000).toFixed(0)
            )

            var existingItem = await this.checkItemInInventory(item.item)

            var newCounting = { vrijeme: now, izbrojana_kolicina: dodavanje, korisnik: state.getUser().name + ' ' + state.getUser().surname }

            var itemZaInventuru

            if (!existingItem) {
              if (!this.allItems[key].povijest) {
                this.allItems[key].povijest = []
              }
              this.allItems[key].povijest.push(newCounting)
              itemZaInventuru = clone(this.allItems[key])
            }

            if (existingItem) {
              this.allItems[key].povijest = existingItem.povijest
              this.allItems[key].povijest.push(newCounting)

              itemZaInventuru = clone(this.allItems[key])
            }
            delete itemZaInventuru.item

            df.doc(`warehouse_inventories/${this.selectedWarehouse.value}/inventories/${this.activeInventory}/items/${itemZaInventuru.id}`).set(itemZaInventuru)

            // Ako je prvi unos, promijeni stanje na skladistu na 0
            if (!existingItem) {
              itemZaSkladiste.item.stock = 0
              itemZaSkladiste.warehouse_data.quantity = 0
              df.doc(`warehouses/${this.selectedWarehouse.value}/extended_items/${item.id}`).update(itemZaSkladiste)
            }
          }
        })
      }

      this.$forceUpdate()
    },
    addItemsToList () {
      this.selectedItems.forEach(async it => {
        var existingItem

        existingItem = await this.checkItemInInventory(it)
        if (existingItem) {
          this.allItems.push({
            rbr: existingItem.rbr,
            id: it.item.id,
            naziv: it.item.name,
            trenutna_kolicina: it.warehouse_data.quantity,
            pocetna_kolicina: existingItem.pocetna_kolicina,
            inventurna_kolicina: existingItem.inventurna_kolicina,
            item: it,
            status: 'PENDING'
          })
        } else {
          this.allItems.push({
            rbr: this.allItems.length + 1,
            id: it.item.id,
            naziv: it.item.name,
            trenutna_kolicina: it.warehouse_data.quantity,
            pocetna_kolicina: it.warehouse_data.quantity,
            inventurna_kolicina: 0,
            item: it,
            status: 'PENDING'
          })
        }
      })

      this.allItems.sort((a, b) => (a.rbr > b.rbr) ? 1 : ((b.rbr < a.rbr) ? -1 : 0))

      // Dodavanje rednog broja
      var i = 1
      this.allItems.forEach((_, key) => {
        this.allItems[key].rbr = i++
      })

      this.clearListAndWarehouseItems()
    },
    async checkItemInInventory (item) {
      var existingItem
      const response = await df.doc(`warehouse_inventories/${this.selectedWarehouse.value}/inventories/${this.activeInventory}/items/${item.item.id}`).get()
      if (response.data()) {
        existingItem = response.data()
      }

      return existingItem
    },
    async getItemFromWarehouse (item, selectedWarehouse) {
      var existingItem
      const response = await df.doc(`warehouses/${selectedWarehouse}/extended_items/${item.id}`).get()
      if (response.data()) {
        existingItem = response.data()
      }

      return existingItem
    },
    searchItems () {
      this.warehouseItems = []
      this.allItems = []
      this.loading = true
      const where = this.searchModel ? this.searchModel.toLowerCase() : ''

      df.collection(`warehouses/${this.selectedWarehouse.value}/extended_items`)
        .where('item.name'.trim(), '>=', where.trim())
        .where('item.name'.trim(), '<=', where.trim() + '\uf8ff')
        .where('item.status', '==', 'OK')
        .orderBy('item.name')
        .limit(15)
        .get()
        .then((documentSnapshots) => {
          this.loading = false
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              this.warehouseItems.push(item.data())
            })
          }
        })
    },
    startInventory () {
      this.activeInventory = uuidv4()
      const now = parseInt(
        (new Date().getTime() / 1000).toFixed(0)
      )

      df.collection(`warehouse_inventories/${this.selectedWarehouse.value}/inventories`)
        .where('active_inventory', '==', true)
        .limit(1)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.empty === true) {
            df.doc(`warehouse_inventories/${this.selectedWarehouse.value}/inventories/${this.activeInventory}`)
              .set({
                id: this.activeInventory,
                active_inventory: true,
                started_user_id: `${auth.currentUser.uid}`,
                started_user: `${state.getUser().name + ' ' + state.getUser().surname}`,
                created: now,
                status: 'PENDING'
              })

            this.started = true
          } else {
            this.goBack()
          }
        })
    },
    // async getAllItems () {
    //   this.confirm({
    //     title: 'Dodavanje artikala u inventuru',
    //     message: 'Jeste li sigurni da želite prebaciti sve artikle u inventuru?',
    //     options: {
    //       toolbar: true,
    //       width: 410,
    //       confirmText: 'DA',
    //       cancelText: 'NE'
    //     }
    //   }).then(async resp => {
    //     if (resp) {
    //       this.searchAdd = false
    //       var tempItem = []
    //       this.allItems = []
    //       this.confirmClose()

    //       if (this.selectedWarehouse && this.selectedWarehouse.value !== '') {
    //         await df.collection(`warehouses/${this.selectedWarehouse.value}/extended_items`)
    //           .where('item.status', '==', 'OK')
    //           .get()
    //           .then((documentSnapshots) => {
    //             if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
    //               documentSnapshots.docs.forEach((item) => {
    //                 tempItem.push(item.data())
    //               })
    //             }
    //           })

    //         tempItem.sort((a, b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))

    //         tempItem.forEach(it => {
    //           this.allItems.push({
    //             rbr: this.allItems.length + 1,
    //             id: it.item.id,
    //             naziv: it.item.name,
    //             trenutna_kolicina: it.warehouse_data.quantity,
    //             pocetna_kolicina: it.warehouse_data.quantity,
    //             inventurna_kolicina: 0,
    //             item: it
    //           })
    //         })

    //         this.allItems.sort((a, b) => (a.naziv > b.naziv) ? 1 : ((b.naziv > a.naziv) ? -1 : 0))
    //         var i = 1
    //         this.allItems.forEach((_, key) => {
    //           this.allItems[key].rbr = i++
    //         })
    //       }
    //     }
    //   })
    // },
    filterWarehouses () {
      this.allItems = []
      this.warehouses = this.allWarehouses
      this.warehouses = this.warehouses.filter((wh, k) => {
        if (wh.warehouse.location_id === this.selectedLocation.id) {
          if (wh.warehouse.selling_warehouse === true) {
            this.selectedWarehouse = this.warehouses[k]
            this.getActiveInventory()
          }
          return wh
        }
      })
    },
    getLocations () {
      this.showLocationSelector = false
      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
              if (this.checkRadio === 'pointOfSale' && state.getPointOfSale().id !== undefined && loc.id === state.getPointOfSale().id) {
                this.selectedLocation = loc
                this.showLocationSelector = true
                this.filterWarehouses()
              }
            })

            this.$forceUpdate()
          }
        })
    },

    async getAllWarehouses () {
      const query = df
        .collection('warehouses/')
        .where('company_id', '==', state.getCurrentCompany().id)
        .where('status', '==', 'OK')
      query.get().then((snapshot) => {
        snapshot.forEach((doc) => {
          const warehouse = doc.data()
          this.warehouses.push({ name: warehouse.name, value: warehouse.id, warehouse: warehouse })
        })
      })
      this.allWarehouses = this.warehouses
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    async getActiveInventory () {
      if (this.selectedWarehouse && this.selectedWarehouse.value !== '') {
        await df.collection(`warehouse_inventories/${this.selectedWarehouse.value}/inventories`).where('active_inventory', '==', true).get()
          .then((documentSnapshots) => {
            if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
              documentSnapshots.docs.forEach(async (item) => {
                this.activeInventory = item.data().id
                await this.getInitialItemsForInventory(this.activeInventory, this.selectedWarehouse.value)
                this.started = true
              })
            } else {
              this.started = false
            }
          })
      }
    },
    async getInitialItemsForInventory (activeInventory, selectedWarehouse) {
      await df.collection(`warehouse_inventories/${selectedWarehouse}/inventories/${activeInventory}/items`).get()
        .then((documentSnapshots) => {
          var tempArray = []
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach(async (item) => {
              if (item.data()) {
                var inItem = item.data()
                var whItem = await this.getItemFromWarehouse(inItem, selectedWarehouse)
                inItem.trenutna_kolicina = whItem.warehouse_data.quantity
                inItem.item = whItem
                tempArray.push(inItem)
              }
            })

            tempArray.sort((a, b) => (a.rbr > b.rbr) ? 1 : ((b.rbr < a.rbr) ? -1 : 0))
            this.allItems = tempArray
          }
        })
    },
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')

      number = parseFloat(number)
      return parseInt(number * multiplier)
    },
    checkInput (ev = false) {
      const maskRe = /^([0-9]+)|([,])|([.])$/
      if (([','].some(sep => this.$attrs?.value?.toString()?.includes(sep)) && [','].some(sep => ev.key === sep)) || !maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    setTargetModifier () {
      var sideNav = this.$route.meta.sideNav
      switch (sideNav) {
        case 'Companies':
          break
        case 'Company':
          this.targetModifier = 'company'
          break
        case 'PointOfSale':
          this.targetModifier = 'pointOfSale'
          break
        case 'CashRegister':
          this.targetModifier = 'pointOfSale'
          break
      }
    }
  }
}
</script>
<style scoped>
.stepper-wrapper {
  width: calc(80% - 56px);
}
</style>
